import Avatar from "CustomFields/Avatar"
import StatusField from "CustomFields/StatusField"
import TextFieldSelf from "CustomFields/TextFieldSelf"

import {
    Show,
    SimpleShowLayout,
    TextField,
    DateField,
    RichTextField,
    EmailField,
    BooleanField,
    ImageInput,
    TabbedShowLayout,
    Tab,
    ReferenceField,
    ArrayField,
    Datagrid,
    SimpleList,
    ImageField,
    Link
} from "react-admin"
import{ Paper,Grid} from "@mui/material" 

const Content = () =>{
    return (
    <Paper elevation={20}>
        <SimpleShowLayout>

       <TextField source="header" label="Başlık" />
            <TextField source="description" label="Açıklama" />
            <ReferenceField
                label="Kategori"
                source="categoryId"
                reference="category"
                link={false}>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField
                label="Kullanıcı"
                source="userId"
                reference="user"
                link="show">
                <TextField source="name" />
            </ReferenceField>
            <ArrayField source="preview" label="Görseller" >
                <SimpleList
                    sx={{
                        display: "flex",
                        gap: "2px"
                    }}
                    primaryText={(record) => (
                        <a target="_blank" href={record.url}>
                            <ImageField
                                source="url"
                                sx={{
                                    "& img": {
                                        maxWidth: 200,
                                        maxHeight: 200,
                                        objectFit: "contain"
                                    }
                                }}
                            />
                        </a>
                    )}
                    linkType={false}
                />
            </ArrayField>
            <BooleanField
                source="isOnThisPlatform"
                label="Expero'da mı yapıldı?"
            />
            <StatusField source="releaseType" label="Yayın Durumu" />
            <DateField source="endDate" label="Tamamlanma Tarihi" />
            <DateField source="createDate" label="Oluşturma Tarihi" />
            <DateField source="updateDate" label="Güncelleme Tarihi" />
            <ArrayField source="links" label="Video Linkleri">
                <SimpleList
                    
                    primaryText={(record) => (
                        <a target="_blank" href={record}>
                            <TextFieldSelf label="Bağlantı Linki" />
                        </a>
                    )}
                    linkType={false}
                />
            </ArrayField>
            <ArrayField source="files" label="Dosya İçeriği">
                <SimpleList
                    primaryText={(record) => (
                        <a target="_blank" href={record.url}>
                            <TextField source="name" />
                        </a>
                    )}
                    linkType={false}
                />
            </ArrayField>
            Fotos Tamamlama Yılı Expero’da Yapıldı Video Bağlantıları Ek
            Kategori Yayın Durumu Kullanıcı
            </SimpleShowLayout>
         </Paper>
    )
}


export const PortfolioView = () => (
    <Show title={"Portfolyo Detayı"}>
        <Grid spacing={1} sx={{ mt:1, mb: 6, px: 2 }}>
            <Grid>
                <Content/>
            </Grid>
        </Grid>

           
    </Show>
)
