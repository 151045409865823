import BackToListField from "CustomFields/BackToListField"
import { Card, CardContent, Paper, Grid } from "@mui/material"
import {
    DateField,
    DeleteWithConfirmButton,
    EditButton,
    NumberField,
    Show,
    SimpleShowLayout,
    TextField,
    useRecordContext
} from "react-admin"
import ColorfulBooleanField from "CustomFields/ColorfulBooleanField"
import StatusField from "CustomFields/StatusField"

const Content = () => {
    const record = useRecordContext()
    if(!record) return null
    return (
        <Paper elevation={20}>
            <SimpleShowLayout>
                <TextField source="name" label="Komisyon Adı" />
                <NumberField
                    source="employerRate"
                    label="İşveren Oranı"
                    emptyText="Standart"
                />
                <NumberField
                    source="freelancerRate"
                    label="Freelancer Oranı"
                    emptyText="Standart"
                />
                <ColorfulBooleanField
                    source="isDefault"
                    label="Varsayılan Değer Mi?"
                    trueLabel="Evet"
                    falseLabel="Hayır"
                />
                <StatusField source="status" label="Durum" />
                <DateField source="createDate" label="Oluşturma Tarihi" />
             {!record.isDefault  && <Card sx={{ display: "flex", "flex-direction": "row" }}>
                    <CardContent>
                        <EditButton />
                    </CardContent>
                    <CardContent>
                        <DeleteWithConfirmButton confirmTitle="Emin misiniz?" />
                    </CardContent>
                </Card>}
            </SimpleShowLayout>
        </Paper>
    )
}

export const CommissionRateView = () => {
    return (
        <Show title={"Komisyon Oran Detayı"} actions={<></>}  >
            <Grid spacing={1} sx={{ mt: 5, mb: 6, px: 2 }}>
                <Grid xs={1}>
                    <BackToListField source="commission-rate" />
                </Grid>
                <Grid xs={7}>
                    <Content />
                </Grid>
            </Grid>
        </Show>
    )
}
