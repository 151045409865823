import { Typography, Paper, Grid } from "@mui/material"
import {
    Show,
    TabbedShowLayout,
    Tab,
    useRecordContext,
    useGetOne
} from "react-admin"
import { UserAccountEnum, UserGroupType } from "Types/UserTypes"
import {
    EmployeeCountTypes,
    ExpertiseLevels,
    LanguageLevels
} from "Global/Constants"
import { RecordViewType } from "./types"
import {
    Aside,
    BadgeView,
    EmployeeList,
    MyCertificates,
    MyEducation,
    MyExperience,
    MyLanguages,
    MyProfile,
    MyProject,
    RenderBilling,
    RenderComplaints,
    RenderPortfolio,
    MyLocation
} from "./ViewPartial"
import { ReleaseType } from "Types/ReleaseTypes"
import {} from "./ViewPartial/MyLocation"

export const UserView = () => {
    return (
        <Show title={"Profil Detayı"}>
            <Grid container spacing={2} sx={{ mb: 5, px: 2 }}>
                <Grid item xs={2}>
                    <Aside />
                </Grid>
                <Grid item xs={10}>
                    <Paper elevation={20}>
                        <UserViewPage />
                    </Paper>
                </Grid>
            </Grid>
        </Show>
    )
}

const UserViewPage = () => {
    const record: RecordViewType = useRecordContext()
    if (!record) return null
    const isFreelancer = record.userType === UserAccountEnum.Freelancer
    const isCompany = record.groupType === UserGroupType.Company
    const isApprovedCompany = record.releaseType === ReleaseType.published

    const { data: billingData, isLoading: billingIsLoading } = useGetOne(
        "billing",
        { id: record?.id }
    )

    const { data: portfolioData, isLoading: portfolioIsLoading } = useGetOne(
        "portfolio",
        { id: record?.id }
    )

    const employeeCount = EmployeeCountTypes.find(
        (s: any) => s.id === record?.information?.employeeCountType ?? -1
    )

    const expertiseLevel = ExpertiseLevels.find(
        (s: any) =>
            s.id ===
            (record?.talent?.length > 0 ? record?.talent[0]?.expertise : -1)
    )

    return (
        <TabbedShowLayout>
            <Tab label="Profil" icon={<></>}>
                <MyProfile
                    record={record}
                    employeeCount={employeeCount}
                    expertiseLevel={expertiseLevel}
                    isFreelancer={isFreelancer}
                />
            </Tab>

            {isFreelancer && (
                <Tab label="Konum" icon={<></>} path="location">
                    <MyLocation record={record} />
                </Tab>
            )}
            {isFreelancer && (
                <Tab label="Deneyim" icon={<></>} path="experience">
                    <MyExperience record={record} />
                </Tab>
            )}
            {isFreelancer && (
                <Tab label="Diller" icon={<></>} path="languages">
                    <MyLanguages
                        record={record}
                        LanguageLevels={LanguageLevels}
                    />
                </Tab>
            )}
            {isFreelancer && (
                <Tab label="Eğitim" icon={<></>} path="education">
                    <MyEducation record={record} />
                </Tab>
            )}
            {isFreelancer && (
                <Tab label="Sertifika" icon={<></>} path="certificates">
                    <MyCertificates record={record} />
                </Tab>
            )}
            {isFreelancer && (
                <Tab
                    sx={{ fontSize: "8 px" }}
                    label="Portfolyo"
                    icon={<></>}
                    path="portfolio">
                    {portfolioIsLoading && (
                        <Typography variant="body2">Yükleniyor</Typography>
                    )}

                    {!portfolioIsLoading && (
                        <RenderPortfolio data={portfolioData} />
                    )}
                </Tab>
            )}
            <Tab label={"Fatura ve Ödeme"} icon={<></>} path="billing">
                {billingIsLoading && (
                    <Typography variant="body2">Yükleniyor</Typography>
                )}

                {!billingIsLoading && (
                    <RenderBilling
                        data={billingData}
                        isFreelancer={isFreelancer}
                    />
                )}
            </Tab>
            {!isFreelancer && (
                <Tab label="Projeler" icon={<></>} path="projects">
                    <MyProject />
                </Tab>
            )}
            <Tab label={"Şikayetler"} icon={<></>} path="complaint">
                <RenderComplaints />
            </Tab>

            {isCompany && isApprovedCompany && (
                <Tab label={"Ekip"} icon={<></>}>
                    <EmployeeList />
                </Tab>
            )}

            <Tab label={"Rozetler"} icon={<></>} path="badge">
                <BadgeView
                    record={record}
                    isCompany={isCompany}
                    isFreelancer={isFreelancer}
                />
            </Tab>
        </TabbedShowLayout>
    )
}
