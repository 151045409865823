import * as React from "react"
import { Phone, People } from "@mui/icons-material"
import { Button, Box } from "@mui/material"

import {
    useListContext,
    List,
    Datagrid,
    TextField,
    EmailField,
    BooleanField,
    DateField,
    TopToolbar,
    FilterButton,
    ExportButton,
    TextInput,
    BooleanInput,
    ShowButton,
    EditButton,
    WrapperField,
    Title,
    FilterList,
    FilterListItem,
    Pagination,
    FilterLiveSearch,
    Labeled
} from "react-admin"
import BooleanFilter from "Filters/BooleanFilter"
import StatusField from "CustomFields/StatusField"
import ColorfulBooleanField from "CustomFields/ColorfulBooleanField"
import KFList from "CustomElements/KFList"

import { Card, CardContent, Grid, Paper } from "@mui/material"
import DynamicFilterListItem from "CustomElements/DynamicFilterListItem"

const FilterSidebar = () => (
    <>
        <Paper elevation={20}>
            <Box sx={{ marginBottom: 0.5, p: 1.2 }}>
                <Labeled label="Email Arama">
                    <FilterLiveSearch
                        source="email"
                        variant="filled"
                        placeholder="E-Posta"
                    />
                </Labeled>
                <Labeled label="İsim Arama">
                    <FilterLiveSearch
                        source="firstName"
                        variant="filled"
                        placeholder="İsim"
                    />
                </Labeled>
            </Box>
        </Paper>
        <Paper elevation={20}>
            <Card>
                <CardContent>
                    <FilterList label="E-posta" icon={<></>}>
                        <DynamicFilterListItem
                            label="Bekleyen"
                            name="isActivated"
                            val={false}
                        />
                        <DynamicFilterListItem
                            label="Onaylı"
                            name="isActivated"
                            val={true}
                        />
                    </FilterList>

                    <FilterList label="Kimlik" icon={<></>}>
                        <DynamicFilterListItem
                            label="Bekleyen"
                            name="isIdentityValid"
                            val={false}
                        />
                        <DynamicFilterListItem
                            label="Onaylı"
                            name="isIdentityValid"
                            val={true}
                        />
                    </FilterList>

                    <FilterList label="Telefon" icon={<></>}>
                        <DynamicFilterListItem
                            label="Bekleyen"
                            name="isPhoneValid"
                            val={false}
                        />
                        <DynamicFilterListItem
                            label="Onaylı"
                            name="isPhoneValid"
                            val={true}
                        />
                    </FilterList>

                    <FilterList label="İletişim İzni" icon={<></>}>
                        <DynamicFilterListItem
                            label="Var"
                            name="isContactAllowed"
                            val={true}
                        />
                        <DynamicFilterListItem
                            label="Yok"
                            name="isContactAllowed"
                            val={false}
                        />
                    </FilterList>
                </CardContent>
            </Card>
        </Paper>
    </>
)

const DataList = () => {
    return (
        <>
            <Datagrid bulkActionButtons={false}>
                <WrapperField
                    label="İsim Soyisim"
                    sortable={true}
                    sortBy={"firstname"}>
                    <TextField source="firstname" />{" "}
                    <TextField source="lastname" />
                </WrapperField>
                <TextField source="email" label="E-posta Adresi" />
                <ColorfulBooleanField
                    source="isActivated"
                    label="E-posta"
                    trueLabel="Onaylı"
                    falseLabel="Bekleyen"
                />
                <ColorfulBooleanField
                    source="isIdentityValid"
                    label="Kimlik"
                    trueLabel="Onaylı"
                    falseLabel="Bekleyen"
                />
                <ColorfulBooleanField
                    source="isPhoneValid"
                    label="Telefon"
                    trueLabel="Onaylı"
                    falseLabel="Bekleyen"
                />
                <StatusField source="status" label="Durum" />
                <ColorfulBooleanField
                    source="isContactAllowed"
                    label="İletişim İzni"
                    trueLabel="İletişim İzni Verildi"
                    falseLabel="İletişim İzni Verilmedi"
                />
                <DateField source="createDate" label="Kayıt Tarihi" />
                <WrapperField label="" sortable={false}>
                    <ShowButton
                        label="Detay"
                        icon={<></>}
                        alignIcon="right"
                        variant="text"
                    />
                </WrapperField>
            </Datagrid>
            <Pagination rowsPerPageOptions={[5,10, 25]} />
        </>
    )
}

export const AccountList = () => {
    return (
        <>
            <List
                exporter={false}
                pagination={<></>}
                hasShow
                title="Hesaplar"
                sort={{
                    field: "createDate",
                    order: "DESC"
                }}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <FilterSidebar />
                    </Grid>
                    <Grid item xs={10}>
                        <DataList />
                    </Grid>
                </Grid>
            </List>
        </>
    )
}
