import {
    Show,
    SimpleShowLayout,
    TextField,
    DateField,
    RichTextField,
    EmailField,
    BooleanField,
    ImageInput,
    TabbedShowLayout,
    Tab,
    ReferenceField,
    ArrayField,
    Datagrid,
    SimpleList,
    ImageField,
    Link,
    useRecordContext,
    useGetOne,
    ReferenceManyField,
    Button,
    Labeled,
    FunctionField,
    RecordContext,
    RecordContextProvider
} from "react-admin"
import {
    Box,
    Typography,
    Link as MLink,
    Grid,
    Paper,
    Alert,
    Card,
    CardContent,
    CircularProgress
} from "@mui/material"
import { ArrowBack } from "@mui/icons-material"
import { RecordViewType } from "Pages/User/types"
import Avatar from "CustomFields/Avatar"
import OfferGroupTypeField from "CustomFields/OfferGroupTypeField"
import PriceField from "CustomFields/PriceField"
import OfferOveralTypeField from "CustomFields/OfferOveralTypeField"
import { List } from "lodash"
import GroupOfferList from "./ViewPartial/GroupOfferList"
import OfferContent from "./ViewPartial/OfferContent"

const Aside = () => {
    const record = useRecordContext()
    if (!record) return null

    return (
        <>
            <Link to={`/project/${record?.projectId}/show/2`}>
                <Button
                    label={"Tekliflere Dön"}
                    variant={"outlined"}
                    color={"primary"}
                    startIcon={<ArrowBack />}
                    sx={{ mb: 2 }}
                />
            </Link>
            <Paper elevation={20}>
                <Card>
                    <CardContent>
                        <Box display="flex" flexDirection="column">
                            <Labeled label="Teklif Sahibi Profili">
                                <></>
                            </Labeled>

                            <ReferenceField source="userId" reference="user" link="show">
                                <Avatar
                                    source="imagePath"
                                    title="Profil Resmi"
                                    size={80}
                                    sx={{
                                        width: 90,
                                        height: 90,
                                        mx: "auto"
                                    }}></Avatar>
                                <Labeled label="İsim">
                                    <TextField
                                        textAlign="center"
                                        source="name"
                                        label="İsim"
                                    />
                                </Labeled>
                                <ReferenceField
                                    source="accountId"
                                    reference="account"
                                    link="show">
                                    <Labeled label="Hesap">
                                        <FunctionField
                                            render={(record: any) =>
                                                `${record.firstname} ${record.lastname}`
                                            }
                                        />
                                    </Labeled>
                                </ReferenceField>
                            </ReferenceField>
                        </Box>
                    </CardContent>
                </Card>
            </Paper>
        </>
    )
}

export const OfferView = () => {
    return (
        <Show title={"Teklif Detayı"}>
            <Grid container spacing={2} sx={{ mt: 1, mb: 6, px: 2 }}>
                <Grid item xs={2}>
                    <Aside />
                </Grid>
                <Grid item xs={10}>
                    <OfferContent />
                </Grid>
            </Grid>
        </Show>
    )
}
