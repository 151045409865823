const LanguageLevels = [
    { id: 0, name: 'Başlangıç' },
    { id: 1, name: 'Kısıtlı' },
    { id: 2, name: 'Orta düzey' },
    { id: 3, name: 'Akıcı' },
    { id: 4, name: 'Anadil' }
]


const EmployeeCountTypes = [
    { id: 0, name: '1-5 arası' },
    { id: 1, name: '6-10 arası' },
    { id: 2, name: '11-15 arası' },
    { id: 3, name: '16-20 arası' },
    { id: 4, name: "20'den fazla" }
]

const ExpertiseLevels = [
    { id: 0, name: 'Giriş Seviyesi' },
    { id: 1, name: 'Orta Düzey' },
    { id: 2, name: 'Uzman' }
]



export { LanguageLevels, EmployeeCountTypes, ExpertiseLevels }