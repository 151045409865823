import React from "react"
import MUIAvatar from "@mui/material/Avatar"
import { useRecordContext } from "react-admin"
import { Box } from "@mui/material"

type PropType = {
    source: string
    title?: string
    label?: string
    size?: number
    sx?: any
}

const Avatar = (props: PropType) => {
    const { source, title = "", size = 24, sx = {} } = props
    const record = useRecordContext(props)
    return (
        <Box sx={sx}>
            <MUIAvatar
                src={record[source]}
                alt={title}
                sx={{ width: size, height: size }}
            />
        </Box>
    )
}

export default Avatar
