import { Box, Typography } from "@mui/material"

type Props = {
    title : string,
    color : string
} 

export const TitleBox = (props : Props) => {
    const { title, color } = props
    return (
        <Box className="title-box" style={{ backgroundColor: color }}>
            <Typography variant="body2" className="title">
                {title}
            </Typography>
        </Box>
    )
}
