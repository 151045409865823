import StatusField from "CustomFields/StatusField"
import {
    Create,
    Datagrid,
    DateField,
    List,
    ShowButton,
    SimpleForm,
    TextField,
    TextInput,
    WrapperField,
    Pagination
} from "react-admin"

const ManagerListView = () => {
    return (
        <>
            <List
                exporter={false}
                pagination={<></>}
                disableSyncWithLocation
                hasShow
                title="Yöneticiler">
                <>
                    {" "}
                    <Datagrid bulkActionButtons={false}>
                        <TextField source="firstname" label="İsim" />
                        <TextField source="lastname" label="Soyisim" />
                        <TextField source="email" label="E-posta Adresi" />
                        <TextField source="role" label="Rol" />

                        <StatusField source="status" label="Durum" />
                        <DateField source="createDate" label="Kayıt Tarihi" />
                        <DateField
                            source="updateDate"
                            label="Güncelleme Tarihi"
                        />

                        <WrapperField label="" sortable={false}>
                            <ShowButton
                                label="Detay"
                                icon={<></>}
                                alignIcon="right"
                                variant="text"
                            />
                        </WrapperField>
                    </Datagrid>
                    <Pagination rowsPerPageOptions={[5, 10, 25]} />
                </>
            </List>
        </>
    )
}

export default ManagerListView
