import { DataGrid } from "@mui/x-data-grid"
export const MyEducation = ({ record }: { record: any }) => {
    return (
        <DataGrid
            getRowId={(row: any) => row.name}
            rows={record?.education || []}
            columns={[
                {
                    field: "name",
                    headerName: "Kurum",
                    sortable: false,
                    flex: 1
                },
                {
                    field: "field",
                    headerName: "Bölüm",
                    sortable: false,
                    flex: 1
                },
                {
                    field: "startDate",
                    headerName: "Başlangıç",
                    sortable: false,
                    flex: 1
                },
                {
                    field: "endDate",
                    headerName: "Bitiş",
                    sortable: false,
                    flex: 1
                }
            ]}
        />
    )
}
