import { Show, TabbedShowLayout, Tab } from "react-admin"

import { Container, Paper, Grid } from "@mui/material"

import OfferCriteria from "./ViewPartial/OfferCriteria"
import OfferListing from "./ViewPartial/OfferListing"
import Summary from "./ViewPartial/Summary"
import Aside from "./ViewPartial/Aside"
import WorkProcess from "./ViewPartial/WorkProcess"

const ProjectViewPage = () => {
    return (
        <Paper elevation={20}>
            <TabbedShowLayout>
                <Tab label="Genel" icon={<></>}>
                    <Summary />
                </Tab>
                <Tab label="Kriterler" icon={<></>}>
                    <OfferCriteria />
                </Tab>
                <Tab label="Teklifler" icon={<></>}>
                    <OfferListing />
                </Tab>
                <Tab label="İş Süreçleri" icon={<></>}>
                    <WorkProcess />
                </Tab>
            </TabbedShowLayout>
        </Paper>
    )
}

export const ProjectView = () => (
    <Show title={"Proje Detayı"}>
        <Grid container spacing={2} sx={{ mb: 5, px: 2 }}>
            <Grid item xs={3}>
                <Aside />
            </Grid>
            <Grid item xs={9}>
                <ProjectViewPage />
            </Grid>
        </Grid>
    </Show>
)
