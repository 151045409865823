import { httpClient } from "Helpers"
import { GenerateUrlByResource } from "Providers/Helpers"
import { useState } from "react"
import { Button, Confirm, useNotify, useRedirect } from "react-admin"
import { Delete } from "@mui/icons-material"

export const RemoveProfile = ({ record }: { record: any }) => {
    const redirect = useRedirect()
    const notify = useNotify()
    const [loading, setLoading] = useState<boolean>(false)
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const showAreYouSure = () => {
        setIsOpen(true)
    }

    const handleSubmit = () => {
        setLoading(true)
        const baseUrl = GenerateUrlByResource("user")

        httpClient(`${baseUrl}/${record.id}`, {
            method: "DELETE"
        })
            .then(() => {
                notify("Profil silindi!")
                redirect("/user")
            })
            .catch((e) => {
                notify("Bir hata oluştu.", { type: "warning" })
            })
            .finally(() => {
                setLoading(false)
                setIsOpen(false)
            })
    }
    return (
        <>
            <Confirm
                isOpen={isOpen}
                title={"Profili Sil"}
                content="Dikkat! Profil tamamen silinecektir. Onaylıyor musunuz?"
                confirm="Evet"
                confirmColor="default"
                cancel="Vazgeç"
                onConfirm={handleSubmit}
                onClose={() => {
                    setIsOpen(false)
                }}
            />
            <Button
                sx={{ m: 1 }}
                startIcon={<Delete />}
                label="Profili Sil"
                onClick={showAreYouSure}
                disabled={loading}
                variant="outlined"
            />
        </>
    )
}
