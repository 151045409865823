import { useRecordContext } from "react-admin"
import {
    NotificationChannelType,
    NotificationTargetTag
} from "Types/NotificationTypes"
import Chip from "@mui/material/Chip"
import Box from "@mui/material/Box"

type PropType = {
    source: string
}

export const tagTargets = [
    {
        id: NotificationTargetTag.ActionTaker,
        name: "Aksiyon Yapan (ActionTaker)"
    },
    {
        id: NotificationTargetTag.ActionTarget,
        name: "Aksiyon Hedefi (ActionTarget)"
    },
    {
        id: null,
        name: "Profil",
        disabled: true
    },
    {
        id: NotificationTargetTag.Freelancer,
        name: "Freelancer"
    },
    {
        id: NotificationTargetTag.User,
        name: "Profil"
    },
    {
        id: NotificationTargetTag.Ghost,
        name: "Ghost Profil"
    },
    {
        id: null,
        name: "Şirket",
        disabled: true
    },
    {
        id: NotificationTargetTag.Company,
        name: "Şirket Profili"
    },
    {
        id: NotificationTargetTag.CompanyUser,
        name: "Şirket Üyesi"
    },
    {
        id: null,
        name: "Teklif",
        disabled: true
    },
    {
        id: NotificationTargetTag.OfferLeader,
        name: "Teklif Sahibi"
    }
]

const NotificationTargetField = (props: PropType) => {
    const { source } = props
    const record = useRecordContext(props)

    let target = record[source]

    return (
        <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="flex-start"
            sx={{ gap: 1 }}>
            {target.map((s: NotificationTargetTag, index: number) => {
                const found = tagTargets.find((x: any) => x.id === s)
                return <Chip label={found?.name.replace('(ActionTarget)', '').replace('(ActionTaker)', '')} size="small" />
            })}
        </Box>
    )
}

export default NotificationTargetField
