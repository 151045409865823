import { Box, Typography, Divider } from "@mui/material"
import { Link } from "react-admin"
type InfoBoxPropType = {
    title: string
    borderColor: string
    isIndividualEmployer?: boolean
    chipList: { chipTitle: string; chipCount: number , link: string}[]
}
type InfoBoxChipPropType = {
    chipTitle: string
    chipCount: number
    chipColor: string
    chipLink: string
}
export const InfoBox = (props: InfoBoxPropType) => {
    const { title, chipList, borderColor, isIndividualEmployer } = props
    return (
        <Box style={InfoBoxStyle.infoBoxBorder(borderColor)}>
            <Box style={InfoBoxStyle.infoBox}>
                <Typography
                    variant="caption"
                    style={InfoBoxStyle.infoBoxTitle(isIndividualEmployer)}>
                    {title}
                </Typography>
                {Array.from(chipList).map((item: any, index: number) => (
                    <InfoBoxChip
                        chipTitle={item.chipTitle}
                        chipCount={item.chipCount}
                        chipColor={InfoBoxStyle.ChipColorList[index]}
                        chipLink={item.link}
                    />
                ))}
            </Box>
        </Box>
    )
}

const InfoBoxChip = (props: InfoBoxChipPropType) => {
    const { chipTitle, chipCount, chipColor , chipLink} = props
    return (
        <Box style={InfoBoxStyle.infoBoxChip}>
            <Link to={chipLink}>
                <Typography variant="caption">{chipTitle}</Typography>
                <Box style={InfoBoxStyle.infoBoxChipCount(chipColor)}>
                    <Typography
                        variant="body2"
                        style={InfoBoxStyle.infoBoxChipCountText}>
                        {chipCount}
                    </Typography>
                </Box>
            </Link>
        </Box>
    )
}

class InfoBoxStyle {
    static infoBox: React.CSSProperties = {
        padding: "10px",
        justifyContent: "space-arround",
        display: "flex"
    }
    static infoBoxTitle = (
        isIndividualEmployer: boolean = false
    ): React.CSSProperties => {
        return {
            fontSize: isIndividualEmployer ? 10 : 12,
            marginBottom: "1px",
            writingMode: "vertical-rl",
            transform: "rotate(180deg)"
        }
    }
    static infoBoxChip: React.CSSProperties = {
        padding: "0px 40px",
        margin: "3px",
        borderRadius: "10px",
        flex: 1,
        textAlign: "center"
    }
    static infoBoxChipCount = (color: string): React.CSSProperties => {
        return {
            padding: "8px",
            backgroundColor: color,
            borderRadius: "10px",
            marginLeft: "2px"
        }
    }
    static infoBoxBorder = (color: string): React.CSSProperties => {
        return {
            borderLeftStyle: "solid",
            borderLeftColor: color,
            margin: "15px 0px"
        }
    }
    static infoBoxChipCountText: React.CSSProperties = { color: "#F2F3F4" }
    static ChipColorList = ["#979A9A", "#F1C40F", "#2ECC71", "#17202A"]
}
