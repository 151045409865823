import { Box, Typography, Alert } from "@mui/material"
import { TableRow } from "../Components/TableRow"
export const RenderBilling = ({
    data,
    isFreelancer
}: {
    data: any
    isFreelancer: boolean
}) => {
    const isPersonalCompany = data?.dealerType === 2
    const isBillable = data?.dealerType !== 1

    if (!data?.id) {
        return (
            <Alert severity="warning">
                Fatura ve ödeme bilgisi bulunamadı.
            </Alert>
        )
    }

    return (
        <Box>
            <table className="admin-table">
                <tbody>
                    {isFreelancer && (
                        <>
                            <tr>
                                <td colSpan={2}>
                                    <Typography
                                        variant="body1"
                                        style={{
                                            fontWeight: "bold"
                                        }}>
                                        IBAN Bilgileri
                                    </Typography>
                                </td>
                            </tr>
                            <TableRow label="IBAN" val={data?.iban} />
                            <TableRow
                                label="IBAN İsmi"
                                val={data?.ibanFullName}
                            />
                        </>
                    )}
                    <tr>
                        <td colSpan={2}>
                            <Typography
                                variant="body1"
                                style={{
                                    fontWeight: "bold"
                                }}>
                                Fatura Bilgileri
                            </Typography>
                        </td>
                    </tr>
                    {isFreelancer && isPersonalCompany && (
                        <TableRow
                            label="Yetkili Kişi"
                            val={data?.contactName}
                        />
                    )}
                    {isBillable && (
                        <>
                            {data?.companyName && (
                                <TableRow
                                    label="Firma Unvanı"
                                    val={data?.companyName}
                                />
                            )}
                            <TableRow
                                label={
                                    data?.taxNumber.length == 11
                                        ? "Kimlik No"
                                        : "Vergi Numarası"
                                }
                                val={data?.taxNumber}
                            />
                            {data?.taxAdministration && (
                                <TableRow
                                    label="Vergi Dairesi"
                                    val={data?.taxAdministration}
                                />
                            )}
                        </>
                    )}
                    <TableRow label="Ülke" val={data?.details?.countryName} />
                    <TableRow label="Şehir" val={data?.details?.cityName} />
                    <TableRow label="Adres" val={data?.address} />
                </tbody>
            </table>
        </Box>
    )
}
