import { Create, SimpleForm, TextInput, SelectInput, required } from 'react-admin';

const BlogCategoryCreate = () => {
    return (
        <Create title="Oluştur">
            <SimpleForm>
                <TextInput source="name" name="name" label="İsim" validate={[required()]} fullWidth />
                <TextInput source="description" name="description" label="Açıklama" validate={[required()]} fullWidth />

                <SelectInput
                    fullWidth
                    validate={required()}
                    name="blogType"
                    source="blogType"
                    label="İçerik Sayfası"
                    choices={[
                        { id: 0, name: "Blog" },
                        { id: 1, name: "Yardım" }
                    ]}
                />
                
            </SimpleForm>
        </Create>
    )
}

export default BlogCategoryCreate;