import React, { ReactElement } from "react"
import MUIAvatar from "@mui/material/Avatar"
import { useRecordContext } from "react-admin"
import { UserAccountEnum } from "Types/UserTypes"
import { Circle } from "@mui/icons-material"
import { StatusType } from "Types/StatusTypes"

type PropType = {
    source: string
    label?: string
    record?: any
}

const GetStatusText = (status: number): ReactElement => {
    let returnElement: ReactElement = <Circle color="success" />
    switch (status) {
        case StatusType.Deleted:
            returnElement = <Circle color="error" fontSize={"inherit"} />
            break
        case StatusType.Active:
            returnElement = <Circle color="success" fontSize={"inherit"} />
            break
        case StatusType.Passive:
            returnElement = <Circle color="warning" fontSize={"inherit"} />
            break
    }
    return returnElement
}

const StatusField = (props: PropType) => {
    const { source, record: propRecord } = props
    const record = propRecord ? propRecord : useRecordContext(props)
    return <>{GetStatusText(record[source])}</>
}

export default StatusField
