import * as React from "react"
import {
    Admin,
    Authenticated,
    CustomRoutes,
    defaultTheme,
    EditGuesser,
    ListGuesser,
    Resource,
    ShowGuesser,
    useAuthProvider,
    useGetIdentity,
    useGetPermissions
} from "react-admin"
import { Route } from "react-router"
import turkishMessages from "./Global/tr"
import polyglotI18nProvider from "ra-i18n-polyglot"
import ForgotPasswordView from "Pages/ForgotPassword/View"
import { AccountList, AccountView, AccountEdit } from "./Pages/Account"
import { UserList, UserView } from "./Pages/User"
import { ProjectList, ProjectView } from "./Pages/Project"
import { ComplaintList, ComplaintView } from "./Pages/Complaint"
import { BlogList, BlogEditView, BlogCreateView } from "./Pages/Blog"
import {
    BlogCategoryCreate,
    BlogCategoryList,
    BlogCategoryEdit
} from "./Pages/BlogCategory"
import {
    QuickFeedbackCreate,
    QuickFeedbackEdit,
    QuickFeedbackList
} from "./Pages/QuickFeedback"
import DashboardView from "Pages/Dashboard/View"
import AuthProvider from "Providers/AuthProvider"
import DataProvider from "Providers/DataProvider"

import {
    Badge,
    PeopleAlt,
    Inventory,
    BorderColor,
    Style,
    Category,
    AdminPanelSettings,
    MarkEmailUnread,
    Notifications,
    Feedback,
    RateReview,
    ContactSupport,
    Verified,
    LocalOffer,
    Paid
} from "@mui/icons-material"

import "App.css"
import "bootstrap.css"
import { httpClient } from "Helpers"
import { AdminThemePalette } from "Global/Theme"
import { InviteCodeList } from "Pages/InviteCode/List"
import ManagerCreateView from "Pages/Manager/Create"
import { ManagerEditView } from "Pages/Manager/Edit"
import ManagerListView from "Pages/Manager/List"
import TemplateListView from "Pages/Template/List"
import { TemplateEditView } from "Pages/Template/Edit"
import TemplateCreateView from "Pages/Template/Create"
import { queryClient } from "Providers/Config"
import {
    ContentFeedbackList,
    ContentFeedbackEdit,
    ContentFeedbackView
} from "Pages/ContentFeedback"
import { PortfolioView } from "Pages/Portfolio/View"
import LoginView from "Pages/Login/View"
import { OfferView } from "Pages/Offer/View"
import { BadgeCreate, BadgeEdit, BadgeList, BadgeView } from "Pages/Badge"
import {
    CommissionRateCreate,
    CommissionRateEdit,
    CommissionRateList,
    CommissionRateView
} from "Pages/CommissionRate"
import {
    AccounterInvoiceList,
    AccounterInvoiceView
} from "Pages/AccounterInvoice"

const messages = {
    tr: turkishMessages
}

// @ts-ignore
const i18nProvider = polyglotI18nProvider((locale) => messages["tr"])
const App = () => {
    //TODO: ikiden fazla rol olayı olursa bu sistem revize edilecektir.
    const isAdmin = localStorage.getItem("role") === "admin"

    return (
        <Admin
            //layout={MyLayout}
            dashboard={DashboardView}
            loginPage={LoginView}
            theme={AdminThemePalette}
            queryClient={queryClient}
            dataProvider={DataProvider(httpClient)}
            authProvider={AuthProvider}
            i18nProvider={i18nProvider}
            requireAuth>
            <CustomRoutes noLayout>
                <Route
                    path="/forgot-password"
                    element={
                        <Authenticated requireAuth={false}>
                            <ForgotPasswordView />
                        </Authenticated>
                    }></Route>
            </CustomRoutes>
            {isAdmin && (
                <>
                    <Resource
                        name="account"
                        list={AccountList}
                        show={AccountView}
                        edit={AccountEdit}
                        icon={Badge}
                        options={{ label: "Hesaplar" }}
                    />
                    <Resource
                        name="user"
                        list={UserList}
                        show={UserView}
                        icon={PeopleAlt}
                        options={{ label: "Profiller" }}
                    />
                    <Resource
                        name="project"
                        list={ProjectList}
                        show={ProjectView}
                        icon={Inventory}
                        options={{ label: "Projeler" }}
                    />

                    <Resource
                        name="content"
                        list={BlogList}
                        create={BlogCreateView}
                        edit={BlogEditView}
                        options={{ label: "İçerik" }}
                        icon={BorderColor}
                    />

                    <Resource
                        name="content-category"
                        list={BlogCategoryList}
                        create={BlogCategoryCreate}
                        edit={BlogCategoryEdit}
                        options={{ label: "İçerik Kategorileri" }}
                        icon={Category}
                    />

                    <Resource
                        name="content-tag"
                        list={BlogCategoryList}
                        create={BlogCategoryCreate}
                        edit={BlogCategoryEdit}
                        options={{ label: "İçerik Etiketleri" }}
                        icon={Style}
                    />
                    {/*  
                     //Ön yüz desteği daha sağlanmadığı için kullanıma açılması mantıklı değildir.
                    <Resource
                        name="quick-feedback"
                        list={QuickFeedbackList}
                        create={QuickFeedbackCreate}
                        edit={QuickFeedbackEdit}
                        options={{ label: "Hazır Geribildirimler" }}
                        icon={Feedback}
                      />   
                    <Resource
                        name="content-feedback"
                        list={ContentFeedbackList}
                        edit={ContentFeedbackEdit}
                        show={ContentFeedbackView}
                        options={{ label: "Kullanıcı Geribildirimleri" }}
                        icon={RateReview}
                    />*/}
                    <Resource
                        name="manager"
                        list={ManagerListView}
                        create={ManagerCreateView}
                        edit={ManagerEditView}
                        options={{ label: "Yöneticiler" }}
                        icon={AdminPanelSettings}
                    />

                    {/*
                   Eskiden işimize yarayan bir sistem fakat şu an kullanımda değildir. 
                   <Resource
                        name="invite-codes"
                        list={InviteCodeList}
                        options={{ label: "Davet Kodları" }}
                        icon={MarkEmailUnread}
                /> */}

                    <Resource
                        name="talent"
                        edit={EditGuesser}
                        options={{ label: "talent" }}
                    />

                    <Resource
                        name="sector"
                        edit={EditGuesser}
                        options={{ label: "sector" }}
                    />

                    <Resource
                        name="template"
                        list={TemplateListView}
                        edit={TemplateEditView}
                        options={{ label: "Bildirimler" }}
                        icon={Notifications}
                    />
                    <Resource
                        name="complaint"
                        options={{ label: "Profil Şikayetleri" }}
                        icon={ContactSupport}
                        list={ComplaintList}
                        show={ComplaintView}
                    />

                    <Resource name="offer" show={OfferView} />
                    <Resource
                        name="portfolio-detail"
                        options={{ label: "Portfolyo" }}
                        show={PortfolioView}
                    />
                    <Resource
                        name="badge"
                        options={{ label: "Rozetler" }}
                        show={BadgeView}
                        list={BadgeList}
                        edit={BadgeEdit}
                        create={BadgeCreate}
                        icon={Verified}
                    />
                    <Resource
                        name="commission-rate"
                        options={{ label: "Komisyon Oranları" }}
                        show={CommissionRateView}
                        list={CommissionRateList}
                        edit={CommissionRateEdit}
                        create={CommissionRateCreate}
                        icon={LocalOffer}
                    />
                    <Resource
                        name="accounter-invoice"
                        list={AccounterInvoiceList}
                        show={AccounterInvoiceView}
                        icon={Paid}
                        options={{ label: "Muhasebe" }}
                    />
                </>
            )}
            {!isAdmin && (
                <>
                    <Resource
                        name="accounter-invoice"
                        list={AccounterInvoiceList}
                        show={AccounterInvoiceView}
                        icon={Paid}
                        options={{ label: "Muhasebe" }}
                    />
                </>
            )}
            {/* 
            <Resource
                name="language"
                list={ListGuesser}
                edit={EditGuesser}
                options={{ label: "Diller" }}
                icon={GTranslate}
    />*/}
        </Admin>
    )
}

export default App
