import {
    DateField,
    RecordContextProvider,
    ReferenceField,
    TextField,
} from "react-admin"
import { Alert , Link as MLink} from "@mui/material"
import {DataGrid as MDataGrid} from "@mui/x-data-grid"
import StatusField from "CustomFields/StatusField"

export const RenderPortfolio = ({ data }: { data: any }) => {
    if (!data?.id) {
        return <Alert severity="warning">Portfolyo bilgisi bulunamadı.</Alert>
    }

    return (
        <MDataGrid
            getRowId={(row: any) => row.id}
            rows={data?.portfolioList || []}
            columns={[
                {
                    field: "header",
                    headerName: "Başlık",
                    sortable: false,
                    flex: 1
                },
                {
                    field: "categoryId",
                    headerName: "Kategori",
                    sortable: false,
                    flex: 1,
                    renderCell: (data) => {
                        return (
                            <RecordContextProvider value={data.row}>
                                <ReferenceField
                                    label="Kategori"
                                    source="categoryId"
                                    reference="category"
                                    link={false}>
                                    <TextField source="name" />
                                </ReferenceField>
                            </RecordContextProvider>
                        )
                    }
                },
                {
                    field: "releaseType",
                    headerName: "Yayın Durumu",
                    sortable: false,
                    flex: 1,
                    renderCell: (data) => {
                        return (
                            <StatusField
                                source="releaseType"
                                record={data.row}
                            />
                        )
                    }
                },
                {
                    field: "createDate",
                    headerName: "Oluşturma Tarihi",
                    sortable: false,
                    flex: 1,
                    renderCell: (data) => {
                        return (
                            <RecordContextProvider value={data.row}>
                                <DateField source="createDate" />
                            </RecordContextProvider>
                        )
                    }
                },
                {
                    field: "updateDate",
                    headerName: "Güncelleme Tarihi",
                    sortable: false,
                    flex: 1,
                    renderCell: (data) => {
                        return (
                            <RecordContextProvider value={data.row}>
                                <DateField source="updateDate" />
                            </RecordContextProvider>
                        )
                    }
                },
                {
                    field: "",
                    headerName: "İşlemler",
                    sortable: false,
                    flex: 1,
                    renderCell: (data) => {
                        return (
                            <MLink
                                href={`#/portfolio-detail/${data.row.id}/show`}>
                                Detay
                            </MLink>
                        )
                    }
                }
            ]}
        />
    )
}
