import NotificationTypeField from "CustomFields/NotificationTypeField"
import StatusField from "CustomFields/StatusField"
import {
    Create,
    Datagrid,
    DateField,
    List,
    ShowButton,
    SimpleForm,
    TextField,
    BooleanField,
    WrapperField,
    FilterList,
    FilterListItem,
    Pagination,
    ArrayField,
    ChipField,
    FilterLiveSearch,
    Labeled,
    SimpleShowLayout
} from "react-admin"

import { Box, Card, CardContent, Grid, Paper } from "@mui/material"
import { NotificationChannelType } from "Types/NotificationTypes"
import ColorfulBooleanField from "CustomFields/ColorfulBooleanField"
import NotificationTargetField from "CustomFields/NotificationTargetField"

const FilterSidebar = () => (
    <Paper elevation={20}>
        <Card>
            <CardContent>
                <FilterList label="Kanal" icon={<></>}>
                    <FilterListItem
                        label="Email"
                        value={{
                            notificationChannelType:
                                NotificationChannelType.Email
                        }}
                    />
                    <FilterListItem
                        label="SMS"
                        value={{
                            notificationChannelType: NotificationChannelType.Sms
                        }}
                    />
                    <FilterListItem
                        label="Web"
                        value={{
                            notificationChannelType: NotificationChannelType.Web
                        }}
                    />
                    <FilterListItem
                        label="Mobil Push"
                        value={{
                            notificationChannelType:
                                NotificationChannelType.MobilePush
                        }}
                    />
                </FilterList>

                <FilterList label="Durum" icon={<></>}>
                    <FilterListItem
                        label="Aktif"
                        value={{
                            status: 1
                        }}
                    />
                    <FilterListItem
                        label="Pasif"
                        value={{
                            status: 0
                        }}
                    />
                </FilterList>

                <Labeled label="Notifikasyon Türü" sx={{ mt: 1 }}>
                    <FilterLiveSearch source="notificationType" />
                </Labeled>

                <Labeled label="İsim" sx={{ mt: 1 }}>
                    <FilterLiveSearch source="name" />
                </Labeled>
            </CardContent>
        </Card>
    </Paper>
)

const ShowDetail = () => (
    <SimpleShowLayout>
        <TextField source="notificationTypeName" label="Tür İsmi"></TextField>


        <Box display="flex" sx={{ gap: 1}}>
            <Labeled label="Oluşturma Tarihi">
                <DateField source="createDate" label="Oluşturma" />
            </Labeled>
            <Labeled label="Güncelleme Tarihi">
                <DateField source="updateDate" label="Güncelleme" />
            </Labeled>
        </Box>
    </SimpleShowLayout>
)

const DataList = () => {
    return (
        <>
            <Datagrid bulkActionButtons={false} expand={<ShowDetail />}>
            <TextField source="id" label="ID" />

                <TextField source="notificationType" label="Tür" />

                <TextField source="name" label="İsim" />

                <NotificationTypeField
                    source="notificationChannelType"
                    label="Kanal"
                />
                <ArrayField source="allow" label="Dahil">
                    <NotificationTargetField source="allow" />
                </ArrayField>

                <ArrayField source="disAllow" label="Hariç">
                    <NotificationTargetField source="disAllow" />
                </ArrayField>

                <ColorfulBooleanField source="isMandatory" label="Zorunlu" />
                <StatusField source="status" label="Durum" />

                <WrapperField label="" sortable={false}>
                    <ShowButton
                        label="Detay"
                        icon={<></>}
                        alignIcon="right"
                        variant="text"
                    />
                </WrapperField>
            </Datagrid>
            <Pagination rowsPerPageOptions={[5, 10, 25]} />
        </>
    )
}

const TemplateListView = () => {
    return (
        <>
            <List
                pagination={<></>}
                exporter={false}
                hasShow
                title="Bildirimler">
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <FilterSidebar />
                    </Grid>
                    <Grid item xs={10}>
                        <DataList />
                    </Grid>
                </Grid>
            </List>
        </>
    )
}

export default TemplateListView
