import {
    Edit,
    SimpleForm,
    SelectInput,
    TextInput,
    ReferenceInput,
    ReferenceArrayInput,
    SelectArrayInput,
    BooleanInput,
    NumberInput,
    required,
    maxLength,
    regex,
    RadioButtonGroupInput,
    DateInput,
    Link,
    Button,
    useEditContext,
    useListContext,
    useNotify,
    useRecordContext,
    DeleteWithConfirmButton,
    SaveButton,
    Create
} from "react-admin"
import {
    AlignmentButtons,
    ClearButtons,
    FormatButtons,
    LevelSelect,
    LinkButtons,
    ListButtons,
    QuoteButtons,
    RichTextInput,
    RichTextInputToolbar
} from "ra-input-rich-text"
import { useWatch, useFormContext } from "react-hook-form"
import { useEffect, useRef } from "react"
import Remove from "@mui/icons-material/Remove"

import {
    Container,
    Box,
    Card,
    CardContent,
    Divider,
    Grid,
    Paper
} from "@mui/material"
import { ArrowBack } from "@mui/icons-material"
import { GenerateUrlByResource } from "Providers/Helpers"
import { httpClient } from "Helpers"

const STATIC_CHOICES = {
    contentTypes: [
        { id: 0, name: "Blog" },
        { id: 1, name: "Yardım" }
    ],
    publishTypes: [
        { id: 0, name: "Taslak" },
        { id: 1, name: "Yayında" }
    ],
    postTypes: [
        {
            id: 0,
            name: "Resimli Makale"
        },
        {
            id: 1,
            name: "Videolu Makale"
        }
    ]
}

const SlugInput = () => {
    const { getValues, setValue } = useFormContext()
    const notify = useNotify()
    const baseUrl = GenerateUrlByResource("content")

    const onValidateSlug = () => {
        const blogType = parseInt(getValues("blogType") || "0")
        const slug = getValues("slug")

        httpClient(`${baseUrl}/checkslug`, {
            method: "POST",
            body: JSON.stringify({
                isBlog: blogType === 0,
                contentId: null,
                slug
            })
        })
            .then((response) => JSON.parse(response.body))
            .then((response) => {
                const { isAvailable, checkedSlug } = response
                if (isAvailable === true) {
                    setValue("slug", checkedSlug)

                    notify("Bu adres kullanım için uygundur.", {
                        type: "success"
                    })
                } else {
                    notify("Bu adres kullanım için uygun değildir.", {
                        type: "error"
                    })
                }
            })
            .catch((e) => {
                notify("Bir hata oluştu.", { type: "warning" })
            })
            .finally(() => {})
    }

    return (
        <Box display="flex" sx={{ gap: 4, mb: 1 }}>
            <TextInput
                fullWidth
                source="slug"
                label="İçerik Adresi"
                validate={[
                    required(),
                    regex(
                        /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
                        "İzin verilen karakterler : [a-z, 0-9 ve -]"
                    )
                ]}
            />
            <Button
                label="Kontrol Et"
                variant="outlined"
                color="primary"
                sx={{ width: 160, height: 40 }}
                onClick={() => onValidateSlug()}
            />
        </Box>
    )
}

const MainCategoryInput = (props: any) => {
    const blogType = useWatch({ name: "blogType" })
    const { setValue } = useFormContext()

    useEffect(() => {
        if (blogType !== "") {
            setValue("mainCategoryId", "")
        }
    }, [blogType])

    if (blogType === "" || blogType === undefined) return null

    return (
        <>
            <ReferenceInput
                perPage={20}
                reference="content-category"
                source="mainCategoryId"
                filter={{ blogType }}>
                <SelectInput
                    name="mainCategoryId"
                    optionText="name"
                    validate={required()}
                />
            </ReferenceInput>
        </>
    )
}

const TagInput = (props: any) => {
    const blogType = useWatch({ name: "blogType" })
    const { setValue } = useFormContext()
    useEffect(() => {
        if (blogType !== "") {
            setValue("tags", [])
        }
    }, [blogType])

    if (blogType === "" || blogType === undefined) return null

    return (
        <ReferenceArrayInput
            source="tags"
            reference="content-tag"
            perPage={100}
            filter={{
                blogType: blogType
            }}>
            <SelectArrayInput
                name="tags"
                className="multiple-tags"
                validate={required()}
                label="Etiketler"
            />
        </ReferenceArrayInput>
    )
}

const Aside = () => {
    const isHeadline = useWatch({ name: "isHeadline" })

    return (
        <>
            <Link to="/content">
                <Button
                    label="Listeye Dön"
                    variant="outlined"
                    color="primary"
                    startIcon={<ArrowBack />}
                    sx={{ mb: 2 }}
                />
            </Link>

            <Paper elevation={20}>
                <Card>
                    <CardContent>
                        <Box display="flex" flexDirection="column">
                            <RadioButtonGroupInput
                                source="blogType"
                                name="blogType"
                                validate={required()}
                                sx={{ m: 0 }}
                                label="İçerik Türü"
                                choices={STATIC_CHOICES.contentTypes}
                            />

                            <MainCategoryInput />
                            <TagInput />
                        </Box>
                    </CardContent>
                </Card>
            </Paper>
            <Paper elevation={20} sx={{ mt: 1 }}>
                <Card>
                    <CardContent>
                        <BooleanInput
                            name="isHeadline"
                            label="Anasayfada listelensin"
                            source="isHeadline"
                            helperText="Anasayfada listelenmesi istenen içerikler için aktif edilmelidir."
                        />

                        {isHeadline && (
                            <NumberInput
                                sx={{ mt: 2 }}
                                name="headlineOrder"
                                source="headlineOrder"
                                label="Anasayfa Sıralaması"
                                min={0}
                                max={999}
                                validate={required()}
                                defaultValue={0}
                                helperText="Daha düşük sırada olan daha üstte gözükecektir."
                            />
                        )}
                    </CardContent>
                </Card>
            </Paper>
            <Paper elevation={20} sx={{ mt: 1 }}>
                <Card>
                    <CardContent>
                        <SelectInput
                            source="releaseType"
                            label="Yayın Durumu"
                            validate={required()}
                            choices={STATIC_CHOICES.publishTypes}
                            helperText="Taslak durumunda olan içerikler sadece yönetim paneline erişebilen kişiler tarafından görüntülenebilir."
                        />

                        <DateInput
                            source="publishDate"
                            label="Yayın Tarihi"
                            sx={{ mt: 2 }}
                            helperText="Bu alan boş bırakılırsa ve içerik yayında ise, tarih bugünün tarihi olarak ayarlanır."
                        />
                    </CardContent>
                </Card>
            </Paper>
        </>
    )
}

const Content = () => {
    return (
        <>
            <Paper elevation={20}>
                <Card>
                    <CardContent>
                        <Box display="flex" flexDirection="column">
                            <TextInput
                                fullWidth
                                source="title"
                                label="Başlık"
                                validate={required()}
                            />

                            <SlugInput />

                            <TextInput
                                fullWidth
                                multiline
                                source="summary"
                                label="Özet"
                                validate={[required(), maxLength(250)]}
                                helperText="Özet içerik listeleme sayfalarında ve sosyal medya paylaşımlarında gözükür."
                            />

                            <RadioButtonGroupInput
                                source="posterType"
                                validate={required()}
                                label="İçerik Tipi"
                                choices={STATIC_CHOICES.postTypes}
                            />

                            <TextInput
                                fullWidth
                                source="posterURL"
                                label="Poster / Video Adresi"
                                validate={required()}
                                helperText="Resimli makaleler için resim linki, videolu makaleler için YouTube embed link (https://www.youtube.com/embed/ID) eklenmelidir. Önerilen boyut: 920x516"
                            />
                            <TextInput
                                fullWidth
                                source="previewImageURL"
                                label="Önizleme Resmi Adresi"
                                validate={required()}
                                sx={{ mt: 2 }}
                                helperText="Listeleme sayfalarında görülecek olarak önizleme resmidir. Önerilen boyut: 350x196."
                            />
                        </Box>
                    </CardContent>
                </Card>
            </Paper>
            <Paper elevation={20} sx={{ mt: 1 }}>
                <Card>
                    <CardContent>
                        <RichTextInput
                            fullWidth
                            height={800}
                            source="body"
                            label="İçerik"
                            validate={required()}
                            toolbar={
                                <RichTextInputToolbar>
                                    <LevelSelect size={"medium"} />
                                    <FormatButtons size={"medium"} />
                                    <AlignmentButtons size={"medium"} />
                                    <ListButtons size={"medium"} />
                                    <LinkButtons size={"medium"} />
                                    <QuoteButtons size={"medium"} />
                                    <ClearButtons size={"medium"} />
                                </RichTextInputToolbar>
                            }
                        />
                    </CardContent>
                </Card>
            </Paper>
            <Paper elevation={20} sx={{mt:1}}>
                <Card>
                    <CardContent>
                        <Box display="flex" justifyContent="space-between">
                            <SaveButton />
                        </Box>
                    </CardContent>
                </Card>
            </Paper>
        </>
    )
}

const BlogCreateView = () => {
    return (
        <Create title="İçerik Düzenle">
            <SimpleForm toolbar={<></>} sx={{ p: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Aside />
                    </Grid>
                    <Grid item xs={9}>
                        <Content />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
}

export default BlogCreateView
