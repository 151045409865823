import { FilterListItem } from "react-admin"

const DynamicFilterListItem = ({
    label,
    name,
    val
}: {
    label: string
    name: string
    val: any
}) => {
    return (
        <FilterListItem
            label={label}
            value={{
                [name]: val
            }}
        />
    )
}

export default DynamicFilterListItem;