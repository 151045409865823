import { TranslationMessages } from 'ra-core';

const turkishMessages: TranslationMessages = {
    ra: {
        action: {
            add_filter: 'Filtre ekle',
            add: 'Ekle',
            back: 'Geri Dön',
            bulk_actions: '1 seçildi |||| %{smart_count} seçildi',
            cancel: 'İptal',
            clear_input_value: 'Temizle',
            clone: 'Çoğalt',
            confirm: 'Onayla',
            create: 'Oluştur',
            create_item: 'Oluştur %{item}',
            delete: 'Sil',
            edit: 'Düzenle',
            export: 'Dışa aktar',
            list: 'Listele',
            refresh: 'Yenile',
            remove_filter: 'Filtreyi kaldır',
            remove: 'Kaldır',
            save: 'Kaydet',
            search: 'Ara',
            select_all: 'Tümünü seç',
            select_row: 'Satırı seç',
            show: 'Göster',
            sort: 'Sırala',
            undo: 'Geri Al',
            unselect: 'Seçimi Kaldır',
            expand: 'Genişlet',
            close: 'Kapat',
            open_menu: 'Menüyü Aç',
            close_menu: 'Menüyü Kapat',
            update: 'Güncelle',
            move_up: 'Yukarı taşı',
            move_down: 'Aşağı taşı',
            open: 'Aç',
            toggle_theme: 'Temayı Değiştir',
        },
        boolean: {
            true: 'Evet',
            false: 'Hayır',
            null: ' ',
        },
        page: {
            create: '%{name} Oluştur',
            dashboard: 'Dashboard',
            edit: '%{name} #%{id}',
            error: 'Bir şeyler yanlış gitti',
            list: '%{name}',
            loading: 'Yükleniyor',
            not_found: 'Bulunamadı',
            show: '%{name} #%{id}',
            empty: 'Henüz %{name} yok.',
            invite: 'Yeni bir tane eklemek ister misiniz?',
        },
        input: {
            file: {
                upload_several:
                    'Yüklemek için bazı dosyaları bırakın veya bir tanesini seçmek için tıklayın.',
                upload_single: 'Yüklemek için bir dosya bırakın veya seçmek için tıklayın.',
            },
            image: {
                upload_several:
                    'Yüklemek için birkaç resim bırakın veya bir tanesini seçmek için tıklayın.',
                upload_single:
                    'Yüklemek için bir resim bırakın veya seçmek için tıklayın.',
            },
            references: {
                all_missing: 'Referans verileri bulunamadı.',
                many_missing:
                    'İlişkili referanslardan en az biri mevcut değil.',
                single_missing:
                    'İlişkili referans mevcut görünmüyor.',
            },
            password: {
                toggle_visible: 'Şifreyi gizle',
                toggle_hidden: 'Şifreyi göster',
            },
        },
        message: {
            about: 'Hakkında',
            are_you_sure: 'Emin misin?',
            bulk_delete_content:
                'Bu %{name} dosyasını silmek istediğinizden emin misiniz? |||| Bu %{smart_count} öğeyi silmek istediğinizden emin misiniz?',
            bulk_delete_title:
                '%{name} Sil |||| %{smart_count} %{name} sil',
            bulk_update_content:
                'Bu %{name} güncellemek istediğinizden emin misiniz? |||| Bu %{smart_count} öğeyi güncellemek istediğinizden emin misiniz?',
            bulk_update_title:
                '%{name} Güncelle |||| %{smart_count} %{name} güncelle',
            delete_content: 'Bu öğeyi silmek istediğinizden emin misiniz?',
            delete_title: '%{name} #%{id} sil',
            details: 'Detaylar',
            error:
                "Bir istemci hatası oluştu ve isteğiniz tamamlanamadı.",
            invalid_form: 'Form geçerli değil. Lütfen hataları kontrol edin.',
            loading: 'Sayfa yükleniyor, bir dakika lütfen',
            no: 'Hayır',
            not_found:
                'Eksik ya da hatalı bir URL girdiniz. Sayfa bulunamadı.',
            yes: 'Evet',
            unsaved_changes:
                "Değişikliklerinizden bazıları kaydedilmedi. Onları görmezden gelmek istediğinizden emin misiniz?",
        },
        navigation: {
            no_results: 'Sonuç bulunamadı',
            no_more_results:
                '%{page}. sayfa geçerli sınırlar içinde değil. Bir önceki sayfayı deneyin.',
            page_out_of_boundaries: '%{page}. sayfa geçerli sınırlar içinde değil',
            page_out_from_end: 'Sonuncu sayfadan sonrasına gidilemez',
            page_out_from_begin: '1. sayfadan öncesine gidilemez',
            page_range_info: '%{total} kayıttan %{offsetBegin}-%{offsetEnd} arası',
            partial_page_range_info:
                '%{offsetBegin}-%{offsetEnd} of more than %{offsetEnd}',
            current_page: '%{page}. sayfa',
            page: '%{page}. sayfaya git',
            first: 'İlk sayfaya git',
            last: 'Son sayfaya git',
            next: 'Sonraki sayfaya git',
            previous: 'Önceki sayfaya git',
            page_rows_per_page: 'Sayfa başına kayıt:',
            skip_nav: 'İçeriği geç',
        },
        sort: {
            sort_by: '% {Field}% {order}a göre sıralandı',
            ASC: 'artan',
            DESC: 'azalan',
        },
        auth: {
            auth_check_error: 'Giriş yapınız',
            user_menu: 'Profil',
            username: 'E-posta',
            password: 'Şifre',
            sign_in: 'Giriş Yap',
            sign_in_error: 'E-posta veya şifre yanlış',
            logout: 'Çıkış Yap',
        },
        notification: {
            updated: 'Öğe güncellendi |||| %{smart_count} öğe güncellendi',
            created: 'Öğe oluşturuldu',
            deleted: 'Öğe silindi |||| %{smart_count} öğe silindi',
            bad_item: 'Hatalı öğe',
            item_doesnt_exist: 'Öğe bulunamadı',
            http_error: 'Sunucu iletişim hatası',
            data_provider_error:
                'dataProvider hatası. Detay için konsolu gözden geçir.',
            i18n_error:
                'Belirtilen dil için çeviriler yüklenemiyor',
            canceled: 'Eylem iptal edildi',
            logged_out: 'Oturumunuz sona erdi, Lütfen yeniden bağlanın.',
            not_authorized: "Bu sayfayı görmek için yetkili değilsin.",
        },
        validation: {
            required: 'Bu alan zorunludur',
            minLength: 'En az %{min} karakter',
            maxLength: 'En fazla %{max} karakter',
            minValue: 'En az %{min} olmalı',
            maxValue: 'En fazla %{max} olmali',
            number: 'Sayısal bir değer olmalı',
            email: 'E-posta geçerli değil',
            oneOf: 'Bunlardan biri olmalı: %{options}',
            regex: 'Belirli bir formatla eşleşmelidir (regexp): %{pattern}',
        },
        saved_queries: {
            label: 'Kayıtlı Sorgular',
            query_name: 'Sorgu İsmi',
            new_label: 'Şu anki sorguyu kaydet...',
            new_dialog_title: 'Şu anki sorguyu kaydet',
            remove_label: 'Kayıtlı sorguyu sil',
            remove_label_with_name: '"%{name}" isimli sorguyu sil',
            remove_dialog_title: 'Kayıtlı sorguyu sil?',
            remove_message:
                'Bu içeriği kayıtlı sorgularınızdan silmek isteğinize emin misiniz?',
            help: 'Listeyi filtrele ve bu sorguyu daha sonra kullanmak üzere kaydet',
        },
        configurable : {
            customize : "Özelleştir"
        }
    },
};

export default turkishMessages;
