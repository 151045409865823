export enum BadgeUserType {
    Freelancer = 0,
    Corporate = 1,
    Individual = 2,
}
export enum BadgeVisibilityType {
    Nowhere = 0,
    JustProfile = 1,
    JustOffer = 2,
    Everywhere =3
}