import { DataGrid } from "@mui/x-data-grid"
export const MyCertificates = ({ record }: { record: any }) => {
    return (
        <DataGrid
            getRowId={(row: any) => row.name}
            rows={record?.certificate || []}
            columns={[
                {
                    field: "name",
                    headerName: "İsim",
                    sortable: false,
                    flex: 1
                },
                {
                    field: "from",
                    headerName: "Yer",
                    sortable: false,
                    flex: 1
                },
                {
                    field: "url",
                    headerName: "Adres",
                    sortable: false,
                    flex: 1
                },
                {
                    field: "year",
                    headerName: "Yıl",
                    sortable: false,
                    flex: 1
                }
            ]}
        />
    )
}
