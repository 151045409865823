import { useRecordContext } from "react-admin"

import { Typography } from "@mui/material"

type PropType = {
    source: string
    label?: string
    record?: any
}

const PriceFormat = (props: any) => {
    const {
        price,
        minimumFractionDigits = 0,
        maximumFractionDigits = 0,
        defaultValue = ""
    } = props
    if (price !== null && price !== 0 && price !== undefined) {
        const str = new Intl.NumberFormat("tr-TR", {
            style: "currency",
            currency: "TRY",
            minimumFractionDigits,
            maximumFractionDigits
        }).format(price / 100)

        return str
    }
    return defaultValue
}

const PriceField = (props: PropType) => {
    const { source, record: propRecord } = props
    const record = propRecord ? propRecord : useRecordContext(props)
    return (
        <Typography variant="body2">
            {PriceFormat({
                price: record[source],
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                defaultValue: "Belirtilmemiş"
            })}
        </Typography>
    )
}

export default PriceField
