// in src/users.js
import Avatar from "CustomFields/Avatar"
import ContentTypeField from "CustomFields/ContentTypeField"
import UserTypeField from "CustomFields/UserTypeField"
import * as React from "react"
import {
    List,
    Datagrid,
    TextField,
    EmailField,
    BooleanField,
    DateField,
    TopToolbar,
    FilterButton,
    ExportButton,
    TextInput,
    BooleanInput,
    EditButton,
    ImageInput,
    ImageField,
    ReferenceField,
    FilterList,
    FilterListItem,
    Pagination
} from "react-admin"

import { Box, Card, CardContent, Grid, Paper } from "@mui/material"
import StatusField from "CustomFields/StatusField"
import ColorfulBooleanField from "CustomFields/ColorfulBooleanField"
import DynamicFilterListItem from "CustomElements/DynamicFilterListItem"

const FilterSidebar = () => (
    <Paper elevation={20}>
        <Card>
            <CardContent>
                <FilterList label="İçerik Türü" icon={<></>}>
                    <DynamicFilterListItem
                        label="Blog"
                        name="blogType"
                        val={0}
                    />
                    <DynamicFilterListItem
                        label="Yardım"
                        name="blogType"
                        val={1}
                    />
                </FilterList>

                <FilterList label="Anasayfa Görünürlüğü" icon={<></>}>
                    <DynamicFilterListItem
                        label="Evet"
                        name="isHeadline"
                        val={true}
                    />
                    <DynamicFilterListItem
                        label="Hayır"
                        name="isHeadline"
                        val={false}
                    />
                </FilterList>

                <FilterList label="Yayın Durumu" icon={<></>}>
                    <DynamicFilterListItem
                        label="Evet"
                        name="releaseType"
                        val={1}
                    />
                    <DynamicFilterListItem
                        label="Hayır"
                        name="releaseType"
                        val={0}
                    />
                </FilterList>
            </CardContent>
        </Card>
    </Paper>
)

const DataList = () => {
    return (
        <>
            <Datagrid bulkActionButtons={false}>
                <TextField source="title" />
                <TextField source="slug" />
                <ContentTypeField source="blogType" label="Tip" />
                <ReferenceField
                    label="Ana Kategori"
                    source="mainCategoryId"
                    reference="content-category"
                    link="show">
                    <>
                        <TextField source="name" />
                    </>
                </ReferenceField>

                <StatusField source="releaseType" label="Yayın Durumu" />
                <ColorfulBooleanField
                    source="isHeadline"
                    label="Anasayfa Görünürlüğü"
                    trueLabel="Görünür"
                    falseLabel="Gizli"
                />
                <TextField source="headlineOrder" label="Anasayfa Sırası" />

                <EditButton label="Düzenle" />
            </Datagrid>
            <Pagination rowsPerPageOptions={[5,10, 25]} />
        </>
    )
}

export const BlogList = () => (
    <List
        hasShow
        pagination={<></>}
        sort={{
            field: "createDate",
            order: "DESC"
        }}>
        <Grid container spacing={2}>
            <Grid item xs={2}>
                <FilterSidebar />
            </Grid>
            <Grid item xs={10}>
                <DataList />
            </Grid>
        </Grid>
    </List>
)
