import { Circle } from "@mui/icons-material"
import { Tooltip } from "@mui/material"
import { BadgeVisibilityType } from "Types/BadgeTypes"
import { ReactElement } from "react"
import { useRecordContext } from "react-admin"

type PropType = {
    source: string
    label?: string
}
export const choiceBadgeVisibility = [
    {
        id: BadgeVisibilityType.Nowhere,
        name: "Görünür Değil"
    },
    {
        id: BadgeVisibilityType.JustProfile,
        name: "Sadece Profil"
    },
    {
        id: BadgeVisibilityType.JustOffer,
        name: "Sadece Teklifler"
    },
    {
        id: BadgeVisibilityType.Everywhere,
        name: "Görünür"
    }
]

const GetVisibilityStatus = (
    visibility:
        | {
              id: BadgeVisibilityType
              name: string
          }
        | any
): ReactElement => {
    let color: any
    switch (visibility.id) {
        case BadgeVisibilityType.Nowhere:
            color = "disabled"
            break
        case BadgeVisibilityType.JustProfile:
            color = "primary"
            break
        case BadgeVisibilityType.JustOffer:
            color = "info"
            break
        case BadgeVisibilityType.Everywhere:
            color = "success"
            break
    }
    return (
        <Tooltip title={visibility.name}>
            <Circle color={color} fontSize={"inherit"} />
        </Tooltip>
    )
}

const VisibilityStatusField = (props: PropType) => {
    const { source } = props
    const record = useRecordContext(props)
    const visibility = choiceBadgeVisibility.find(
        (s: any) => s.id === record[source]
    )
    return <>{GetVisibilityStatus(visibility)}</>
}

export default VisibilityStatusField
