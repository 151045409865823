import { ENDPOINTS, ServiceInfo, SERVICE_INFOS } from "./Config"

export const FindServiceInfoByResource = (resource: string): ServiceInfo => {
    let serviceInfo = SERVICE_INFOS.find((serviceInfo) =>
        serviceInfo.resources.includes(resource)
    )
    if (!serviceInfo) {
        throw new Error(`No service info found for resource ${resource}`)
    }
    return serviceInfo
}

export const GetServiceUrl = (resource: string) => {
    let serviceUrl = FindServiceInfoByResource(resource).serviceEndpoint
    return serviceUrl
}

export const ManipulateApiUrl = (resource: string): string => {
    let baseURL = FindServiceInfoByResource(resource).baseURL
    return baseURL
}

export const ManipulateResourceParam = (resource: string): string => {
    if (resource == "content-category") {
        return "category"
    }
    if (resource == "content-tag") {
        return "tag"
    }
    return resource
}

export const GenerateUrlByResource = (resource: string): string => {
    const baseUrl = ManipulateApiUrl(resource),
        serviceUrl = GetServiceUrl(resource),
        resourceParam = ManipulateResourceParam(resource)
    const url = `${baseUrl}/${serviceUrl}/${resourceParam}`

    return url
}

export const GetLoginEndpoint = (): string => {
    return `${ENDPOINTS.IdentityService}/identity-service/Admin/manager`
}

