import * as React from "react"
import {
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    ReferenceManyField,
    Datagrid,
    TextField,
    DateField,
    EditButton,
    required,
    NumberInput
} from "react-admin"

export const AccountEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" />
            <NumberInput source="status" validate={required()} />
        </SimpleForm>
    </Edit>
)
