import {
    BooleanInput,
    Create,
    NumberInput,
    SelectInput,
    SimpleForm,
    TextInput,
    required
} from "react-admin"
import { Paper } from "@mui/material"

const CommissionRateCreate = () => {
    return (
        <Create title={"Komisyon Oranı Oluştur"}>
            <Paper elevation={20}>
                <SimpleForm>
                    <TextInput
                        source="name"
                        name="name"
                        validate={[required()]}
                        fullWidth
                        label="Komisyon İsmi"
                    />
                    <NumberInput
                        source="employerRate"
                        name="employerRate"
                        label="İşveren Komisyon Oranı"
                        helperText="İşveren tarafına uygulanacak komisyon tutarını girmiş oluyorsunuz. Standart komisyon oranına tabi olacaksa komisyon oranı girmeyiniz!"
                    />
                    <NumberInput
                        source="freelancerRate"
                        name="freelancerRate"
                        label="Freelance Komisyon Oranı"
                        helperText="Freelance tarafına uygulanacak komisyon tutarını girmiş oluyorsunuz. Standart komisyon oranına tabi olacaksa komisyon oranı girmeyiniz!"
                    />
                </SimpleForm>
            </Paper>
        </Create>
    )
}

export default CommissionRateCreate
