import { Box, CircularProgress } from "@mui/material"
import { httpClient } from "Helpers"
import { GenerateUrlByResource } from "Providers/Helpers"
import { useNotify } from "react-admin"
import React, { useEffect, useState } from "react"
import { DashboardProjectViewType, DashboardUserViewType } from "./types"
import {
    Badge,
    PeopleAlt,
    Inventory,
    ContactSupport
} from "@mui/icons-material"
import { GeneralData } from "./ViewPartial/GeneralData"
import { ProjectInfoBoxList, UserInfoBoxList } from "./ViewPartial/InfoBoxList"

const DashboardView = () => {
    const [projectCount, setProjectCount] = useState<DashboardProjectViewType>(
        DashboardStateModel.projectCountModel
    )
    const [isLoadProjectCount, setIsLoadProjectCount] = useState(false)
    const [userCount, setUserCount] = useState<DashboardUserViewType>(
        DashboardStateModel.userCountModel
    )
    const [isLoadUserCount, setIsLoadUserCount] = useState(false)
    const [isFetch, setIsFetch] = useState(false)
    const notify = useNotify()
    const fetch = async (resource: string) => {
        if (!isFetch) {
            try {
                const baseUrl = GenerateUrlByResource(resource)
                httpClient(`${baseUrl}/dashboard/count`, {
                    method: "GET"
                })
                    .then((response) => JSON.parse(response.body))
                    .then((data) => {
                        if (resource === "project") {
                            setProjectCount(data.data)
                            setIsLoadProjectCount(true)
                        } else if (resource === "user") {
                            setUserCount(data.data)
                            setIsLoadUserCount(true)
                        }
                    })
            } catch {
                notify("Bir hata ile karşılaşıldı.")
                if (resource === "project") {
                    setIsLoadProjectCount(false)
                } else if (resource === "user") {
                    setIsLoadUserCount(false)
                }
            }
        }
    }
    useEffect(() => {
        fetch("project")
        fetch("user")
        setIsFetch(true)
    }, [isFetch])
    if ((!isLoadProjectCount && !isLoadUserCount) || !isFetch) {
        return (
            <Box style={DashboardStyle.loadingCircularProgress}>
                <CircularProgress />
            </Box>
        )
    }
    return (
        <Box>
            <Box style={DashboardStyle.generalDataList}>
                <GeneralData
                    icon={<Badge />}
                    title="Hesap"
                    totalCount={userCount.accountTotal}
                />
                <GeneralData
                    icon={<PeopleAlt />}
                    title="Profil"
                    totalCount={userCount.userTotal}
                />
                <GeneralData
                    icon={<Inventory />}
                    title="Proje"
                    totalCount={projectCount.projectTotal}
                />
                <GeneralData
                    icon={<ContactSupport />}
                    title="Profil Şikayetleri"
                    totalCount={userCount.complaintUserTotal}
                />
            </Box>
            <Box style={DashboardStyle.infoBoxList}>
                <UserInfoBoxList userCount={userCount} />
                <ProjectInfoBoxList projectCount={projectCount} />
            </Box>
        </Box>
    )
}

export default DashboardView

//#region stateModel
class DashboardStateModel {
    static projectCountModel = {
        completedProjectCount: 0,
        draftProjectCount: 0,
        pendingApprovalProjectCount: 0,
        projectTotal: 0,
        publishProjectCount: 0
    }
    static userCountModel = {
        accountTotal: 0,
        approvedCorporateEmployerCount: 0,
        approvedFreelancerCount: 0,
        approvedIndividualEmployerCount: 0,
        complaintUserTotal: 0,
        draftCorporateEmployerCount: 0,
        draftFreelancerCount: 0,
        draftIndividualEmployerCount: 0,
        pendingApprovalCorporateEmployerCount: 0,
        pendingApprovalFreelancerCount: 0,
        pendingApprovalIndividualEmployerCount: 0,
        totalCorporateEmployerCount: 0,
        totalFreelancerCount: 0,
        totalIndividualEmployerCount: 0,
        userTotal: 0
    }
}
//#endregion
//#region css
class DashboardStyle {
    static generalDataList: React.CSSProperties = {
        padding: "10px 5px",
        width: "100%",
        display: "flex",
        justifyContent: "space-between"
    }
    static infoBoxList: React.CSSProperties = {
        padding: "5px 0px",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column"
    }

    static loadingCircularProgress: React.CSSProperties = {
        height: "80%",
        justifyContent: "center",
        display: "flex",
        padding: "20% 0px"
    }
}
//#endregion
