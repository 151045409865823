import {
    Show,
    SimpleShowLayout,
    DateField,
    TextField,
    DeleteWithConfirmButton,
    EditButton,
    ReferenceField
} from "react-admin"
import { Card, Paper, Grid, Box,Typography } from "@mui/material"
import BackToListField from "CustomFields/BackToListField"
import ComplaintTypeField from "CustomFields/ComplaintTypeField"
import StatusField from "CustomFields/StatusField"
import Avatar from "CustomFields/Avatar"
import UserTypeField from "CustomFields/UserTypeField"
const Content = () => {
    return (
        <Paper elevation={20}>
            <SimpleShowLayout>
                    <ReferenceField
                        label="Şikayet Edilen"
                        source="complementedUserId"
                        reference="user"
                        link="show">
                        <>
                            <Box display="flex" style={{ gap: "6px", marginLeft: 2 }}>                        

                                <Avatar
                                    source="imagePath"
                                    title="Profil Resmi"
                                />
                                <TextField source="name" />
                            </Box>
                        </>
                    </ReferenceField>
                    <ReferenceField
                        label="Şikayetçi Olan"
                        source="complementerUserId"
                        reference="user"
                        link="show">
                        <>
                            <Box display="flex" style={{ gap: "10px" }}>
         
                                <Avatar
                                    source="imagePath"
                                    title="Profil Resmi"
                                />
                                <TextField source="name" />
                            </Box>
                        </>
                    </ReferenceField>

                <ComplaintTypeField
                    source="complaintType"
                    label="Şikayet Tipi"
                />

                <TextField label="Açıklama" source="description" />
                <StatusField source="status" label="Durum" />
                <DateField source="createDate" label="Oluşturma Tarihi" showTime={true} locales="tr" options={{weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour:"numeric", minute: "numeric" }}/>
                <Card sx={{ display: "flex", flexDirection: "row" , mt:1}}>
   
                        <DeleteWithConfirmButton confirmTitle="Emin misiniz?" />
                </Card>
            </SimpleShowLayout>
        </Paper>
    )
}

export const ComplaintView = () => {
    return (
        <Show title={"Geri Bildirim Detayı"} actions={<></>}>
            <Grid spacing={1} sx={{ mt: 5, mb: 6, px: 2 }}>
                <Grid xs={1}>
                    <BackToListField source="complaint" />
                </Grid>
                <Grid xs={7}>
                    <Content />
                </Grid>
            </Grid>
        </Show>
    )
}
