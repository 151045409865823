export enum ProjectStatus {
    PUBLISHED = 0,
    ON_PLANNING = 1,
    ON_APPROVE = 2,
    ON_DOING = 3,
    DRAFT = 4,
    COMPLETED = 5,
    UNCOMPLETED = 6,
    HISTORIC = 7,
    REJECTED = 8
}