// in src/users.js
import Avatar from "CustomFields/Avatar"
import UserTypeField from "CustomFields/UserTypeField"
import UserReleaseTypeField from "CustomFields/UserReleaseTypeField"
import * as React from "react"
import {
    List,
    Datagrid,
    TextField,
    EmailField,
    BooleanField,
    DateField,
    TopToolbar,
    FilterButton,
    ExportButton,
    TextInput,
    BooleanInput,
    ShowButton,
    ImageInput,
    ImageField,
    FilterList,
    FilterListItem,
    ReferenceField,
    Pagination
} from "react-admin"

import { UserAccountEnum, UserGroupType } from "Types/UserTypes"
import { Box, Card, CardContent, Grid, Paper } from "@mui/material"

const FilterSidebar = () => (
    <Paper elevation={20}>
        <Card>
            <CardContent>
                <FilterList label="Onay Durumu" icon={<></>}>
                    <FilterListItem
                        label="Onay Bekleyen"
                        value={{
                            releaseType: 2
                        }}
                    />
                    <FilterListItem
                        label="Onaylı"
                        value={{
                            releaseType: 1
                        }}
                    />
                    <FilterListItem
                        label="Reddedilen"
                        value={{
                            releaseType: 3
                        }}
                    />
                    <FilterListItem
                        label="Taslak"
                        value={{
                            releaseType: 0
                        }}
                    />
                </FilterList>

                <FilterList label="Kullanıcı Tipi" icon={<></>}>
                    <FilterListItem
                        label="Freelancer"
                        value={{
                            userType: UserAccountEnum.Freelancer,
                            groupType: undefined
                        }}
                    />
                    <FilterListItem
                        label="İşveren"
                        value={{
                            userType: UserAccountEnum.Employee,
                            groupType: UserGroupType.Company
                        }}
                    />
                    <FilterListItem
                        label="Bireysel İşveren"
                        value={{
                            userType: UserAccountEnum.Employee,
                            groupType: UserGroupType.Single
                        }}
                    />
                </FilterList>
            </CardContent>
        </Card>
    </Paper>
)

const DataList = () => {
    return (
        <>
            <Datagrid bulkActionButtons={false}>
                <Avatar
                    source="imagePath"
                    title="Profil Resmi"
                    label="Profil Resmi"
                />
                <TextField source="name" label="İsim" />
                <ReferenceField
                    label="Hesap"
                    source="accountId"
                    reference="account"
                    link="show">
                    <>
                        <TextField source="firstname" />{" "}
                        <TextField source="lastname" />
                    </>
                </ReferenceField>
                <UserTypeField source="userType" label="Kullanıcı Tipi" />
                <UserReleaseTypeField
                    source="releaseType"
                    label="Onay Durumu"
                />
                <DateField source="createDate" label="Kayıt Tarihi" />
                <ShowButton label="Detay" />
            </Datagrid>
            <Pagination rowsPerPageOptions={[5,10, 25]} />
        </>
    )
}

export const UserList = () => (
    <List
        exporter={false}
        pagination={<></>}
        hasShow
        sort={{
            field: "createDate",
            order: "DESC"
        }}>
            <Grid container spacing={2}>
            <Grid item xs={2}>
                <FilterSidebar />
            </Grid>
            <Grid item xs={10}>
                <DataList />
            </Grid>
        </Grid>
        </List>
)
