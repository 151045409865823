import React from "react"
import MUIAvatar from "@mui/material/Avatar"
import { useRecordContext } from "react-admin"
import { UserAccountEnum, UserGroupType } from "Types/UserTypes"
import Chip from '@mui/material/Chip';

type PropType = {
    source: string
    label?: string
}


const UserTypeField = (props: PropType) => {
    const { source } = props
    const record = useRecordContext(props)
    return (
        <>
            {record[source] == UserAccountEnum.Freelancer
                ? <Chip label="Freelancer" color="default"  size="small"/>
                : record["groupType"] === UserGroupType.Company ?  <Chip label="İşveren" color="info" size="small" /> : <Chip label="Bireysel İşveren" color="info"  variant="outlined" size="small"/> }
        </>
    )
}

export default UserTypeField
