import { Typography } from "@mui/material"

export const TableRow = ({ label, val }: { label: string; val: any }) => {
    return (
        <tr>
            <td width="150">
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    {label}
                </Typography>
            </td>
            <td>
                <Typography variant="body2"> {val}</Typography>
            </td>
        </tr>
    )
}
