import { DataGrid } from "@mui/x-data-grid"
export const MyLanguages = ({
    record,
    LanguageLevels
}: {
    record: any
    LanguageLevels: any
}) => {
    return (
        <DataGrid
            getRowId={(row: any) => row.languageId}
            rows={record?.language || []}
            columns={[
                {
                    field: "İsim",
                    sortable: false,
                    flex: 1,
                    renderCell: (params: any) => {
                        return (
                            <div className="rowitem">
                                {params.row.details.displayName}
                            </div>
                        )
                    }
                },
                {
                    field: "Seviye",
                    sortable: false,
                    flex: 1,
                    renderCell: (params: any) => {
                        const languageLevelText = LanguageLevels.find(
                            (s: any) => s.id === params.row.level
                        )
                        return (
                            <div className="rowitem">
                                {languageLevelText?.name}
                            </div>
                        )
                    }
                }
            ]}
        />
    )
}
