import React, { ReactElement } from "react"
import { Avatar as MUIAvatar, Tooltip } from "@mui/material"
import { useRecordContext } from "react-admin"
import { UserAccountEnum } from "Types/UserTypes"
import { CircleOutlined, Circle } from "@mui/icons-material"

type PropType = {
    source: string
    label?: string
    trueLabel?: string
    falseLabel?: string
}

const GetStatusText = (
    val: boolean,
    trueLabel: string,
    falseLabel: string
): ReactElement => {
    const isSuccess = val
    return (
        <Tooltip title={isSuccess ? trueLabel : falseLabel}>
            {isSuccess ? (
                <Circle color="success" fontSize={"inherit"} />
            ) : (
                <CircleOutlined fontSize={"inherit"} color="disabled" />
            )}
        </Tooltip>
    )
}

const ColorfulBooleanField = (props: PropType) => {
    const { source, trueLabel = "Evet", falseLabel = "Hayır" } = props
    const record = useRecordContext(props)
    return <>{GetStatusText(record[source], trueLabel, falseLabel)}</>
}

export default ColorfulBooleanField
