import { TableRow } from "../Components/TableRow"
import { Alert } from "@mui/material"

export const MyLocation = ({ record }: { record: any }) => {
    return (
        <>
            {record?.location?.length > 0 ? (
                <table className="admin-table">
                    <tbody>
                        <TableRow
                            label="Ülke"
                            val={record?.location[0]?.details?.countryName}
                        />
                        <TableRow
                            label="Şehir"
                            val={record?.location[0]?.details?.cityName}
                        />
                        <TableRow
                            label="İlçe"
                            val={record?.location[0]?.details?.townName}
                        />
                        <TableRow
                            label="Adres"
                            val={record?.location[0]?.address}
                        />
                        <TableRow
                            label="Posta Kodu"
                            val={record?.location[0]?.postCode}
                        />
                    </tbody>
                </table>
            ) : (
                <Alert severity="warning">Konum bilgisi bulunamadı.</Alert>
            )}
        </>
    )
}
