import {
    Edit,
    SimpleForm,
    ReferenceInput,
    SelectArrayInput,
    SelectInput,
    required,
    TextInput,
    Toolbar,
    SaveButton,
    DeleteWithConfirmButton
} from "react-admin"
import { Grid, Paper } from "@mui/material"
import { Help } from "@mui/icons-material"
import { tagBadgeUser } from "CustomFields/BadgeUserTypeField"
import { choiceBadgeVisibility } from "CustomFields/VisibilityStatusField"
import { StatusType } from "Types/StatusTypes"
import { useState } from "react"
import { BadgeIdentifierInput } from "./components/BadgeIdentifierInput"

const BadgeEdit = () => {
    const [identifierValid, setIdentifierValid] = useState<number>(2)
    return (
        <Edit>
            <SimpleForm
                toolbar={
                    <Toolbar
                        style={{
                            justifyContent: "space-between",
                            display: "flex"
                        }}>
                        <SaveButton disabled={identifierValid != 1} />
                        <DeleteWithConfirmButton />
                    </Toolbar>
                }>
                <Grid container spacing={2} sx={{ mb: 5, px: 2 }}>
                    <Grid item xs={3}>
                        <Paper elevation={20} style={{ padding: 12 }}>
                            <SelectArrayInput
                                validate={required()}
                                source="userType"
                                choices={tagBadgeUser}
                                label="Uygulanacak Profiller"
                                fullWidth
                            />
                            <ReferenceInput
                                label="Komisyon"
                                source="commissionRateId"
                                reference="commission-rate">
                                <SelectInput
                                    name="commissionRateId"
                                    optionText={"name"}
                                    validate={required()}
                                    label="Komisyon"
                                    helperText="Rozetin komisyon oranında  farklı bir oran uygulanması gerekiyorsa ona özel olan komisyonu, eğer uygulanmaması gerekiyorsa varsayılan olarak oluşturduğunuz komisyonu seçiniz."
                                />
                            </ReferenceInput>
                            <SelectInput
                                source="visibility"
                                name="visibility"
                                label="Görünürlük Durumu"
                                validate={required()}
                                choices={choiceBadgeVisibility}
                            />
                            <SelectInput
                                fullWidth
                                validate={[required()]}
                                name="status"
                                source="status"
                                label="Durum"
                                choices={[
                                    { id: StatusType.Passive, name: "Pasif" },
                                    { id: StatusType.Active, name: "Aktif" }
                                ]}
                                helperText="Pasif durumunda olan rozetler profil tanımlama kısmında kullanıma açık olmayacaktır."
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={9}>
                        <Paper elevation={20} style={{ padding: 12 }}>
                            <TextInput
                                source="name"
                                name="name"
                                validate={[required()]}
                                fullWidth
                                label="İsim"
                            />
                            <BadgeIdentifierInput
                                identifierValid={identifierValid}
                                setIdentifierValid={(val: number) =>
                                    setIdentifierValid(val)
                                }
                            />
                            <TextInput
                                style={{ marginTop: "12px" }}
                                source="description"
                                name="description"
                                validate={[required()]}
                                fullWidth
                                label="Açıklama"
                                helperText="Bu kısma doldurulan bilgiler sayfa ziyaretçisine rozet hakkında bilgi vermesi içindir."
                            />
                            <TextInput
                                style={{ marginTop: "12px" }}
                                source="note"
                                name="note"
                                fullWidth
                                label="Rozetin Amacı"
                                helperText="Bu kısma doldurulan bilgiler rozetin hangi profillere, ne amaçla hangi koşullarda uygulanacağını belirlenmesi içindir."
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    )
}

export default BadgeEdit
