import { stringify } from "query-string"
import { fetchUtils, GetListParams } from "ra-core"
import {
    GenerateUrlByResource,
    GetServiceUrl,
    ManipulateApiUrl,
    ManipulateResourceParam
} from "./Helpers"

export default (httpClient = fetchUtils.fetchJson): any => ({
    create: (resource: string, params: any) => {
        const url = GenerateUrlByResource(resource)

        return httpClient(url, {
            method: "POST",
            body: JSON.stringify(params.data)
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id }
        }))
    },
    getList: (resource: string, params: GetListParams) => {
        const { page, perPage } = params.pagination
        const { field, order } = params.sort

        const rangeStart = (page - 1) * perPage
        const rangeEnd = page * perPage

        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([rangeStart, rangeEnd]),
            filter: JSON.stringify(params.filter)
        }

        const url = `${GenerateUrlByResource(resource)}?${stringify(
            query
        )}`

        return httpClient(url).then(
            ({ headers, json }: { headers: any; json: any }) => {
                if (!headers.has("x-total-count")) {
                    throw new Error(
                        "The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?"
                    )
                }
                return {
                    data: json || [],
                    total: headers.get("x-total-count")
                }
            }
        )
    },
    getOne: (resource: any, params: any) => {

        const url = `${GenerateUrlByResource(resource)}/${params.id}`

        return httpClient(url).then(({ json }) => ({
            data: json.data
        }))
    },
    getMany: (resource: any, params: any) => {
        const query = {
            filter: JSON.stringify({
                ids:
                    params.ids?.filter(
                        (s: string) => s !== "" && s !== null && s !== undefined
                    ) || []
            })
        }

        const url = `${GenerateUrlByResource(resource)}?${stringify(
            query
        )}`
        
        return httpClient(url).then(({ json }) => ({ data: json }))
    },
    getManyReference: (resource: any, params: any) => {
        const { page, perPage } = params.pagination
        const { field, order } = params.sort

        const rangeStart = (page - 1) * perPage
        const rangeEnd = page * perPage

        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([rangeStart, rangeEnd]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id
            })
        }

        const url = `${GenerateUrlByResource(resource)}?${stringify(
            query
        )}`

        return httpClient(url).then(({ headers, json }) => {
            if (!headers.has("x-total-count")) {
                throw new Error(
                    "The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?"
                )
            }
            return {
                data: json,
                total: headers.get("x-total-count")
            }
        })
    },
    update: (resource: any, params: any) => {
        const url = `${GenerateUrlByResource(resource)}/${params.id}`
        
        return httpClient(url, {
            method: "PUT",
            body: JSON.stringify(params.data)
        }).then(({ json }) => ({ data: json }))
    },
    delete: (resource: any, params: any) => {

        const url = `${GenerateUrlByResource(resource)}/${params.id}`

        return httpClient(url, {
            method: "DELETE"
        }).then(({ json }) => ({ data: json }))
    }
})
