import {
    Typography,
    Paper,
} from "@mui/material"
import Avatar from "CustomFields/Avatar"
import PriceField from "CustomFields/PriceField"
import OfferGroupTypeField from "CustomFields/OfferGroupTypeField"
import OfferOveralTypeField from "CustomFields/OfferOveralTypeField"
import {
    TextField,
    ReferenceField,
    useRecordContext,
    RecordContextProvider
} from "react-admin"
const GroupOfferList = (data : any) => {
    return (
        <Paper elevation={20} sx={{marginTop:2, paddingTop:0.1, paddingBottom: 2}}>
            <Typography variant="h6" style={{ textAlign: "left", margin: 15 }}>
                Ekip Teklifleri
            </Typography>
            <table className="admin-table">
                <tbody>
                    <tr>
                        <td></td>
                        <td>İsim</td>
                        <td>Açıklama</td>
                        <td>Durum</td>
                        <td>Fiyat</td>
                    </tr>
                    {Array.from(data.data).map((item: any, i: number) => (
                        <tr key={i}>
                            <RecordContextProvider value={item}>
                                <td>
                                    <ReferenceField
                                        source={"userId"}
                                        reference="user">
                                        <Avatar
                                            source="imagePath"
                                            title="Profil Resmi"
                                            size={32}
                                            sx={{
                                                width: 32,
                                                height: 32,
                                                mx: "auto"
                                            }}></Avatar>
                                    </ReferenceField>
                                </td>
                                <td>
                                    <ReferenceField
                                        source="userId"
                                        reference="user" link="show">
                                        <TextField source="name" />
                                    </ReferenceField>
                                </td>
                                <td> <TextField source="description" /></td>
                                <td>
                                    <OfferOveralTypeField
                                        source={
                                            "overalStatus"
                                        }></OfferOveralTypeField>
                                </td>
                                <td>
                                    <PriceField source={"budgetPrice"} />
                                </td>
                            </RecordContextProvider>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Paper>
    )
   }
   
   export default GroupOfferList