import React, { ReactElement } from "react"
import MUIAvatar from "@mui/material/Avatar"
import { useRecordContext } from "react-admin"
import { UserAccountEnum } from "Types/UserTypes"
import { Circle } from "@mui/icons-material"
import { StatusType } from "Types/StatusTypes"

type PropType = {
    label?: string
    record?: any
}

const TextFieldSelf = (props: PropType) => {
    const { record: propRecord } = props
    const record = propRecord ? propRecord : useRecordContext(props)

    return <>{record}</>
}

export default TextFieldSelf
