import { Link, Button} from "react-admin"
import {ArrowBack} from "@mui/icons-material"
type PropType= {
    source: string
}
const BackToListField = (props: PropType) => {
   const source : string = props.source
  
    return (<Link to={`/${source}`}>
    <Button
        label="Listeye Dön"
        variant="outlined"
        color="primary"
        startIcon={<ArrowBack />}
        sx={{ mb: 2 }}
    />
</Link>)  
}

export default BackToListField