import Avatar from "CustomFields/Avatar"
import * as React from "react"
import {
    List,
    Datagrid,
    TextField,
    EmailField,
    BooleanField,
    DateField,
    TopToolbar,
    FilterButton,
    ExportButton,
    TextInput,
    BooleanInput,
    ShowButton,
    ImageInput,
    ImageField,
    ReferenceField,
    FilterList,
    FilterListItem,
    Pagination,
    RecordContext
} from "react-admin"

import ColorfulBooleanField from "CustomFields/ColorfulBooleanField"
import ProjectReleaseTypeField from "CustomFields/ProjectReleaseTypeField"

import { Box, Card, CardContent, Grid, Paper } from "@mui/material"
import { ReleaseType, StateType } from "Types/ReleaseTypes"

const ReleaseTypeFilterList = ({
    label,
    releaseType
}: {
    label: string
    releaseType: number
}) => {
    return <FilterListItem label={label} value={{ releaseType }} />
}

const FilterSidebar = () => (
    <Paper elevation={20}>
        <Card>
            <CardContent>
                <FilterList label="Onay Durumu" icon={<></>}>
                    <ReleaseTypeFilterList
                        label="Bekleyen"
                        releaseType={ReleaseType.inSubmission}
                    />
                    <ReleaseTypeFilterList
                        label="Reddedilen"
                        releaseType={ReleaseType.rejected}
                    />
                    <ReleaseTypeFilterList
                        label="Taslak"
                        releaseType={ReleaseType.draft}
                    />
                    <FilterListItem
                        label="Tamamlanan"
                        value={{ stateType: StateType.finished }}
                    />
                </FilterList>
            </CardContent>
        </Card>
    </Paper>
)

const DataList = () => {
    return (
        <>
            <Datagrid bulkActionButtons={false}>
                <TextField source="title" label="Başlık" />
                <ReferenceField
                    label="Kullanıcı"
                    source="userId"
                    reference="user"
                    link="show">
                    <>
                        <Box display="flex" style={{ gap: "6px" }}>
                            <Avatar source="imagePath" title="Profil Resmi" />
                            <TextField source="name" />
                        </Box>
                    </>
                </ReferenceField>
                <ColorfulBooleanField
                    source="isPersonal"
                    label="Kişisel Proje"
                />
                <ProjectReleaseTypeField label="Durum" />
                <DateField source="createDate" label="Oluşturma Tarihi" />

                <ShowButton />
            </Datagrid>
            <Pagination rowsPerPageOptions={[5, 10, 25]} />
        </>
    )
}

export const ProjectList = () => (
    <List
        exporter={false}
        pagination={<></>}
        hasShow
        sort={{
            field: "createDate",
            order: "DESC"
        }}>
        <Grid container spacing={2}>
            <Grid item xs={2}>
                <FilterSidebar />
            </Grid>
            <Grid item xs={10}>
                <DataList />
            </Grid>
        </Grid>
    </List>
)
