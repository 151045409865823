import ComplaintTypeField from "CustomFields/ComplaintTypeField"
import StatusField from "CustomFields/StatusField"
import { Box } from "@mui/material"
import Avatar from "CustomFields/Avatar"

import {
    Datagrid,
    DateField,
    List,
    Pagination,
    ReferenceField,
    ShowButton,
    TextField
} from "react-admin"

const DataList = () => {
    return (
        <>
            <Datagrid bulkActionButtons={false}>
                <ReferenceField
                    label="Şikayet Edilen"
                    source="complementedUserId"
                    reference="user"
                    link="show">
                    <>
                        <Box display="flex" style={{ gap: "6px" }}>
                            <Avatar source="imagePath" title="Profil Resmi" />
                            <TextField source="name" />
                        </Box>
                    </>
                </ReferenceField>
                <ReferenceField
                    label="Şikayetçi Olan"
                    source="complementerUserId"
                    reference="user"
                    link="show">
                    <>
                        <Box display="flex" style={{ gap: "6px" }}>
                            <Avatar source="imagePath" title="Profil Resmi" />
                            <TextField source="name" />
                        </Box>
                    </>
                </ReferenceField>
                <ComplaintTypeField
                    source="complaintType"
                    label="Şikayet Tipi"
                />
                
                <TextField label="Açıklama" source="description"  sx={{
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1
            }} />
                <StatusField source="status" label= "Durum" />
                <DateField source="createDate" label="Oluşturma Tarihi" />
                <ShowButton label="Detay"/>
            </Datagrid>
            <Pagination rowsPerPageOptions={[5, 10, 25]} />
        </>
    )
}

export const ComplaintList = () => {
    return (
        <List
            exporter={false}
            pagination={<></>}
            hasShow
            sort={{ field: "createDate", order: "DESC" }}>
            <DataList />
        </List>
    )
}
