import {
    Edit,
    SelectInput,
    SelectArrayInput,
    SimpleForm,
    TextInput,
    required,
    BooleanInput,
    TextField,
    Labeled
} from "react-admin"
import {
    NotificationChannelType,
    NotificationTargetTag
} from "Types/NotificationTypes"
import AceEditor from "react-ace"

import * as ace from "ace-builds/src-noconflict/ace"
import "ace-builds/src-noconflict/mode-html"
import "ace-builds/src-noconflict/mode-handlebars"
import "ace-builds/src-noconflict/theme-tomorrow"
import "ace-builds/src-noconflict/ext-language_tools"
import { useInput } from "react-admin"
import { useWatch, useFormContext } from "react-hook-form"
import BackToListField from "CustomFields/BackToListField"

import {
    Alert,
    Box,
    Typography,
    Divider,
    Grid,
    Paper,
    Card,
    CardContent
} from "@mui/material"
import NotificationTypeField from "CustomFields/NotificationTypeField"
import { tagTargets } from "CustomFields/NotificationTargetField"
/*
const SUPPORTEDFUNCTIONS = [
    { name: "User.Name", meta: "Name", value: "User.Name" },
    {
        name: "avg1k",
        meta: "Calculates the average of a metric avg1k",
        value: "avg1k"
    }
]

let langTools = ace.acequire("ace/ext/language_tools")

var customCompleter = {
    identifierRegexps: [/User.+/],
    // @ts-ignore
    getCompletions: function (editor, session, pos, prefix, callback) {
        // your code
        var completions = [...SUPPORTEDFUNCTIONS]
        callback(null, completions)
    }
}

langTools.setCompleters([customCompleter])
*/

/*

{
  "data": {
    "id": 4,
    "name": "HesapOnayı(Email)",
    "title": "Hesap Onayı",
    "body": "Seni aramızda görmek güzel. Expero'yu kullanmaya başlamak için hemen <a href=\"/profile-wizard\">profilini tamamla</a>.",
    "text": "Seni aramızda görmek güzel. Expero'yu kullanmaya başlamak için hemen profilini tamamla.",
    "note": "Variables: (ActionTarget, Account (ActionTarget), SiteUrl))",
    "notificationType": 4,
    "notificationChannelType": 0,
    "allow": [
      3
    ],
    "disAllow": [
      
    ],
    "isMandatory": true,
    "status": 1,
    "createDate": "2022-06-24T11:33:35.381984Z"
  }
}

*/

const EditorInput = (props: any) => {
    const {
        field,
        fieldState: { isTouched, invalid, error },
        formState: { isSubmitted }
    } = useInput(props)

    const channelType = useWatch({ name: "notificationChannelType" })
    const { height = 200, width = "100%", mode = "html", name } = props

    if (
        channelType === NotificationChannelType.Sms &&
        (name == "text" || name == "title")
    )
        return null
    if (channelType === NotificationChannelType.Web && name == "text")
        return null
    if (channelType === NotificationChannelType.MobilePush && name == "text")
        return null

    return (
        <Box mt={2} mb={2} sx={{ width: "100%" }}>
            <Typography variant="body2" mb={2}>
                {channelType === NotificationChannelType.Email
                    ? props.label
                    : channelType === NotificationChannelType.Web
                    ? props.label.replace("(HTML)", "Buton, Link, Resim")
                    : props.label.replace("(HTML)", "")}
            </Typography>
            <AceEditor
                mode={mode}
                wrapEnabled={true}
                theme="tomorrow"
                {...field}
                editorProps={{
                    $blockScrolling: true
                }}
                setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    autoScrollEditorIntoView: true,
                    enableSnippets: true,
                    wrapEnabled: true,
                    fontSize: 13
                }}
                height={height}
                width={width}
            />
        </Box>
    )
}

const Inputs = () => {
    return (
        <Paper elevation={20}>
            <Card>
                <CardContent>
                    <TextInput
                        source="name"
                        label="İsim"
                        validate={required()}
                        fullWidth
                    />

                    <EditorInput
                        source="title"
                        name="title"
                        label="Başlık"
                        fullWidth
                        mode="html"
                        height={150}
                    />

                    <EditorInput
                        source="text"
                        name="text"
                        label="İçerik (Düz Metin)"
                        fullWidth
                        mode="html"
                        height={100}
                    />

                    <EditorInput
                        source="body"
                        name="body"
                        label="İçerik (HTML)"
                        fullWidth
                    />

                    <Alert severity="info">
                        <TextField source="note" />
                    </Alert>
                </CardContent>
            </Card>
        </Paper>
    )
}

export const Aside = () => {
    return (
        <>
            <BackToListField source="template" />

            <Paper elevation={20}>
                <Card>
                    <CardContent>
                        <Labeled label="Bildirim Türü İsmi" sx={{ mb: 2 }}>
                            <TextField source="notificationTypeName"></TextField>
                        </Labeled>
                        <Box
                            display="flex"
                            sx={{ width: "100%", gap: 2, mb: -2 }}>
                            <Labeled label="Bildirim Türü" sx={{ mb: 2 }}>
                                <TextField source="notificationType" />
                            </Labeled>

                            <Labeled label="Bildirim Kanalı" sx={{ mb: 2 }}>
                                <Typography variant="body2">
                                    <NotificationTypeField
                                        source="notificationChannelType"
                                        label="Kanal"
                                    />
                                </Typography>
                            </Labeled>
                        </Box>
                    </CardContent>
                </Card>
            </Paper>

            <Paper elevation={20} sx={{ mt: 1 }}>
                <Card>
                    <CardContent>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                            Gönderim Grupları
                        </Typography>
                        <SelectArrayInput
                            fullWidth
                            source="allow"
                            className="multiple-tags"
                            label="Dahil"
                            choices={tagTargets}
                        />

                        <SelectArrayInput
                            fullWidth
                            source="disAllow"
                            className="multiple-tags"
                            label="Hariç"
                            choices={tagTargets}
                        />

                        <Typography variant="body2" sx={{ mt: -1, mb: 1 }}>
                            Bildirim hedefi "dahil" grubunda ise ve "hariç"
                            grubunda değilse, hedefe bildirim gönderilir.
                            <br />
                            <i>
                                Örn: Eğer şirketteki sadece Ghost üyelere
                                bildirim göndermek istiyorsak. Dahil : Şirket
                                Üyesi, Hariç: Freelancer
                            </i>
                        </Typography>
                    </CardContent>
                </Card>
            </Paper>

            <Paper elevation={20} sx={{ mt: 1 }}>
                <Card>
                    <CardContent>
                        <Box display="flex" flexDirection="column">
                            <Typography variant="h6" sx={{ mb: 2 }}>
                                Ek Ayarlar
                            </Typography>

                            <BooleanInput
                                name="isMandatory"
                                source="isMandatory"
                                label="Zorunlu bildirim"
                            />
                            <Typography variant="body2" sx={{ mt: -2 }}>
                                Zorunlu bildirimler, kullanıcı ilgili kanalı
                                sessize almış olsa bile çalışır.
                            </Typography>

                            <Divider sx={{ my: 2 }} />

                            <SelectInput
                                sx={{ mb: -2 }}
                                source="status"
                                label="Durum"
                                emptyValue={false}
                                validate={required()}
                                choices={[
                                    { id: 0, name: "Pasif" },
                                    { id: 1, name: "Yayında" }
                                ]}
                            />
                        </Box>
                    </CardContent>
                </Card>
            </Paper>
        </>
    )
}

export const TemplateEditView = () => {
    return (
        <Edit title="Bildirim İçeriği Düzenle">
            <SimpleForm id="id">
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Aside />
                    </Grid>
                    <Grid item xs={9}>
                        <Inputs />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    )
}
