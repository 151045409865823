//@ts-nocheck
import { createTheme } from "@mui/material"
import { Stack, Alert } from "@mui/material"

export const AdminThemePalette = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1000,
            lg: 1200,
            xl: 1920
        }
    },
    components: {
        MuiListItemButton :{
            styleOverrides: {
                root : {
                    paddingLeft: '14px !important',
                    marginBottom: 5,
                    marginTop: 5
                }
            }
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    border: "1px solid #f0f0f0",
                    "& .MuiDataGrid-columnsContainer": {
                        backgroundColor: "#fafafa"
                    },
                    "& .MuiDataGrid-iconSeparator": {
                        display: "none"
                    },
                    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {},
                    "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
                        borderTop: `1px solid #f0f0f0`
                    },
                    "& .MuiDataGrid-footerContainer ": {
                        borderTop: `1px solid #f0f0f0`
                    },
                    "& .MuiDataGrid-cell": {
                        color: "rgba(0,0,0,.85)"
                    },
                    "& .MuiPaginationItem-root": {
                        borderRadius: 0
                    }
                }
            },
            defaultProps: {
                autoHeight: true,
                disableColumnMenu: true,
                isRowSelectable: () => false,
                components: {
                    NoRowsOverlay: () => (
                        <Stack
                            height="100%"
                            alignItems="center"
                            justifyContent="center">
                            <Alert severity="warning">İçerik bulunamadı</Alert>
                        </Stack>
                    )
                }
            }
        },
        MuiLink:{
            styleOverrides:{
                defaultProps:{
                    underline:"hover"
                },
                root: {
                    fontFamily: "Inter",
                    fontSize: 13,
                    fontWeight: "bold"
                },
            }
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true
            },
            styleOverrides: {
                root: {
                    textTransform: "none"
                },
                endIcon: {
                    display: "none"
                },
                sizeSmall: {
                    padding: "6px 16px"
                },
                sizeMedium: {
                    padding: "8px 20px"
                },
                sizeLarge: {
                    padding: "11px 24px"
                },
                textSizeSmall: {
                    padding: "7px 12px"
                },
                textSizeMedium: {
                    padding: "9px 16px"
                },
                textSizeLarge: {
                    padding: "12px 16px"
                }
            }
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true
            }
        },
        MuiSwitch:{
            styleOverrides: {
                root: {
                padding: 8,
                '& .MuiSwitch-track': {
                    borderRadius: 22 / 2,
                    '&:before, &:after': {
                      content: '""',
                      position: 'absolute',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      width: 16,
                      height: 16,
                    },
                    '&:before': {
                      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="%23000000" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
                      left: 12,
                    },
                    '&:after': {
                      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="%23fff" d="M19,13H5V11H19V13Z" /></svg>')`,
                      right: 12,
                    },
                  },
                  '& .MuiSwitch-thumb': {
                    boxShadow: 'none',
                    width: 16,
                    height: 16,
                    margin: 2,
                  },
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    "&:last-child": {
                        paddingBottom: 16
                    }
                }
            },
            defaultProps:{
                sx : {
                    p : 2
                }
            }
        },
        MuiCardHeader: {
            defaultProps: {
                titleTypographyProps: {
                    variant: "h6"
                },
                subheaderTypographyProps: {
                    variant: "body2"
                }
            },
            styleOverrides: {
                root: {
                    padding: "32px 24px"
                }
            }
        },
        MuiCssBaseline: {
            styleOverrides: {
                "*": {
                    boxSizing: "border-box",
                    margin: 0,
                    padding: 0
                },
                html: {
                    MozOsxFontSmoothing: "grayscale",
                    WebkitFontSmoothing: "antialiased",
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "100%",
                    width: "100%"
                },
                body: {
                    display: "flex",
                    flex: "1 1 auto",
                    flexDirection: "column",
                    minHeight: "100%",
                    width: "100%"
                },
                "#__next": {
                    display: "flex",
                    flex: "1 1 auto",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%"
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: "#E6E8F0"
                }
            }
        },
        MuiAppBar: {
            defaultProps: {
                position: "absolute"
            }
        },
        MuiTable: {
            defaultProps: {
                stickyHeader: true
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    backgroundColor: "#FFF !important",
                    "&:nth-child(2n)": {
                        backgroundColor: "#f6f6f6 !important",
                    },
                    "&:hover": {
                        backgroundColor: "#ebebeb !important", 
                    }
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    backgroundColor: "#FFF !important",
                    borderBottomLeftRadius: 12,
                    borderBottomRightRadius: 12
                }
            }
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    backgroundColor: "#F3F4F6  !important",
                    ".MuiTableCell-root": {
                        color: "#374151 !important",
                        backgroundColor: "#F3F4F6  !important",
                        paddingTop: 16,
                        paddingBottom: 16
                    },
                    borderBottom: "none",
                    "& .MuiTableCell-root": {
                        borderBottom: "none",
                        fontSize: "12px",
                        fontWeight: 600,
                        lineHeight: 1,
                        letterSpacing: 0.5,
                        textTransform: "uppercase"
                    },
                    "& .MuiTableCell-paddingCheckbox": {
                        paddingTop: 4,
                        paddingBottom: 4
                    }
                }
            }
        },
        MuiPaper:{
            styleOverrides:{
                root: {
                backgroundColor: '#fafafa'
                }
            },
            defaultProps : {
                elevation: 0
            }
        },
        MuiDivider : {
            styleOverrides : {
                root : {
                    borderColor : '#d4d5db'
                }
            }
        }
    },
    palette: {
        action: {
            active: "#6B7280",
            focus: "rgba(55, 65, 81, 0.12)",
            hover: "rgba(55, 65, 81, 0.04)",
            selected: "rgba(55, 65, 81, 0.08)",
            disabledBackground: "rgba(55, 65, 81, 0.12)",
            disabled: "rgba(55, 65, 81, 0.26)"
        },
        background: {
            default: "#F9FAFC",
            paper: "#FFFFFF"
        },
        divider: "#E6E8F0",
        primary: {
            main: "#5048E5",
            contrastText: "#FFFFFF"
        },
        secondary: {
            main: "#10B981",
            contrastText: "#FFFFFF"
        },
        success: {
            main: "#14B8A6",
            contrastText: "#FFFFFF"
        },
        info: {
            main: "#2196F3",
            contrastText: "#FFFFFF"
        },
        warning: {
            main: "#FFB020",
            contrastText: "#FFFFFF"
        },
        error: {
            main: "#D14343",
            contrastText: "#FFFFFF"
        },
        text: {
            primary: "#121828",
            secondary: "#65748B",
            disabled: "rgba(55, 65, 81, 0.48)"
        }
    },
    shape: {
        borderRadius: 8
    },
    shadows: [
        "none",
        "0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)",
        "0px 1px 2px rgba(100, 116, 139, 0.12)",
        "0px 1px 4px rgba(100, 116, 139, 0.12)",
        "0px 1px 5px rgba(100, 116, 139, 0.12)",
        "0px 1px 6px rgba(100, 116, 139, 0.12)",
        "0px 2px 6px rgba(100, 116, 139, 0.12)",
        "0px 3px 6px rgba(100, 116, 139, 0.12)",
        "0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(100, 116, 139, 0.12)",
        "0px 5px 12px rgba(100, 116, 139, 0.12)",
        "0px 5px 14px rgba(100, 116, 139, 0.12)",
        "0px 5px 15px rgba(100, 116, 139, 0.12)",
        "0px 6px 15px rgba(100, 116, 139, 0.12)",
        "0px 7px 15px rgba(100, 116, 139, 0.12)",
        "0px 8px 15px rgba(100, 116, 139, 0.12)",
        "0px 9px 15px rgba(100, 116, 139, 0.12)",
        "0px 10px 15px rgba(100, 116, 139, 0.12)",
        "0px 12px 22px -8px rgba(100, 116, 139, 0.25)",
        "0px 13px 22px -8px rgba(100, 116, 139, 0.25)",
        "0px 14px 24px -8px rgba(100, 116, 139, 0.25)",
        "0px 10px 10px rgba(31, 41, 55, 0.04), 0px 20px 25px rgba(31, 41, 55, 0.1)",
        "0px 25px 50px rgba(100, 116, 139, 0.25)",
        "0px 25px 50px rgba(100, 116, 139, 0.25)",
        "0px 25px 50px rgba(100, 116, 139, 0.25)",
        "0px 25px 50px rgba(100, 116, 139, 0.25)"
    ],
    typography: {
        button: {
            fontWeight: 600
        },
        fontFamily:
            '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
        body1: {
            fontSize: "1rem",
            fontWeight: 400,
            lineHeight: 1.5
        },
        body2: {
            fontSize: "0.875rem",
            fontWeight: 400,
            lineHeight: 1.1
        },
        subtitle1: {
            fontSize: "1rem",
            fontWeight: 500,
            lineHeight: 1.75
        },
        subtitle2: {
            fontSize: "0.875rem",
            fontWeight: 500,
            lineHeight: 1.57
        },
        overline: {
            fontSize: "0.75rem",
            fontWeight: 600,
            letterSpacing: "0.5px",
            lineHeight: 2.5,
            textTransform: "uppercase"
        },
        caption: {
            fontSize: "0.75rem",
            fontWeight: 400,
            lineHeight: 1.66
        },
        h1: {
            fontWeight: 700,
            fontSize: "3.5rem",
            lineHeight: 1.375
        },
        h2: {
            fontWeight: 700,
            fontSize: "3rem",
            lineHeight: 1.375
        },
        h3: {
            fontWeight: 700,
            fontSize: "2.25rem",
            lineHeight: 1.375
        },
        h4: {
            fontWeight: 700,
            fontSize: "2rem",
            lineHeight: 1.375
        },
        h5: {
            fontWeight: 600,
            fontSize: "1.5rem",
            lineHeight: 1.375
        },
        h6: {
            fontWeight: 600,
            fontSize: "1rem",
            lineHeight: 1
        }
    }
})
