import { Paper, Box } from "@mui/material"
import { FromUserType } from "../types"
import { TitleBox } from "./GetTitleBox"
import { EmailField, SimpleShowLayout, TextField } from "react-admin"

type FromUserPropType = {
    user: FromUserType
}
export const FromUserShow = (props: FromUserPropType) => {
    const { user } = props
    return (
        <Paper elevation={20}>
            <Box margin={"5px 0px"}>
                <TitleBox title="Müşteri" color="#F1C40F" />
            </Box>

            <SimpleShowLayout>
                <TextField source="fromUser.name" label="Ünvan" />
                <EmailField source="fromUser.mail" label="Email" />
                <TextField
                    source="fromUser.address.fullAddress"
                    label="Açık Adres"
                />
                <TextField source="fromUser.address.city" label="Şehir" />
                <TextField source="fromUser.address.country" label="Ülke" />

                {user?.billingInfo?.identityNumber && (
                    <TextField
                        source="fromUser.billingInfo.identityNumber"
                        label="Kimlik No"
                    />
                )}
                {user?.billingInfo?.taxNumber && (
                    <TextField
                        source="fromUser.billingInfo.taxNumber"
                        label={
                            user?.billingInfo?.taxNumber.length == 11
                                ? "Kimlik No"
                                : "Vergi No"
                        }
                    />
                )}
                {user?.billingInfo?.taxOffice && (
                    <TextField
                        source="fromUser.billingInfo.taxOffice"
                        label="Vergi Dairesi"
                    />
                )}
            </SimpleShowLayout>
        </Paper>
    )
}
