import React from "react"
import { useRecordContext } from "react-admin"
import Chip from '@mui/material/Chip';
import { ReleaseType, StateType } from "Types/ReleaseTypes";
import { ProjectStatus } from "Types/ProjectStatusTypes";

type PropType = {
    label?: string
}

type TypeInfo = {
    label: string,
    color: "default" | "success" | "warning" | "error" | "primary" | "secondary" | "info" | undefined,
    value: number
}

const TypeList : TypeInfo[] = [
    { label: "Yayında", color: "primary", value : 0 },
    { label: "Plan Aşaması", color: "info", value : 1 },
    { label: "Onay Bekleyen", color: "warning", value : 2 },
    { label: "Devam Eden", color: "info", value : 3 },
    { label: "Taslak", color: "default", value : 4 },
    { label: "Tamamlanan", color: "success", value : 5 },
    { label: "Tamamlanmayan", color: "error", value : 6 },
    { label: "Geçmiş", color: "warning", value : 7 },
    { label: "Reddedilen", color: "error", value : 8 },
]
  export const  ProjectStatusType = () => {
    const record = useRecordContext()
    if(record.releaseType == ReleaseType.published && record.stateType != StateType.locked && record.applyEndDate < Date.UTC && record.approvedOfferId === null)
    {
       return ProjectStatus.PUBLISHED;
    }
    else if(record.approvedOfferId != null && record.acceptedOfferId === null)
    {
       return ProjectStatus.ON_PLANNING;
    }
    else if(record.releaseType == ReleaseType.inSubmission)
    {
       return ProjectStatus.ON_APPROVE;
    }
    else if(record.stateType == StateType.locked && record.stateType != StateType.finished)
    {
       return ProjectStatus.ON_DOING;
    }
    else if(record.releaseType == ReleaseType.draft)
    {
       return ProjectStatus.DRAFT;
    }   
    else if(record.stateType == StateType.finished)
    {
       return ProjectStatus.COMPLETED;
    }  
    else if(record.stateType == StateType.cancelled)
    {
       return ProjectStatus.UNCOMPLETED;
    }  
    else if(record.applyEndDate > Date.UTC && record.releaseType != ReleaseType.rejected && record.releaseType != ReleaseType.draft && record.releaseType != ReleaseType.inSubmission)
    {
       return ProjectStatus.HISTORIC;
    }  
    else if(record.releaseType == ReleaseType.rejected)
    {
       return ProjectStatus.REJECTED;
    }  
    else {
        return ProjectStatus.HISTORIC;
    }
}
    
const ProjectReleaseTypeField = (props: PropType) => {
   
    const projectStatusType : ProjectStatus = ProjectStatusType()
    const targetType = TypeList.find((s: TypeInfo) => s.value === projectStatusType);
    
    return (
        <>
            <Chip label={targetType?.label} color={targetType?.color}  size="small" />
        </>
    )
}

export default ProjectReleaseTypeField
