import { MilestoneStatus } from "Types/MilestoneTypes"
import {Chip} from "@mui/material"

type PropType = {
    label?: string
    planStatus: number
}

type TypeInfo = {
    label: string,
    color: "default" | "success" | "warning" | "error" | "primary" | "secondary" | "info" | undefined,
    value: number
}

const TypeList : TypeInfo[] = [
    { label: "Kilitli", color: "primary", value : 0 },
    { label: "Devam Ediyor", color: "default", value : 1 },
    { label: "Ödeme Talebi", color: "info", value : 2 },
    { label: "Ödeme Başlatıldı", color: "secondary", value : 3 },
    { label: "Ödeme Yapılmadı", color: "warning", value : 4 },
    { label: "Ödendi", color: "success", value : 5 },
    { label: "Ödendi", color: "success", value : 6 },
    { label: "Yardım Talebi", color: "error", value : 7 },

]

const MilestoneStatusTypeField = (props: PropType) => {
    const {planStatus} = props
    const milestonetStatus : MilestoneStatus = planStatus
    const targetType = TypeList.find((s: TypeInfo) => s.value === milestonetStatus);
    
    return (
        <>
              <Chip label={targetType?.label} color={targetType?.color}  size="small"/>
        </>
    )
}

export default MilestoneStatusTypeField
