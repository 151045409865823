import { httpClient } from "Helpers"
import { GenerateUrlByResource } from "Providers/Helpers"
import { useEffect, useState } from "react"
import {
    RecordContextProvider,
    ReferenceField,
    TextField,
    useRecordContext
} from "react-admin"
import { Box, CircularProgress, Typography, Alert, Paper, Link as MLink } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import Avatar from "CustomFields/Avatar"
import ComplaintTypeField from "CustomFields/ComplaintTypeField"

export const RenderComplaints = () => {
    const record = useRecordContext()
    const [complaints, setComplaints] = useState([])
    const baseUrl = GenerateUrlByResource("complaint")
    const [isLoadComplaints, setIsLoadComplaints] = useState(false)

    useEffect(() => {
        const fetchComplaints = async () => {
            try {
                httpClient(`${baseUrl}/complemented-user/${record.id}`, {
                    method: "GET"
                })
                    .then((response) => JSON.parse(response.body))
                    .then((data) => {
                        setComplaints(data.data)
                        setIsLoadComplaints(true)
                    })
            } catch {
                setIsLoadComplaints(false)
            }
        }
        fetchComplaints()
    }, [])
    if (!isLoadComplaints)
        return (
            <Box
                sx={{
                    height: 250,
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center"
                }}>
                <CircularProgress />
            </Box>
        )
    else if (complaints.length == 0) {
        return <Alert>Bu profil hakkında şikayet yapılmamıştır.</Alert>
    }
    return (
        <Paper elevation={20}>
            <Box sx={{ m: 2 }}>
                <Typography variant="h6">Şikayetler</Typography>
            </Box>
            <DataGrid
                getRowId={(row: any) => row.id}
                rows={complaints || []}
                columns={[
                    {
                        field: "avatar",
                        headerName: "",
                        sortable: false,
                        width: 35,
                        align: "center",
                        renderCell: (data) => {
                            return (
                                <RecordContextProvider value={data.row}>
                                    <ReferenceField
                                        source="complementerUserId"
                                        reference="user">
                                        <Box
                                            display="flex"
                                            justifyContent={"center"}>
                                            <Avatar
                                                source="imagePath"
                                                title="Profil Resmi"
                                            />
                                        </Box>
                                    </ReferenceField>
                                </RecordContextProvider>
                            )
                        }
                    },
                    {
                        field: "complaint-name",
                        headerName: "Şikayetçi İsmi",
                        sortable: false,
                        flex: 1,
                        renderCell: (data) => {
                            return (
                                <RecordContextProvider value={data.row}>
                                    <ReferenceField
                                        source="complementerUserId"
                                        reference="user"
                                        link="show">
                                        <>
                                            <Box
                                                display="flex"
                                                style={{ gap: "6px" }}>
                                                <TextField source="name" />
                                            </Box>
                                        </>
                                    </ReferenceField>
                                </RecordContextProvider>
                            )
                        }
                    },
                    {
                        field: "complaint-type",
                        headerName: "Şikayet Tipi",
                        sortable: false,
                        flex: 1,
                        renderCell: (data) => {
                            return (
                                <RecordContextProvider value={data.row}>
                                    <Box>
                                        <ComplaintTypeField source="complaintType" />
                                    </Box>
                                </RecordContextProvider>
                            )
                        }
                    },
                    {
                        field: "description",
                        headerName: "Açıklama",
                        sortable: false,
                        flex: 2
                    },
                    {
                        field: "description-button",
                        headerName: "Detay",
                        sortable: false,
                        renderCell: (data) => {
                            return (
                                <MLink href={`#/complaint/${data.row.id}/show`}>
                                    Detay
                                </MLink>
                            )
                        }
                    }
                ]}
            />
        </Paper>
    )
}
