import ContentTypeField from "CustomFields/ContentTypeField"
import StatusField from "CustomFields/StatusField"
import { List, Datagrid, TextField, EditButton, Pagination } from "react-admin"

const BlogCategoryList = () => {
    return (
        <List pagination={<></>}>
            <>
                <Datagrid>
                    <TextField source="name" label="İsim" />
                    <TextField source="description" label="Açıklama" />
                    <ContentTypeField
                        source="blogType"
                        label="İçerik Sayfası"
                    />
                    <StatusField source="status" />
                    <EditButton label="Düzenle" />
                </Datagrid>
                <Pagination rowsPerPageOptions={[5, 10, 25]} />
            </>
        </List>
    )
}

export default BlogCategoryList
