import { Box, Typography, Divider, CircularProgress } from "@mui/material"
import { PeopleAlt, Inventory } from "@mui/icons-material"
import { DashboardProjectViewType, DashboardUserViewType } from "../types"
import { InfoBox } from "./InfoBox"
export const ProjectInfoBoxList = (props: ProjectInfoBoxListPropType) => {
    const { projectCount } = props
    return (
        <Box style={InfoBoxList.infoBoxsList}>
            <Box style={InfoBoxList.infoBoxsListTitle}>
                <Typography
                    variant="h5"
                    style={InfoBoxList.infoBoxsListTitleText}>
                    Projeler
                </Typography>
                <Inventory />
            </Box>
            <Divider />
            <InfoBox
                borderColor="#DFFF00"
                title="Proje"
                chipList={[
                    {
                        chipTitle: "Taslak",
                        chipCount: projectCount.draftProjectCount,
                        link: `/project?filter=%7B"releaseType"%3A0%7D&order=DESC&page=1&perPage=10&sort=createDate`
                    },
                    {
                        chipTitle: "Onay Bekleyen",
                        chipCount: projectCount.pendingApprovalProjectCount,
                        link: `/project?filter=%7B"releaseType"%3A2%7D&order=DESC&page=1&perPage=10&sort=createDate`
                    },
                    {
                        chipTitle: "Yayında",
                        chipCount: projectCount.publishProjectCount,
                        link: `/project?filter=%7B%7D&order=DESC&page=1&perPage=10&sort=createDate`
                    },
                    {
                        chipTitle: "Tamamlanan",
                        chipCount: projectCount.completedProjectCount,
                        link: `/project?filter=%7B"stateType"%3A4%7D&order=DESC&page=1&perPage=10&sort=createDate`
                    }
                ]}
            />
        </Box>
    )
}

type UserInfoBoxListPropType = {
    userCount: DashboardUserViewType
}
type ProjectInfoBoxListPropType = {
    projectCount: DashboardProjectViewType
}
export const UserInfoBoxList = (props: UserInfoBoxListPropType) => {
    const { userCount } = props
    return (
        <Box style={InfoBoxList.infoBoxsList}>
            <Box style={InfoBoxList.infoBoxsListTitle}>
                <Typography
                    variant="h5"
                    style={InfoBoxList.infoBoxsListTitleText}>
                    Profiller
                </Typography>
                <PeopleAlt />
            </Box>
            <Divider />
            <InfoBox
                title="Freelancer"
                borderColor="#DE3163"
                chipList={[
                    {
                        chipTitle: "Taslak",
                        chipCount: userCount.draftFreelancerCount,
                        link: `/user?filter=%7B"userType"%3A0%2C"releaseType"%3A0%7D&order=DESC&page=1&perPage=10&sort=createDate`
                    },
                    {
                        chipTitle: "Onay Bekleyen",
                        chipCount: userCount.pendingApprovalFreelancerCount,
                        link: `/user?filter=%7B"userType"%3A0%2C"releaseType"%3A2%7D&order=DESC&page=1&perPage=10&sort=createDate`
                    },
                    {
                        chipTitle: "Onaylı",
                        chipCount: userCount.approvedFreelancerCount,
                        link: `/user?filter=%7B"userType"%3A0%2C"releaseType"%3A1%7D&order=DESC&page=1&perPage=10&sort=createDate`
                    },
                    {
                        chipTitle: "Toplam",
                        chipCount: userCount.totalFreelancerCount,
                        link: `/user?filter=%7B"userType"%3A0%7D&order=DESC&page=1&perPage=10&sort=createDate`
                    }
                ]}
            />
            <InfoBox
                isIndividualEmployer={true}
                borderColor="#40E0D0"
                title="Bireysel İşveren"
                chipList={[
                    {
                        chipTitle: "Taslak",
                        chipCount: userCount.draftIndividualEmployerCount,
                        link: `/user?filter=%7B"userType"%3A1%2C"releaseType"%3A0%2C"groupType"%3A0%7D&order=DESC&page=1&perPage=10&sort=createDate`
                    },
                    {
                        chipTitle: "Onay Bekleyen",
                        chipCount: userCount.pendingApprovalIndividualEmployerCount,
                        link: `/user?filter=%7B"userType"%3A1%2C"releaseType"%3A2%2C"groupType"%3A0%7D&order=DESC&page=1&perPage=10&sort=createDate`
                    },
                    {
                        chipTitle: "Onaylı",
                        chipCount: userCount.approvedIndividualEmployerCount,
                        link: `/user?filter=%7B"userType"%3A1%2C"releaseType"%3A1%2C"groupType"%3A0%7D&order=DESC&page=1&perPage=10&sort=createDate`
                    },
                    {
                        chipTitle: "Toplam",
                        chipCount: userCount.totalIndividualEmployerCount,
                        link: `/user?filter=%7B"userType"%3A1%2C"groupType"%3A0%7D&order=DESC&page=1&perPage=10&sort=createDate`
                    }
                ]}
            />
            <InfoBox
                title="İşveren"
                borderColor="#6495ED"
                chipList={[
                    {
                        chipTitle: "Taslak",
                        chipCount: userCount.draftCorporateEmployerCount,
                        link: `/user?filter=%7B"userType"%3A1%2C"groupType"%3A1%2C"releaseType"%3A0%7D&order=DESC&page=1&perPage=10&sort=createDate`
                    },
                    {
                        chipTitle: "Onay Bekleyen",
                        chipCount: userCount.pendingApprovalCorporateEmployerCount,
                        link: `user?filter=%7B"userType"%3A1%2C"groupType"%3A1%2C"releaseType"%3A2%7D&order=DESC&page=1&perPage=10&sort=createDate`
                    },
                    {
                        chipTitle: "Onaylı",
                        chipCount: userCount.approvedCorporateEmployerCount,
                        link: `/user?filter=%7B"userType"%3A1%2C"groupType"%3A1%2C"releaseType"%3A1%7D&order=DESC&page=1&perPage=10&sort=createDate`
                    },
                    {
                        chipTitle: "Toplam",
                        chipCount: userCount.totalCorporateEmployerCount,
                        link: `/user?filter=%7B"userType"%3A1%2C"groupType"%3A1%7D&order=DESC&page=1&perPage=10&sort=createDate`
                    }
                ]}
            />
        </Box>
    )
}

class InfoBoxList {
    static infoBoxsList: React.CSSProperties = {
        flex: 1,
        margin: "0px 10px",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0px 0px 15px -10px rgba(0, 0, 0, 0.75)"
    }
    static infoBoxsListTitle: React.CSSProperties = {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "1px"
    }
    static infoBoxsListTitleText: React.CSSProperties = { fontWeight: "300" }
}
