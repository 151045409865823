export enum ReleaseType
        {
            draft = 0,
            published = 1,
            inSubmission = 2,
            rejected = 3,
            revision = 4,
            isContinuing = 5,
            disabled = 6
        }

  export enum StateType
        {
            hold = 0,
            free = 1,
            locked = 2,
            closed = 3,
            finished = 4,
            cancelled = 5
        }

