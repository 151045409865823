import { UserAccountEnum, UserGroupType } from "Types/UserTypes"
import { Button, Link } from "react-admin"
import { Badge } from "@mui/icons-material"

export const AccountShowButton = ({ record }: { record: any }) => {
    if (!record) return null
    if (
        record.userType === UserAccountEnum.Employee &&
        record.groupType === UserGroupType.Company
    )
        return null

    return (
        <Link
            to={`/account/${record.accountId}/show`}
            style={{ margin: "5px", display: "flex", justifyContent: "center" }}
            children={
                <Button
                    style={{ width: "95%" }}
                    startIcon={<Badge />}
                    label="Hesaba Git"
                    variant="outlined"
                />
            }
        />
    )
}
