import BackToListField from "CustomFields/BackToListField"
import { Card, CardContent, Paper, Grid } from "@mui/material"
import {
    ArrayField,
    DateField,
    DeleteWithConfirmButton,
    EditButton,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField
} from "react-admin"
import StatusField from "CustomFields/StatusField"
import VisibilityStatusField from "CustomFields/VisibilityStatusField"
import BadgeUserTypeField from "CustomFields/BadgeUserTypeField"

const Content = () => {
    return (
        <Paper elevation={20}>
            <SimpleShowLayout>
                <TextField source="name" label="İsim" />
                <TextField source="description" label="Açıklama" />
                <ReferenceField
                    label="Komisyon"
                    source="commissionRateId"
                    reference="commission-rate"
                    link="show">
                    <TextField source="name" label="Komisyon Adı" />
                </ReferenceField>
                <ArrayField source="userType" label="Uygulanan Profiller">
                    <BadgeUserTypeField source="userType" />
                </ArrayField>
                <TextField source="note" label="Rozetin Amacı" />
                <VisibilityStatusField
                    source="visibility"
                    label="Görünürlük Durumu"
                />
                <StatusField source="status" label="Durum" />
                <DateField source="createDate" label="Oluşturma Tarihi" />
                <Card sx={{ display: "flex", "flex-direction": "row" }}>
                    <CardContent>
                        <EditButton />
                    </CardContent>
                    <CardContent>
                        <DeleteWithConfirmButton confirmTitle="Emin misiniz?" />
                    </CardContent>
                </Card>
            </SimpleShowLayout>
        </Paper>
    )
}

export const BadgeView = () => {
    return (
        <Show title={"Badge Detayı"} actions={<></>}>
            <Grid spacing={1} sx={{ mt: 5, mb: 6, px: 2 }}>
                <Grid xs={1}>
                    <BackToListField source="badge" />
                </Grid>
                <Grid xs={7}>
                    <Content />
                </Grid>
            </Grid>
        </Show>
    )
}
