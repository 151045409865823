import { httpClient } from "Helpers"
import { MessageOutlined, MarkChatUnreadOutlined } from "@mui/icons-material"
import { GenerateUrlByResource } from "Providers/Helpers"
import { useEffect, useState } from "react"
import {
    Button,
    DateField,
    RecordContextProvider,
    ReferenceField,
    TextField
} from "react-admin"
import {
    Box,
    CircularProgress,
    Typography,
    Tooltip,
    Modal
} from "@mui/material"
import Avatar from "CustomFields/Avatar"
import { MessageBoxStyle } from "Pages/Project/ViewPartial/WorkProcess"

export const Chat = ({ record }: { record: any }) => {
    const isReadMessage = (
        messageReadDate: Date | undefined,
        messageSendDate: Date | undefined,
        lastMessageWriteDate: Date
    ) => {
        if (
            (messageReadDate != null &&
                messageSendDate != null &&
                messageReadDate < messageSendDate &&
                messageReadDate < lastMessageWriteDate) ||
            (messageReadDate === null &&
                messageSendDate != null &&
                messageSendDate > lastMessageWriteDate)
        ) {
            return (
                <Tooltip title="Okunmamış">
                    <MarkChatUnreadOutlined />
                </Tooltip>
            )
        }
    }
    const [open, setOpen] = useState(false)
    const [roomId, setRoomId] = useState("")
    const [queryType, setQueryType] = useState({
        type: 0,
        roomId: ""
    })
    const [loadChats, setLoadChats] = useState(false)
    const [loadMessages, setLoadMessages] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => {
        setOpen(false),
            setLoadChats(false),
            setQueryType({ type: 0, roomId: "" })
    }
    const baseUrlChat = GenerateUrlByResource("chat")
    const [chats, setChats] = useState([
        {
            roomId: "",
            targetUserId: "",
            targetUserLastMessageReadDate: null,
            targetUserLastMessageWriteDate: null,
            lastMessageWriteDate: null
        }
    ])
    const [messages, setMessages] = useState([{}])
    const fetchChat = async () => {
        if (queryType.type == 0 && open && !loadChats) {
            try {
                httpClient(`${baseUrlChat}/${record.id}/chatRooms`, {
                    method: "GET"
                })
                    .then((response) => JSON.parse(response.body))
                    .then((data) => {
                        setChats(data.data)
                        setLoadChats(true)
                    })
            } catch (e) {
                setLoadChats(false)
            }
        } else if (
            queryType.type == 1 &&
            ((queryType.roomId != "" && !loadMessages) ||
                roomId != queryType.roomId)
        ) {
            try {
                httpClient(`${baseUrlChat}/${queryType.roomId}/chatRoom`, {
                    method: "GET"
                })
                    .then((response) => JSON.parse(response.body))
                    .then((data) => {
                        setMessages(data.data)
                        setLoadMessages(true)
                        setRoomId(queryType.roomId)
                    })
            } catch (e) {
                setLoadMessages(false)
            }
        }
    }
    useEffect(() => {
        fetchChat()
    }, [open, chats, messages, queryType])

    return (
        <>
            <Button
                startIcon={<MessageOutlined />}
                label="Sohbetler"
                variant="outlined"
                sx={{ m: 1 }}
                onClick={handleOpen}
            />
            <Modal open={open} onClose={handleClose}>
                <Box
                    sx={{
                        position: "absolute" as "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 900,
                        height: 500,
                        backgroundColor: "background.paper",
                        boxShadow: "24px",
                        display: "flex",
                        flexDirection: "row"
                    }}>
                    {!loadChats ? (
                        <Box
                            sx={{
                                position: "absolute",
                                left: "50%",
                                top: "50%",
                                transform: "translate(-50%, -50%)"
                            }}>
                            <CircularProgress />
                        </Box>
                    ) : chats.length < 1 ? (
                        <Box
                            sx={{
                                pl: "35%",
                                pt: 1
                            }}>
                            <Typography
                                variant="caption"
                                style={{ fontSize: 15, fontWeight: 300 }}>
                                Konuşma geçmişi bulunmamaktadır.
                            </Typography>
                        </Box>
                    ) : (
                        <>
                            <Box
                                sx={{
                                    width: "35%",
                                    flexDirection: "column",
                                    display: "flex",
                                    overflowY: "visible",
                                    overflowX: "hidden"
                                }}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        pt: 3,
                                        height: "40px",
                                        borderBottomStyle: "solid",
                                        borderBottomColor: "#85929E"
                                    }}>
                                    <Typography variant="body1">
                                        Sohbetler
                                    </Typography>
                                </Box>
                                <Box>
                                    {Array.from(chats).map(
                                        (chat: any, index: number) => {
                                            return (
                                                <>
                                                    <Button
                                                        style={{
                                                            width: "100%",
                                                            flexDirection: "row"
                                                        }}
                                                        children={
                                                            <RecordContextProvider
                                                                value={chat}>
                                                                <ReferenceField
                                                                    source="targetUserId"
                                                                    reference="user">
                                                                    <Box
                                                                        style={{
                                                                            width: "55px",
                                                                            display:
                                                                                "flex",
                                                                            justifyContent:
                                                                                "start"
                                                                        }}>
                                                                        <Avatar
                                                                            source="imagePath"
                                                                            title="Profil Resmi"
                                                                            size={
                                                                                32
                                                                            }
                                                                            sx={
                                                                                MessageBoxStyle.avatar
                                                                            }
                                                                        />
                                                                    </Box>
                                                                    <Box
                                                                        style={{
                                                                            width: "200px",
                                                                            display:
                                                                                "flex",
                                                                            justifyContent:
                                                                                "start",
                                                                            textAlign:
                                                                                "center"
                                                                        }}>
                                                                        <TextField
                                                                            source="name"
                                                                            style={
                                                                                MessageBoxStyle.userName
                                                                            }></TextField>
                                                                    </Box>
                                                                </ReferenceField>
                                                                <Box
                                                                    sx={{
                                                                        width: "25px",
                                                                        display:
                                                                            "flex",
                                                                        alignItems:
                                                                            "center"
                                                                    }}>
                                                                    {isReadMessage(
                                                                        chat.fromUserLastMessageReadDate,
                                                                        chat.targetUserLastMessageReadDate,
                                                                        chat.lastMessageWriteDate
                                                                    )}
                                                                </Box>
                                                            </RecordContextProvider>
                                                        }
                                                        onClick={() =>
                                                            setQueryType({
                                                                type: 1,
                                                                roomId: chats[
                                                                    index
                                                                ].roomId
                                                            })
                                                        }
                                                    />
                                                </>
                                            )
                                        }
                                    )}
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    width: "65%",
                                    backgroundColor: "#ECF0F1",
                                    overflowY: "visible",
                                    overflowX: "hidden"
                                }}>
                                {!loadMessages && loadChats ? (
                                    <></>
                                ) : (
                                    <Box sx={{ mt: 2 }}>
                                        {Array.from(messages).map(
                                            (message: any) => {
                                                let fromType =
                                                    record.id ==
                                                    message.fromUserId
                                                        ? 0
                                                        : 1
                                                return (
                                                    <MessageBox
                                                        handleClose={
                                                            handleClose
                                                        }
                                                        chat={message}
                                                        fromType={fromType}
                                                    />
                                                )
                                            }
                                        )}
                                    </Box>
                                )}
                            </Box>
                        </>
                    )}
                </Box>
            </Modal>
        </>
    )
}
const MessageBox = ({
    fromType,
    chat,
    handleClose
}: {
    fromType: number
    handleClose: Function
    chat: {
        message: string
        fromUserId: string
        createDate: Date
    }
}) => {
    return (
        <>
            <Box sx={MessageBoxStyle.messageBox(fromType)}>
                <Box sx={MessageBoxStyle.messageBoxStartDirection(fromType)}>
                    <RecordContextProvider value={chat}>
                        <ReferenceField
                            source="fromUserId"
                            reference="user"
                            link="show">
                            <div onClick={() => handleClose()}>
                                <Avatar
                                    source="imagePath"
                                    title="Profil Resmi"
                                    size={32}
                                    sx={MessageBoxStyle.avatar}
                                />
                            </div>
                        </ReferenceField>
                        <Box>
                            <Box
                                style={MessageBoxStyle.messageBoxContent(
                                    fromType
                                )}>
                                <ReferenceField
                                    source="fromUserId"
                                    reference="user"
                                    link="show">
                                    <div onClick={() => handleClose()}>
                                        <TextField
                                            source="name"
                                            style={MessageBoxStyle.userName}
                                        />
                                    </div>
                                </ReferenceField>
                                <Typography
                                    variant="body2"
                                    style={MessageBoxStyle.message}>
                                    {chat.message}
                                </Typography>
                            </Box>

                            <Box>
                                <DateField
                                    style={MessageBoxStyle.dateField}
                                    source="createDate"
                                    locales="TR"
                                    options={{
                                        day: "numeric",
                                        month: "numeric",
                                        year: "numeric",
                                        hour: "numeric",
                                        minute: "numeric"
                                    }}
                                    showTime
                                />
                            </Box>
                        </Box>
                    </RecordContextProvider>
                </Box>
            </Box>
        </>
    )
}
//#endregion
