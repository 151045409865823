import {
    Card,
    CardContent,
    Typography,
    Box,
    TextField,
    Button
} from "@mui/material"
import { GenerateUrlByResource } from "Providers/Helpers"
import { useNotify, useRedirect } from "react-admin"
import { useEffect, useState } from "react"
import { httpClient } from "Helpers"

const ForgotPassword = () => {
    const [emailValid, setEmailValid] = useState(true)
    const [email, setEmailValue] = useState("")
    const [isProcess, setIsProcess] = useState(false)

    const redirect = useRedirect()
    const notify = useNotify()
    const baseUrl = GenerateUrlByResource("manager")

    const handleEmailValidation = (e: string) => {
        setEmailValue(e)
        if (e != "") {
            const reg = new RegExp(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
            setEmailValid(reg.test(e.toLowerCase()))
        }
    }
    const handleForgotPasswordSubmit = () => {
        setIsProcess(true)
        if (!emailValid || email.length == 0) {
            notify("E-posta adresinizi geçerli formatta giriniz.", {
                type: "warning"
            })
            setIsProcess(false)
        } else {
            httpClient(`${baseUrl}/forgot-password`, {
                method: "POST",
                body: JSON.stringify({ email: email })
            })
                .then((response) => JSON.parse(response.body))
                .then((response) => {
                    const { success } = response
                    if (success) {
                        notify("Mail başarıyla gönderildi.", {
                            type: "success"
                        })
                        redirect(`/forgot-password?email=${email}`)
                    }
                })
                .catch((e) => {
                    if ((e.action = "invalid_credentials")) {
                        notify(
                            "Mail başarıyla gönderildi.",
                            {
                                type: "success"
                            }
                        )
                        redirect(`/forgot-password?email=${email}`)
                    } else {
                        notify("Bir hata oluştu.", { type: "warning" })
                    }
                })
                .finally(() => {
                    setIsProcess(false)
                })
        }
    }

    return (
        <Card
            sx={{
                backgroundColor: "#00023b",
                borderRadius: 0,
                display: "flex",
                justifyContent: "center",
                height: "100vh",
                width: "100%"
            }}>
            <CardContent
                sx={{
                    borderRadius: 2,
                    backgroundColor: "#fafafa",
                    width: 300,
                    mt: "6em",
                    flexDirection: "column",
                    display: "flex",
                    height: "30%"
                }}>
                <Box sx={{ mt: 3, ml: 3 }}>
                    <Typography variant="h4" fontWeight={600} fontSize={22}>
                        Şifre Sıfırla
                    </Typography>

                </Box>
                <Box sx={{ mt: 1, ml: 3 }}>
                    <Typography variant="caption"  sx={{opacity:0.6}} >
                        Size aktivasyon kodu yollayabilmemiz için email adresini giriniz.
                    </Typography>
                </Box>
                <Box sx={{ mt: 2, ml: 3, mr: 3 }}>
                    <TextField
                        name="email"
                        required
                        onChange={(e) => handleEmailValidation(e.target.value)}
                        value={email}
                        error={!emailValid}
                        placeholder="E-posta"
                        fullWidth
                    />
                </Box>
                <Button
                    variant="contained"
                    disabled={isProcess}
                    sx={{ mt: 3, ml: 3, width: 100, height: 40 }}
                    onClick={() => handleForgotPasswordSubmit()}>
                    Gönder
                </Button>
            </CardContent>
        </Card>
    )
}

export default ForgotPassword
