
export enum DeliveryType
{
    nothing = 0,
    makePayment = 1,
    paymentRequest = 2,
    paymentRequestCancelled = 3,
    paymentRequestCompleted = 4, 
    complain = 5,
    complainResponse = 6,
    complainCancelled = 7
}
