import { httpClient } from "Helpers"
import { GenerateUrlByResource } from "Providers/Helpers"
import { useEffect, useState } from "react"
import {
    Button,
    FunctionField,
    RecordContextProvider,
    ReferenceField,
    TextField,
    useNotify,
    useRecordContext
} from "react-admin"
import {
    Box,
    Typography,
    Link as MLink,
    Grid,
    Paper,
    Alert,
    Card,
    CardContent,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from "@mui/material"
import { DataGrid as MDataGrid } from "@mui/x-data-grid"
import { ReleaseType, StateType } from "Types/ReleaseTypes"
import Avatar from "CustomFields/Avatar"
import OfferOveralTypeField from "CustomFields/OfferOveralTypeField"
import OfferGroupTypeField from "CustomFields/OfferGroupTypeField"
import { OfferView } from "Pages/Offer/View"
import OfferContent from "Pages/Offer/ViewPartial/OfferContent"
import PriceField from "CustomFields/PriceField"
import { List } from "lodash"
import {
    ProjectStatusType
} from "CustomFields/ProjectReleaseTypeField"
import { ProjectStatus } from "Types/ProjectStatusTypes"
import { OfferViewType } from "../types"


const Content = (offerViewType: OfferViewType) => {
    return (
        <Paper elevation={20}>
            <Typography variant="h6" sx={{ m: 3 }}>
                {offerViewType.title}
            </Typography>
            <MDataGrid
                getRowId={(row: any) => row.id}
                rows={offerViewType.offers || []}
                columns={[
                    {
                        field: "user-avatar",
                        headerName: "",
                        sortable: false,
                        flex: 0,
                        renderCell: (data) => {
                            return (
                                <RecordContextProvider value={data.row}>
                                    <ReferenceField
                                        source="userId"
                                        reference="user">
                                        <Avatar
                                            source="imagePath"
                                            title="Profil Resmi"
                                            size={32}
                                            sx={{
                                                width: 32,
                                                height: 32,
                                                mx: "auto"
                                            }}></Avatar>
                                    </ReferenceField>
                                </RecordContextProvider>
                            )
                        }
                    },
                    {
                        field: "name",
                        headerName: "İsim",
                        sortable: false,
                        flex: 2,
                        renderCell: (data) => {
                            return (
                                <RecordContextProvider value={data.row}>
                                    <ReferenceField
                                        source="userId"
                                        reference="user">
                                        <TextField source="name" />
                                    </ReferenceField>
                                </RecordContextProvider>
                            )
                        }
                    },
                    {
                        field: "groupType",
                        headerName: "Teklif Tipi",
                        sortable: false,
                        flex: 1,
                        renderCell: (data) => {
                            return (
                                <RecordContextProvider value={data.row}>
                                    <OfferGroupTypeField source="groupType" />
                                </RecordContextProvider>
                            )
                        }
                    },
                    {
                        field: "overalStatus",
                        headerName: "Durum",
                        sortable: true,
                        flex: 1,
                        renderCell: (data) => {
                            return (
                                <RecordContextProvider value={data.row}>
                                    <OfferOveralTypeField source="overalStatus"></OfferOveralTypeField>
                                </RecordContextProvider>
                            )
                        }
                    },
                    {
                        field: "budgetPrice",
                        headerName: "Bütçe",
                        flex: 1,
                        sortable: true,
                        renderCell: (data) => {
                            return (
                                <RecordContextProvider value={data.row}>
                                    <PriceField source="budgetPrice" />
                                </RecordContextProvider>
                            )
                        }
                    },
                    {
                        field: "detail",
                        headerName: "Detay",
                        sortable: false,
                        renderCell: (data) => {
                            return (
                                <MLink
                                    href={`#/offer/${data.row.parentId}/show`}>
                                    Detay
                                </MLink>
                            )
                        }
                    }
                ]}
            />
        </Paper>
    )
}
const projectStatusByContent = (offers: any) => {
    let title: string = "Teklifler"
    const projectStatusType: ProjectStatus = ProjectStatusType()
    switch (projectStatusType) {
        case 0:
        case 1:
            if (offers.length == 0) {
                return (
                    <Alert severity="warning">
                        Projeye teklif gelmemiştir.
                    </Alert>
                )
            }
            title = "Gelen Teklifler"
            return <Content offers={offers} title={title} />
        case 3:
        case 5:
        case 6:
            title = "Onaylanan Teklif"
            return <Content offers={offers} title={title} />
        case 2:
        case 4:
        case 7:
        case 8:
            return (
                <Alert severity="warning">
                    Proje teklif aşamsında değildir.
                </Alert>
            )
    }
    return <Content offers={offers} title={title} />
}
const OfferListing = () => {
    const record = useRecordContext()
    const [offers, setOffers] = useState([])
    const [isLoadOffers, setIsLoadOffers] = useState(false)
    const baseUrl = GenerateUrlByResource("project")
    const notify = useNotify()
    if (!record) return null
    useEffect(() => {
        const fetchOffer = async () => {
            try {
                httpClient(`${baseUrl}/${record.id}/offers`, {
                    method: "GET"
                })
                    .then((response) => JSON.parse(response.body))
                    .then((data) => {
                        setOffers(data.data)
                        setIsLoadOffers(true)
                    })
            } catch {
                notify("Bir hata ile karşılaşıldı.")
                setIsLoadOffers(false)
            }
        }
        fetchOffer()
    }, [])

    if (!isLoadOffers) {
        return (
            <Box
                sx={{
                    height: 200,
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center"
                }}>
                <CircularProgress />
            </Box>
        )
    }
    return projectStatusByContent(offers)
}

export default OfferListing
