import {
    Card,
    CardContent,
    Typography,
    Box,
    TextField,
    IconButton,
    Tooltip,
    Button,
    InputAdornment
} from "@mui/material"
import { ArrowBack, Help } from "@mui/icons-material"
import { httpClient } from "Helpers"
import { GenerateUrlByResource } from "Providers/Helpers"
import { useEffect, useState } from "react"
import { useNotify, useRedirect } from "react-admin"
import ForgotPassword from "./ViewPartial/ForgotPassword"

const ForgotPasswordView = () => {
    const [isRecoverPasswordPage, setIsRecoverPasswordPage] = useState(false)
    const [codeValid, setCodeValid] = useState(true)
    const [code, setCodeValue] = useState("")
    const [passwordCode, setPasswordCodeValue] = useState("")
    const [passwordValid, setPasswordValid] = useState(true)
    const [password, setPasswordValue] = useState("")
    const redirect = useRedirect()
    const [isProcess, setIsProcess] = useState(false)
    const notify = useNotify()
    const baseUrl = GenerateUrlByResource("manager")
    const [isValidCodePage, setIsValidCodePage] = useState(false)
    useEffect(() => {
        setIsValidCodePage(location.href?.includes("#/forgot-password?email="))
    })

    //#region submit function and validation
    const handleCodeValidation = (e: string) => {
        setCodeValue(e)
        if (e != "") {
            const reg = new RegExp(/^[0-9]{6}$/)
            setCodeValid(reg.test(e))
        }
    }
    const handlePasswordValidation = (e: string) => {
        setPasswordValue(e)
        if (e != "") {
            const reg = new RegExp(
                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}$/
            )
            setPasswordValid(reg.test(e))
        }
    }
    const handleValidateActivationCodeSubmit = () => {
        const email = location.href?.split("?email=")[1]
        setIsProcess(true)
        if (!codeValid || code.length == 0) {
            notify("Girdiğiniz aktivasyon kodu kurallara uygun değil.", {
                type: "warning"
            })
            setIsProcess(false)
        } else if (email.length == 0) {
            notify(
                "Lütfen gelen maile linke tekrar tıklayarak, yeniden aktivasyon kodu almayı deneyiniz.",
                { type: "error" }
            )
            redirect("/login")
            setIsProcess(false)
        } else {
            httpClient(`${baseUrl}/validate-forgot-password-code`, {
                method: "POST",
                body: JSON.stringify({ email: email, code: code })
            })
                .then((response) => JSON.parse(response.body))
                .then((response) => {
                    const { success, data } = response
                    if (success) {
                        notify("Yeni şifrenizi girebilirsiniz.", {
                            type: "success"
                        })
                        setPasswordCodeValue(data.code)
                        setIsRecoverPasswordPage(true)
                    } else {
                        notify("Bir sorunla karşılaşıldı.", { type: "warning" })
                    }
                })
                .catch((e) => {
                    if ((e.action = "invalid_code")) {
                        notify("Geçersiz aktivasyon kodu girdiniz.", {
                            type: "error"
                        })
                    } else {
                        notify("Bir hata oluştu.", { type: "error" })
                    }
                })
                .finally(() => {
                    setIsProcess(false)
                })
        }
    }
    const handleRecoverPasswordSubmit = () => {
        setIsProcess(true)
        const email = location.href?.split("?email=")[1]
        if (!passwordValid || password.length == 0) {
            notify("Girdiğiniz şifre kurallara uygun değil.", {
                type: "warning"
            })
            setIsProcess(false)
        } else if (email.length == 0) {
            notify("Bir sorunla karşılaşıldı.", { type: "error" })
            setIsProcess(false)
            redirect("/login")
        } else {
            httpClient(`${baseUrl}/recover-password`, {
                method: "POST",
                body: JSON.stringify({
                    email: email,
                    code: passwordCode,
                    password: password
                })
            })
                .then((response) => JSON.parse(response.body))
                .then((response) => {
                    const { success } = response
                    if (success) {
                        notify("Yeni şifrenizle giriş yapabilirsiniz.", {
                            type: "success"
                        })
                        setIsRecoverPasswordPage(false)
                        redirect("/login")
                    } else {
                        notify("Bir sorunla karşılaşıldı.", { type: "warning" })
                    }
                })
                .catch(() => {
                    notify("Bir hata oluştu.", { type: "error" })
                    redirect("/login")
                })
                .finally(() => {
                    setIsProcess(false)
                })
        }
    }

    //#endregion

    if (isValidCodePage && !isRecoverPasswordPage) {
        return (
            <Card
                sx={{
                    backgroundColor: "#00023b",
                    borderRadius: 0,
                    display: "flex",
                    justifyContent: "center",
                    height: "100vh",
                    width: "100%"
                }}>
                <CardContent
                    sx={{
                        borderRadius: 2,
                        backgroundColor: "#fafafa",
                        width: 300,
                        height: 290,
                        mt: "6em",
                        flexDirection: "column",
                        display: "flex"
                    }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between"
                        }}>
                        <IconButton
                            color="primary"
                            onClick={() => redirect("/login")}>
                            <ArrowBack />
                        </IconButton>
                        <Tooltip title="Bu sayfaya mailinizdeki bağlantıdan ulaşabilirsiniz.">
                            <Help color="primary" sx={{ mt: 1, mr: 1 }} />
                        </Tooltip>
                    </Box>
                    <Box sx={{ mt: 2, ml: 3 }}>
                        <Typography variant="h4" fontWeight={600} fontSize={18}>
                            Aktivasyon Kod Doğrulama
                        </Typography>
                    </Box>
                    <Box sx={{ mt: 1, ml: 3 }}>
                        <Typography variant="caption" sx={{ opacity: 0.6 }}>
                            Mailinize gelen aktivasyon kodunu doğru şekilde
                            girerseniz yeni şifrenizi oluşturabilirsiniz.
                        </Typography>
                    </Box>
                    <Box sx={{ mt: 3, ml: 3, mr: 3 }}>
                        <TextField
                            name="code"
                            required
                            onChange={(e) =>
                                handleCodeValidation(e.target?.value)
                            }
                            error={!codeValid}
                            placeholder="Aktivasyon Kodu"
                            fullWidth
                        />
                    </Box>
                    <Button
                        variant="contained"
                        disabled={isProcess}
                        onClick={() => handleValidateActivationCodeSubmit()}
                        sx={{ mt: 4, ml: 3, width: 100, height: 40 }}>
                        Doğrula
                    </Button>
                </CardContent>
            </Card>
        )
    } else if (isRecoverPasswordPage) {
        return (
            <Card
                sx={{
                    backgroundColor: "#00023b",
                    borderRadius: 0,
                    display: "flex",
                    justifyContent: "center",
                    height: "100vh",
                    width: "100%"
                }}>
                <CardContent
                    sx={{
                        borderRadius: 2,
                        backgroundColor: "#fafafa",
                        width: 300,
                        height: 220,
                        mt: "6em",
                        flexDirection: "column",
                        display: "flex"
                    }}>
                    <Box sx={{ mt: 2, ml: 3 }}>
                        <Typography variant="h4" fontWeight={600} fontSize={22}>
                            Şifreni Değiştir
                        </Typography>
                    </Box>

                    <Box sx={{ mt: 3, ml: 3, mr: 3 }}>
                        <TextField
                            name="password"
                            required
                            type={"password"}
                            onChange={(e) =>
                                handlePasswordValidation(e.target?.value)
                            }
                            error={!passwordValid}
                            placeholder="Yeni Şifre"
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Tooltip title="Şifreniz en az 8 karakterden oluşmalıdır. En az rakam, büyük harf, küçük harf, ve özel karakter içermelidir. ">
                                            <IconButton>
                                                <Help
                                                    color={
                                                        !passwordValid
                                                            ? "error"
                                                            : "primary"
                                                    }
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                    <Button
                        disabled={isProcess}
                        variant="contained"
                        onClick={() => handleRecoverPasswordSubmit()}
                        sx={{ mt: 4, ml: 3, width: 150, height: 40 }}>
                        Şifre Değiştir
                    </Button>
                </CardContent>
            </Card>
        )
    } else {
        return <ForgotPassword />
    }
}

export default ForgotPasswordView
