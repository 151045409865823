import Avatar from "CustomFields/Avatar"
import {
    Box,
    Typography,
    Link as MLink,
    Grid,
    Paper,
    Alert,
    Card,
    CardContent,
    CircularProgress
} from "@mui/material"
import { DataGrid as MDataGrid } from "@mui/x-data-grid"
import {
    Show,
    TextField,
    DateField,
    EmailField,
    BooleanField,
    Datagrid,
    ShowButton,
    ReferenceManyField,
    TabbedShowLayout,
    Tab,
    Labeled,
    DeleteWithConfirmButton,
    RecordContextProvider,
    Link,
    Button,
    useRecordContext,
    ReferenceField
} from "react-admin"
import UserTypeField from "CustomFields/UserTypeField"
import UserReleaseTypeField from "CustomFields/UserReleaseTypeField"
import ColorfulBooleanField from "CustomFields/ColorfulBooleanField"
import StatusField from "CustomFields/StatusField"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { useEffect, useState } from "react"
import { GenerateUrlByResource } from "Providers/Helpers"
import { httpClient } from "Helpers"

const Aside = () => {
    return (
        <>
            <Link to="/account">
                <Button
                    label={"Listeye Dön"}
                    variant={"outlined"}
                    color={"primary"}
                    startIcon={<ArrowBackIcon />}
                    sx={{ mb: 2 }}
                />
            </Link>
            <Paper elevation={20}>
                <Card>
                    <CardContent>
                        <Box display="flex" flexDirection="column">
                            <Labeled label="İsim">
                                <TextField source="firstname" label="İsim" />
                            </Labeled>

                            <Labeled label="Soyisim">
                                <TextField source="lastname" label="Soyisim" />
                            </Labeled>

                            <Labeled label="E-mail">
                                <EmailField
                                    source="email"
                                    label="E-posta Adresi"
                                />
                            </Labeled>
                        </Box>
                    </CardContent>
                </Card>
            </Paper>

            <Paper elevation={20} sx={{ mt: 2 }}>
                <Card>
                    <CardContent>
                        <DeleteWithConfirmButton confirmTitle="Emin misiniz?" />
                    </CardContent>
                </Card>
            </Paper>
        </>
    )
}
const LinkendUsers = () => {
    const record = useRecordContext()
    const [users, setUsers] = useState([])
    const [isLoadUsers, setIsLoadUsers] = useState(false)
    const baseUrl = GenerateUrlByResource("account")
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                httpClient(`${baseUrl}/linkend-users/${record.id}`, {
                    method: "GET"
                })
                    .then((response) => JSON.parse(response.body))
                    .then((data) => {
                        setUsers(data.data)
                        setIsLoadUsers(true)
                    })
            } catch {
                setIsLoadUsers(false)
            }
        }
        fetchUsers()
    }, [])

    if (!isLoadUsers)
        return (
            <Box
                sx={{
                    height: 200,
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center"
                }}>
                <CircularProgress />
            </Box>
        )
    else if (users.length == 0)
        return <Alert severity="warning">Bağlı Profil Bulunamadı.</Alert>
    return (
        <Paper elevation={20}>
            <MDataGrid
                getRowId={(row: any) => row.userId}
                rows={users || []}
                columns={[
                    {
                        field: "",
                        headerName: "",
                        sortable: false,
                        renderCell: (data) => {
                            return (
                                <RecordContextProvider value={data.row}>
                                    <ReferenceField
                                        source="userId"
                                        reference="user">
                                        <Avatar
                                            source="imagePath"
                                            title="Profil Resmi"
                                            size={32}
                                            sx={{
                                                width: 32,
                                                height: 32,
                                                mx: "auto"
                                            }}></Avatar>
                                    </ReferenceField>
                                </RecordContextProvider>
                            )
                        }
                    },
                    {
                        field: "name",
                        headerName: "İsim",
                        sortable: false,
                        flex: 1,
                        renderCell: (data) => {
                            return (
                                <RecordContextProvider value={data.row}>
                                    <ReferenceField
                                        source="userId"
                                        reference="user">
                                        <TextField source="name" />
                                    </ReferenceField>
                                </RecordContextProvider>
                            )
                        }
                    },
                    {
                        field: "userType",
                        headerName: "Kullanıcı Tipi",
                        sortable: false,
                        flex: 1,
                        renderCell: (data) => {
                            return (
                                <RecordContextProvider value={data.row}>
                                    <ReferenceField
                                        source="userId"
                                        reference="user">
                                        <UserTypeField
                                            source="userType"
                                            label="Kullanıcı Tipi"
                                        />
                                    </ReferenceField>
                                </RecordContextProvider>
                            )
                        }
                    },
                    {
                        field: "releaseType",
                        headerName: "Durum",
                        sortable: false,
                        flex: 1,
                        renderCell: (data) => {
                            return (
                                <RecordContextProvider value={data.row}>
                                    <ReferenceField
                                        source="userId"
                                        reference="user">
                                        <UserReleaseTypeField
                                            source="releaseType"
                                            label="Durum"
                                        />
                                    </ReferenceField>
                                </RecordContextProvider>
                            )
                        }
                    },
                    {
                        field: "createDate",
                        headerName: "Kayıt Tarihi",
                        sortable: false,
                        flex: 1,
                        renderCell: (data) => {
                            return (
                                <RecordContextProvider value={data.row}>
                                    <ReferenceField
                                        source="userId"
                                        reference="user">
                                        <DateField
                                            source="createDate"
                                            label="Kayıt Tarihi"
                                        />
                                    </ReferenceField>
                                </RecordContextProvider>
                            )
                        }
                    },
                    {
                        field: "detay",
                        headerName: "Detay",
                        sortable: false,
                        renderCell: (data) => {
                            return (
                                <MLink href={`#/user/${data.row.userId}/show`}>
                                    Detay
                                </MLink>
                            )
                        }
                    }
                ]}
            />
        </Paper>
    )
}
const Content = () => {
    return (
        <Paper elevation={20}>
            <TabbedShowLayout>
                <Tab label="Hesap Bilgileri" icon={<></>}>
                    <TextField
                        source="phoneNumber"
                        label="Telefon Numarası"
                        emptyText="-"
                    />
                    <TextField
                        source="trIdentity"
                        label="Kimlik Numarası"
                        emptyText="-"
                    />
                    <BooleanField
                        source="isActivated"
                        label="E-posta Aktivasyonu"
                    />
                    <ColorfulBooleanField
                        source="isIdentityValid"
                        label="Kimlik Onayı"
                    />
                    <ColorfulBooleanField
                        source="isPhoneValid"
                        label="Telefon Onayı"
                    />
                    <ColorfulBooleanField
                        source="isContactAllowed"
                        label="İletişim İzni"
                    />
                    <StatusField source="status" />
                    <DateField source="createDate" label="Kayıt Tarihi" />
                </Tab>
                <Tab label="Bağlı Profiller" icon={<></>}>
                    <LinkendUsers></LinkendUsers>
                </Tab>
            </TabbedShowLayout>
        </Paper>
    )
}

export const AccountView = () => (
    <Show title={"Hesap Detayı"} actions={<></>}>
        <Grid container spacing={2} sx={{ mt: 1, mb: 5, px: 2 }}>
            <Grid item xs={3}>
                <Aside />
            </Grid>
            <Grid item xs={9}>
                <Content />
            </Grid>
        </Grid>
    </Show>
)
