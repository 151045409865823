import {
    Typography,
    CircularProgress,
    Box,
    Paper,
    Alert,
    Link as MLink,
    Modal,
    Divider,
    Button as MButton,
    TabScrollButton
} from "@mui/material"
import OfferStatusTypeField from "CustomFields/OfferStatusTypeField"
import { ProjectStatusType } from "CustomFields/ProjectReleaseTypeField"
import { CheckCircleRounded, Help, Download } from "@mui/icons-material"
import { httpClient } from "Helpers"
import { GenerateUrlByResource } from "Providers/Helpers"
import { OfferStatusType } from "Types/OfferStatusTypes"
import { ProjectStatus } from "Types/ProjectStatusTypes"
import { useEffect, useState } from "react"
import { DataGrid as MDataGrid } from "@mui/x-data-grid"
import {
    Button,
    DateField,
    NumberField,
    RecordContextProvider,
    ReferenceField,
    TextField,
    useNotify,
    useRecordContext
} from "react-admin"
import {
    MilestoneViewType,
    PlanDeliveriesViewType,
    PlanDeliveryViewType,
    PlanViewType
} from "../types"
import Avatar from "CustomFields/Avatar"
import PriceField from "CustomFields/PriceField"
import { MilestoneStatus } from "Types/MilestoneTypes"
import MilestoneStatusField from "CustomFields/MilestoneStatusTypeField"
import { DeliveryType } from "Types/DeliveryTypes"
import { title } from "process"
import { textAlign } from "@mui/system"
import { link } from "fs"
type PlanDetailPropType = {
    planId: string
    offerId: string
    plan: PlanViewType
    project: { id: any }
    freelancer: { id: string }
}

const getAlertMessage = (
    title: string,
    message: string,
    isHelpMessage: boolean = false
) => {
    return (
        <Box>
            <Box style={MessageBoxStyle.alertMessage}>
                <>
                    {isHelpMessage ? (
                        <Help
                            style={MessageBoxStyle.alertIcon(isHelpMessage)}
                        />
                    ) : (
                        <CheckCircleRounded
                            style={MessageBoxStyle.alertIcon(isHelpMessage)}
                        />
                    )}
                    <Typography variant="body2" style={MessageBoxStyle.message}>
                        {title}
                    </Typography>
                </>
            </Box>
            <Typography variant="body2" style={MessageBoxStyle.message}>
                {message}
            </Typography>
        </Box>
    )
}
const getMessage = (message: string, deliveryType: DeliveryType) => {
    switch (deliveryType) {
        case DeliveryType.nothing:
            return (
                <Typography variant="body2" style={MessageBoxStyle.message}>
                    {message}
                </Typography>
            )
        case DeliveryType.complain:
            return <>{getAlertMessage("Destek Talebi", message, true)}</>
        case DeliveryType.complainCancelled:
            return (
                <>
                    {getAlertMessage(
                        "Destek Talebi",
                        "Destek talebini geri çekti.",
                        true
                    )}
                </>
            )
        case DeliveryType.paymentRequestCancelled:
            return (
                <>
                    {getAlertMessage(
                        "Talimat",
                        "Otomatik ödeme talimatı iptali"
                    )}
                </>
            )
        case DeliveryType.makePayment:
            return <>{getAlertMessage("Ödeme", "Ödeme tamamlandı.", false)}</>
        case DeliveryType.paymentRequest:
            return <>{getAlertMessage("Ödeme", "Ödeme talimatı başlandı.")}</>
        case DeliveryType.paymentRequestCompleted:
            return <>{getAlertMessage("Plan Tamamlandı", "Plan tamamlandı.")}</>
        case DeliveryType.complainResponse:
            return <>{getAlertMessage("Destek Talebi", message)}</>
    }
}
const getDownloadFile = (url: string, fromType: number) => {
    return (
        <Box sx={MessageBoxStyle.downloadBoxContent}>
            <Typography style={MessageBoxStyle.message}>
                İndirilecek Dosya
            </Typography>
            <a href={url} download>
                <Download />
            </a>
        </Box>
    )
}

const MessageBoxs = (planDeliveriesView: PlanDeliveriesViewType) => {
    return (
        <>
            {planDeliveriesView.planDeliveries.length == 0 || null ? (
                <Typography variant="body1" style={MessageBoxStyle.emptyText}>
                    Henüz herhangi bir mesajlaşma yok.
                </Typography>
            ) : (
                Array.from(planDeliveriesView.planDeliveries).map(
                    (planDelivery: PlanDeliveryViewType) => {
                        return (
                            <>
                                <Box
                                    sx={MessageBoxStyle.messageBox(
                                        planDelivery.fromType
                                    )}>
                                    <Box
                                        sx={MessageBoxStyle.messageBoxStartDirection(
                                            planDelivery.fromType
                                        )}>
                                        <RecordContextProvider
                                            value={planDelivery}>
                                            <ReferenceField
                                                source="userId"
                                                reference="user"
                                                link="show">
                                                <Avatar
                                                    source="imagePath"
                                                    title="Profil Resmi"
                                                    size={32}
                                                    sx={MessageBoxStyle.avatar}
                                                />
                                            </ReferenceField>
                                            <Box>
                                                <Box
                                                    style={MessageBoxStyle.messageBoxContent(
                                                        planDelivery.fromType
                                                    )}>
                                                    <ReferenceField
                                                        source="userId"
                                                        reference="user"
                                                        link="show">
                                                        <TextField
                                                            source="name"
                                                            style={
                                                                MessageBoxStyle.userName
                                                            }></TextField>
                                                    </ReferenceField>

                                                    {getMessage(
                                                        planDelivery.message,
                                                        planDelivery.deliveryType
                                                    )}
                                                </Box>

                                                <Box>
                                                    {planDelivery.files ===
                                                        null ||
                                                    planDelivery.files.length ==
                                                        0
                                                        ? null
                                                        : Array.from(
                                                              planDelivery.files
                                                          ).map((file: any) => {
                                                              return (
                                                                  <Box
                                                                      style={MessageBoxStyle.downloadBox(
                                                                          planDelivery.fromType
                                                                      )}>
                                                                      {getDownloadFile(
                                                                          file.url,
                                                                          planDelivery.fromType
                                                                      )}
                                                                  </Box>
                                                              )
                                                          })}
                                                </Box>

                                                <Box>
                                                    <DateField
                                                        style={
                                                            MessageBoxStyle.dateField
                                                        }
                                                        source="createDate"
                                                        locales="TR"
                                                        options={{
                                                            day: "numeric",
                                                            month: "numeric",
                                                            year: "numeric",
                                                            hour: "numeric",
                                                            minute: "numeric"
                                                        }}
                                                        showTime></DateField>
                                                </Box>
                                            </Box>
                                        </RecordContextProvider>
                                    </Box>
                                </Box>
                            </>
                        )
                    }
                )
            )}
        </>
    )
}
const planDetailModalTable = (
    plan: PlanViewType,
    project: { id: any },
    freelancer: { id: string }
) => {
    return (
        <table className="admin-table" style={PlanDetailModalStyle.table}>
            <caption style={PlanDetailModalStyle.caption}>
                <tr>
                    <td colSpan={2}>Plan Bilgileri</td>
                </tr>
            </caption>
            <tbody>
                <RecordContextProvider value={project}>
                    <tr>
                        <td>Proje İsmi </td>
                        <ReferenceField
                            source={"id"}
                            reference="project"
                            link={"show"}>
                            <td>
                                <TextField
                                    source="title"
                                    sx={PlanDetailModalStyle.nameStyle}
                                />
                            </td>
                        </ReferenceField>
                    </tr>
                    <tr>
                        <td>İşveren </td>
                        <ReferenceField
                            source={"id"}
                            reference="project"
                            link={"show"}>
                            <ReferenceField
                                source="userId"
                                reference="user"
                                link="show">
                                <td>
                                    <TextField
                                        source="name"
                                        sx={
                                            PlanDetailModalStyle.nameStyle
                                        }></TextField>
                                </td>
                            </ReferenceField>
                        </ReferenceField>
                    </tr>
                </RecordContextProvider>

                <tr>
                    <td>Freelancer </td>
                    <RecordContextProvider value={freelancer}>
                        <ReferenceField
                            source={"id"}
                            reference="user"
                            link={"show"}>
                            <td>
                                <TextField
                                    source="name"
                                    sx={PlanDetailModalStyle.nameStyle}
                                />
                            </td>
                        </ReferenceField>
                    </RecordContextProvider>
                </tr>
                <tr>
                    <td>Plan Konusu </td>
                    <td>{plan.subject}</td>
                </tr>

                <tr>
                    <td>Plan Açıklaması </td>
                    <td>{plan.description}</td>
                </tr>
                <RecordContextProvider value={plan}>
                    <tr>
                        <td>Plan Bütçesi </td>
                        <td>
                            <PriceField source="budgetPrice" />
                        </td>
                    </tr>
                    <tr>
                        <td>Freelancer Komisyon Oranı</td>
                        <td><NumberField source="freelancerComissionPercentage" emptyText="Belirtilmemiş"/></td>
                    </tr>
                    <tr>
                        <td>İşveren Komisyon Oranı</td>
                        <td><NumberField source="employerComissionPercentage" emptyText="Belirtilmemiş"/></td>
                    </tr>
                    <tr>
                        <td>Plan Başlama Tarihi </td>
                        <td>
                            <DateField source="startDate" />
                        </td>
                    </tr>
                    <tr>
                        <td>Ödeme Başlangıç Tarihi </td>
                        <td>
                            <DateField
                                source="paymentStartDate"
                                emptyText="Belirsiz"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Ödeme Tarihi </td>
                        <td>
                            <DateField
                                source="paymentDoneDate"
                                emptyText="Belirsiz"
                            />
                        </td>
                    </tr>
                </RecordContextProvider>
            </tbody>
        </table>
    )
}

const PlanDetailModal = (props: PlanDetailPropType) => {
    const [open, setOpen] = useState(false)
    const [loadPlanDeliveris, setLoadPlanDeliveris] = useState(false)
    const { offerId, planId, plan, project, freelancer } = props
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const baseUrlOffer = GenerateUrlByResource("offer")
    const [planDeliveries, setPlanDeliveries] = useState([])
    const fetchPlanDelivery = async (offerId: string, planId: string) => {
        if (open && !loadPlanDeliveris) {
            try {
                httpClient(
                    `${baseUrlOffer}/${offerId}/plan/${planId}/deliveries`,
                    {
                        method: "GET"
                    }
                )
                    .then((response) => JSON.parse(response.body))
                    .then((data) => {
                        setPlanDeliveries(data.data)
                        setLoadPlanDeliveris(true)
                    })
            } catch (e) {
                setLoadPlanDeliveris(false)
            }
        }
    }
    useEffect(() => {
        fetchPlanDelivery(offerId, planId)
    }, [open, planDeliveries])

    return (
        <>
            <MButton onClick={handleOpen}>İşlemler</MButton>
            <Modal open={open} onClose={handleClose}>
                <Box sx={PlanDetailModalStyle.modalBox}>
                    {planDetailModalTable(plan, project, freelancer)}
                    <Divider orientation="vertical" />
                    <Typography
                        sx={{ ml: 1, mb: 1, height: "1px", width: "1px" }}>
                        Konuşmalar
                    </Typography>

                    <Box
                        className="container"
                        sx={{
                            mt: 3,
                            height: "100%",
                            overflowY: "visible",
                            overflowX: "hidden",
                            width: "100%"
                        }}>
                        <Box
                            sx={{
                                float: "left",
                                m: 0,
                                width: "100%"
                            }}>
                            <MessageBoxs planDeliveries={planDeliveries} />
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

const MilestoneContent = (planView: MilestoneViewType) => {
    return (
        <>
            <Typography variant="h5" style={{ textAlign: "left", margin: 15 }}>
                Planlar
            </Typography>
            {Array.from(planView.plans).map((plan: any) => (
                <>
                    <Paper
                        elevation={20}
                        sx={{
                            marginTop: 2,
                            paddingTop: 0.1,
                            paddingBottom: 2
                        }}>
                        <OfferStatusTypeField
                            offerStatus={plan.currentOfferStatus}
                        />
                        <table
                            className="admin-table"
                            style={{ margin: 5, border: 1 }}>
                            <caption style={{ textAlign: "left", margin: 10 }}>
                                <tr>
                                    <th>
                                        <RecordContextProvider value={plan}>
                                            <ReferenceField
                                                source={"userId"}
                                                reference="user"
                                                link={"show"}>
                                                <Box
                                                    display="flex"
                                                    alignItems={"center"}
                                                    style={{ gap: "10px" }}>
                                                    <Avatar
                                                        source="imagePath"
                                                        title="Profil Resmi"
                                                        size={32}
                                                    />
                                                    <TextField
                                                        source="name"
                                                        sx={{
                                                            fontWeight: "bold"
                                                        }}
                                                    />
                                                </Box>
                                            </ReferenceField>
                                        </RecordContextProvider>
                                    </th>
                                </tr>
                            </caption>
                            {plan.plans == null ? (
                                <></>
                            ) : (
                                <tbody style={{ marginLeft: 12 }}>
                                    <tr>
                                        <td>Tarih</td>
                                        <td>Konu</td>
                                        <td>Bütçe</td>
                                        <td>Durum</td>
                                        <td> </td>
                                    </tr>
                                    {Array.from(plan.plans).map(
                                        (planDetail: any) => (
                                            <RecordContextProvider
                                                value={planDetail}>
                                                <tr>
                                                    <td>
                                                        <DateField source="startDate" />
                                                    </td>
                                                    <td>
                                                        <TextField
                                                            sx={{
                                                                textAlign:
                                                                    "center"
                                                            }}
                                                            source="description"
                                                        />
                                                    </td>
                                                    <td>
                                                        <PriceField source="budgetPrice" />{" "}
                                                    </td>
                                                    <td>
                                                        <MilestoneStatusField
                                                            planStatus={
                                                                planDetail.planStatus
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <PlanDetailModal
                                                            offerId={plan.id}
                                                            planId={
                                                                planDetail.id
                                                            }
                                                            freelancer={{
                                                                id: plan.userId
                                                            }}
                                                            project={{
                                                                id: plan.projectId
                                                            }}
                                                            plan={planDetail}
                                                        />
                                                    </td>
                                                </tr>
                                            </RecordContextProvider>
                                        )
                                    )}
                                </tbody>
                            )}
                        </table>
                    </Paper>
                </>
            ))}
        </>
    )
}

const projectStatusByContent = (planView: any) => {
    const projectStatusType: ProjectStatus = ProjectStatusType()
    switch (projectStatusType) {
        case 1:
        case 3:
        case 5:
        case 6:
            return <MilestoneContent plans={planView} />
        case 0:
        case 2:
        case 4:
        case 7:
        case 8:
            return (
                <Alert severity="warning">
                    Proje plan aşamasında değildir.
                </Alert>
            )
    }
    return <MilestoneContent plans={planView} />
}

const WorkProcess = () => {
    const record = useRecordContext()
    const [plans, setPlans] = useState([])
    const [isLoadPlans, setIsLoadPlans] = useState(false)
    const baseUrl = GenerateUrlByResource("project")
    const notify = useNotify()
    if (!record) return null
    const projectStatusType: ProjectStatus = ProjectStatusType()
    const fetchOffer = async () => {
        if (
            projectStatusType == ProjectStatus.ON_PLANNING ||
            projectStatusType == ProjectStatus.ON_DOING ||
            projectStatusType == ProjectStatus.COMPLETED ||
            projectStatusType == ProjectStatus.UNCOMPLETED
        ) {
            try {
                httpClient(`${baseUrl}/${record.id}/plans`, {
                    method: "GET"
                })
                    .then((response) => JSON.parse(response.body))
                    .then((data) => {
                        setPlans(data.data)
                        setIsLoadPlans(true)
                    })
            } catch {
                notify("Bir hata ile karşılaşıldı.")
                setIsLoadPlans(false)
            }
        } else {
            setIsLoadPlans(true)
        }
    }

    useEffect(() => {
        fetchOffer()
    }, [])
    if (!isLoadPlans) {
        return (
            <Box
                sx={{
                    height: 200,
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center"
                }}>
                <CircularProgress />
            </Box>
        )
    }
    return projectStatusByContent(plans)
}

export default WorkProcess

//#region css

export class MessageBoxStyle {
    static messageBox = (fromType?: number): React.CSSProperties => {
        return {
            float: fromType == 0 ? "right" : "left",
            width: "60%",
            textAlign: fromType == 0 ? "right" : "left",
            margin: 1
        }
    }
    static alertMessage: React.CSSProperties = {
        flexDirection: "row",
        display: "flex",
        alignItems: "center"
    }
    static alertIcon = (isHelpMessage: boolean): React.CSSProperties => {
        return {
            color: isHelpMessage ? "red" : "green",
            margin: "1px"
        }
    }
    static messageBoxStartDirection = (
        fromType?: number
    ): React.CSSProperties => {
        return {
            display: "flex",
            justifyContent: fromType == 0 ? "end" : "start"
        }
    }
    static avatar: React.CSSProperties = {
        width: 40,
        height: 40
    }
    static messageBoxContent = (fromType?: number): React.CSSProperties => {
        return {
            display: "flex",
            flexDirection: "column",
            alignItems: fromType == 0 ? "end" : "start",
            backgroundColor: "#ebebeb",
            padding: 10,
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
            borderBottomLeftRadius: 20
        }
    }
    static downloadBoxContent = () => {
        return {
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
            paddingX: "20px",
            paddingTop: "8px"
        }
    }
    static downloadBox = (fromType?: number): React.CSSProperties => {
        return {
            backgroundColor: "#F8F9F9",
            border: "1px solid #F4F5F6",
            padding: "8px",
            borderRadius: "10px",
            marginBottom: "10px",
            marginTop: 5
        }
    }
    static userName: React.CSSProperties = {
        fontWeight: 400,
        lineHeight: 1.5,
        fontFamily: `"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"`,
        color: "#65748B"
    }
    static message: React.CSSProperties = {
        fontWeight: 400,
        lineHeight: 1.4,
        fontFamily: `"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"`,
        color: "#444",
        fontSize: 13
    }
    static dateField: React.CSSProperties = {
        color: "#747474",
        display: "block",
        fontSize: 12,
        paddingTop: 5
    }
    static emptyText: React.CSSProperties = {
        color: "#757D83",
        textAlign: "center",
        marginTop: "20px",
        fontSize: "16px",
        fontWeight: 200
    }
}
class PlanDetailModalStyle {
    static modalBox: React.CSSProperties = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 900,
        height: 500,
        backgroundColor: "background.paper",
        border: "2px solid #000",
        boxShadow: "24px",
        padding: 4,
        display: "flex",
        flexDirection: "row"
    }
    static table: React.CSSProperties = {
        border: 0,
        overflow: "hidden",
        borderCollapse: "collapse",
        width: "80%",
        fontFamily: `"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"`,
        display: "flex",
        justifyContent: "start",
        overflowY: "visible",
        overflowX: "hidden"
    }
    static caption: React.CSSProperties = {
        writingMode: "vertical-rl",
        transform: "rotate(180deg)"
    }
    static nameStyle: React.CSSProperties = {
        fontWeight: 400,
        lineHeight: 1.2,
        fontFamily: `"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"`,
        color: "#566573"
    }
}
//#endregion
