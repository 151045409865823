import { httpClient } from "Helpers"
import { GenerateUrlByResource } from "Providers/Helpers"
import { useEffect, useState } from "react"
import {
    EmailField,
    FunctionField,
    RecordContextProvider,
    ReferenceField,
    ShowButton,
    TextField,
    useRecordContext
} from "react-admin"
import {
    Box,
    CircularProgress,
    Typography,
    Alert,
    Paper,
    Link,
    Divider
} from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import Avatar from "CustomFields/Avatar"
import { TableRow } from "../Components/TableRow"

export const EmployeeList = () => {
    const record = useRecordContext()
    const [accounts, setAccounts] = useState([])
    const baseUrl = GenerateUrlByResource("account")
    const [isLoadAccounts, setIsLoadAccounts] = useState(false)

    useEffect(() => {
        const fetchAccounts = async () => {
            try {
                httpClient(`${baseUrl}/company-users/${record.id}`, {
                    method: "GET"
                })
                    .then((response) => JSON.parse(response.body))
                    .then((data) => {
                        setAccounts(data.data)
                        setIsLoadAccounts(true)
                    })
            } catch {
                setIsLoadAccounts(false)
            }
        }
        fetchAccounts()
    }, [])
    if (!isLoadAccounts)
        return (
            <Box
                sx={{
                    height: 250,
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center"
                }}>
                <CircularProgress />
            </Box>
        )
    else if (accounts.length == 0) {
        return <Alert severity="warning">Kayıtlı ekip üyesi bulunamadı.</Alert>
    }
    return (
        <Paper elevation={20}>
            <Box sx={{ m: 2 }}>
                <Typography variant="h6">Ekip Üyeleri</Typography>
            </Box>
            <DataGrid
                getRowId={(row: any) => row.id}
                rows={accounts || []}
                columns={[
                    {
                        field: "avatar",
                        headerName: "",
                        sortable: false,
                        renderCell: (data) => {
                            return (
                                <RecordContextProvider value={data.row}>
                                    <Avatar
                                        source="imagePath"
                                        title="Profil Resmi"
                                        size={32}
                                        sx={{
                                            width: 32,
                                            height: 32,
                                            mx: "auto"
                                        }}
                                    />
                                </RecordContextProvider>
                            )
                        }
                    },
                    {
                        field: "name",
                        headerName: "İsim",
                        sortable: false,
                        flex: 1,
                        renderCell: (data) => {
                            return (
                                <RecordContextProvider value={data.row}>
                                    <TextField source="name" />
                                </RecordContextProvider>
                            )
                        }
                    },
                    {
                        field: "email",
                        headerName: "Email",
                        sortable: false,
                        flex: 1,
                        renderCell: (data) => {
                            return (
                                <RecordContextProvider value={data.row}>
                                    <EmailField source="email" />
                                </RecordContextProvider>
                            )
                        }
                    },
                    {
                        field: "detay",
                        headerName: "Detay",
                        sortable: false,
                        renderCell: (data) => {
                            return (
                                <Link href={`#/account/${data.row.id}/show`}>
                                    Detay
                                </Link>
                            )
                        }
                    }
                ]}
            />
            <Box sx={{ m: 2 }}>
                <Typography variant="h6">Kurucu Üye Bilgisi</Typography>
            </Box>
            <Divider />
            <Box sx={{ ml: 2, mt: 2 }}>
                <ReferenceField
                    source="accountId"
                    reference="account"
                    link={false}>
                    <FunctionField
                        label="Name"
                        render={(record: any) => (
                            <>
                                <table className="admin-table">
                                    <tbody>
                                        <TableRow
                                            val={`${record.firstname} ${record.lastname}`}
                                            label="İsim Soyisim "
                                        />
                                        <TableRow
                                            val={record.email}
                                            label="Email"
                                        />
                                    </tbody>
                                </table>
                                <Box>
                                    <ShowButton
                                        label="Hesap Detayı"
                                        icon={<></>}
                                        sx={{
                                            mx: 0,
                                            display: "block",
                                            textAlign: "center"
                                        }}
                                    />
                                </Box>
                            </>
                        )}></FunctionField>
                </ReferenceField>
            </Box>
        </Paper>
    )
}
