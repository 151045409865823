import { Typography, Paper } from "@mui/material"
import Avatar from "CustomFields/Avatar"
import PriceField from "CustomFields/PriceField"
import OfferGroupTypeField from "CustomFields/OfferGroupTypeField"
import OfferOveralTypeField from "CustomFields/OfferOveralTypeField"
import {
    TextField,
    ReferenceField,
    useRecordContext,
    RecordContextProvider,
    DateField,
    Labeled
} from "react-admin"
import GroupOfferList from "./GroupOfferList"

const TableRow = ({ label, val }: { label: string; val: any }) => {
    return (
        <tr>
            <td width="150">
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    {label}
                </Typography>
            </td>
            <td>
                <Typography variant="body2"> {val}</Typography>
            </td>
        </tr>
    )
}
const OfferContent = () => {
    const record = useRecordContext()
    if (!record) return null
    const isGroupOffer = record.groupType != 0
    return (
        <>
            <Paper elevation={20}>
                <table className="admin-table" style={{ marginTop: 5 }}>
                    <caption>
                        <Typography
                            variant="h5"
                            style={{ textAlign: "left", margin: 15 }}>
                            {isGroupOffer
                                ? "Ana Teklif Detayı"
                                : "Teklif Detayı"}
                        </Typography>
                    </caption>
                    <tbody>
                        <TableRow
                            label="Proje"
                            val={
                                <ReferenceField
                                    source="projectId"
                                    reference="project"
                                    link="show">
                                    <Labeled>
                                        <TextField
                                            source="title"
                                            label=""></TextField>
                                    </Labeled>
                                </ReferenceField>
                            }
                        />
                        {!isGroupOffer && (
                            <TableRow
                                label="Açıklama"
                                val={record.description}
                            />
                        )}
                        <TableRow
                            label="Teklif Bütçesi"
                            val={<PriceField source="budgetPrice" />}
                        />
                        <TableRow
                            label="Teklif Tipi"
                            val={<OfferGroupTypeField source="groupType" />}
                        />
                        {record.plans != null && !isGroupOffer && (
                            <>
                                <TableRow
                                    label="Freelancer Komisyon Oranı"
                                    val={
                                        record.plans[0]
                                            .freelancerComissionPercentage
                                    }
                                />
                                <TableRow
                                    label="İşveren Komisyon Oranı"
                                    val={
                                        record.plans[0]
                                            .employerComissionPercentage
                                    }
                                />
                            </>
                        )}
                        <TableRow
                            label="Durum"
                            val={<OfferOveralTypeField source="overalStatus" />}
                        />
                        <TableRow
                            label="İlan Oluşturulma Tarihi"
                            val={<DateField source="createDate" />}
                        />
                    </tbody>
                </table>
            </Paper>
            {isGroupOffer && <GroupOfferList data={record.childOffers} />}
        </>
    )
}

export default OfferContent
