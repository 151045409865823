import Avatar from "CustomFields/Avatar"
import InvoiceProcessStatusField from "CustomFields/InvoiceProcessStatusField"
import PriceField from "CustomFields/PriceField"
import {
    Datagrid,
    DateField,
    FilterList,
    FilterListItem,
    List,
    Pagination,
    ShowButton,
    TextField
} from "react-admin"
import { Card, CardContent, Grid, Paper } from "@mui/material"
import { InvoiceProcessStatus } from "Types/InvoiveProcressTypes"

const FilterSidebar = () => {
   return <Paper elevation={20}>
        <Card>
            <CardContent>
                <FilterList label="Fatura Durumu" icon={<></>}>
                    <FilterListItem
                        label={"Bekleyen"}
                        value={{
                            invoiceProcessStatus: InvoiceProcessStatus.waiting
                        }}
                    />
                    <FilterListItem
                        label={"Tamamlanan"}
                        value={{
                            invoiceProcessStatus: InvoiceProcessStatus.success
                        }}
                    />
                    <FilterListItem
                        label={"Kısmi İade"}
                        value={{
                            invoiceProcessStatus:
                                InvoiceProcessStatus.partialRefund
                        }}
                    />
                    <FilterListItem
                        label={"Tam İade"}
                        value={{
                            invoiceProcessStatus:
                                InvoiceProcessStatus.fullRefund
                        }}
                    />
                    <FilterListItem
                        label={"İptal"}
                        value={{
                            invoiceProcessStatus: InvoiceProcessStatus.cancelled
                        }}
                    />
                </FilterList>
            </CardContent>
        </Card>
    </Paper>
}

const DataList = () => {
    return (
        <>
            <Datagrid bulkActionButtons={false}>
                <TextField source="toUserName" label="Ünvan" />
                <PriceField source="price" label="Tutar" />
                <InvoiceProcessStatusField
                    source="invoiceProcessStatus"
                    label="İşlem Durumu"
                />
                <DateField source="createDate" label="Oluşturma Tarihi" />
                <ShowButton />
            </Datagrid>
            <Pagination rowsPerPageOptions={[5, 10, 25]} />
        </>
    )
}
const AccounterInvoiceList = () => {
    return (
        <List
            exporter={false}
            pagination={<></>}
            hasShow
            sort={{
                field: "createDate",
                order: "DESC"
            }}>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <FilterSidebar />
                </Grid>
                <Grid item xs={10}>
                    <DataList />
                </Grid>
            </Grid>
        </List>
    )
}

export default AccounterInvoiceList
