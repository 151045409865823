import { useRecordContext } from "react-admin"
import { ContentType } from "Types/ContentTypes"

type PropType = {
    source: string
    label?: string
}

const ContentTypeField = (props: PropType) => {
    const { source } = props
    const record = useRecordContext(props)

    let returnText = "";
    if(record[source] === ContentType.Blog) {
        returnText = "Blog";
    }else if(record[source] === ContentType.Help) {
        returnText = "Yardım";
    }

    return <>{returnText}</>
}

export default ContentTypeField