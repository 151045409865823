import {
    ChipField,
    Empty,
    Labeled,
    ReferenceArrayField,
    SingleFieldList,
    useRecordContext
} from "react-admin"
import { Box, Typography } from "@mui/material"
import EmptyText from "CustomElements/EmptyText"
const OfferCriteria = () => {
    const record = useRecordContext()
    type emptyCriteriaType = {
        text: any
        value: any
    }
    const emptyCriteria: emptyCriteriaType = {
        text: "-",
        value: "-"
    }
    if (!record) return null

    const additionalCriteria = !record.formData
        ? Object.keys(emptyCriteria).map((key: any) => {
              return { text: emptyCriteria.text, value: emptyCriteria.value }
          })
        : Object.keys(record?.formData).map((key: any) => {
              return {
                  text: record?.formData[key].text,
                  value: record?.formData[key].value,
                  key: key
              }
          })
    return (
        <>
            <Labeled label="Dil Kriterleri">
                <ReferenceArrayField reference="language" source="languages">
                    <EmptyText source="languages" />
                    <SingleFieldList sx={{ mt: 1 }}>
                        <ChipField source="displayName" />
                    </SingleFieldList>
                </ReferenceArrayField>
            </Labeled>

            <Labeled label="Şehir Kriterleri" sx={{ mt: 1 }}>
                <ReferenceArrayField reference="city" source="cities">
                    <EmptyText source="cities" />
                    <SingleFieldList sx={{ mt: 1 }}>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>
            </Labeled>
            <Box sx={{ mt: 2 }}>
                <Labeled label="Ekstra Kriterler">
                    <table className="sort-table">
                        {additionalCriteria.map((criteria: any) => (
                            <tr>
                                <td>
                                    <Typography variant="body2">
                                        {criteria.text}
                                    </Typography>
                                </td>
                                <td>
                                    <Typography variant="body2">
                                        {criteria.value}
                                    </Typography>
                                </td>
                            </tr>
                        ))}
                    </table>
                </Labeled>
            </Box>
        </>
    )
}

export default OfferCriteria
