import { AuthContext, AUTH_CHECK } from "react-admin"
import { queryClient } from "./Config"
import { GetLoginEndpoint } from "./Helpers"

const baseUrl = GetLoginEndpoint()

const AuthProvider: any = {
    login: ({ username, password }: { username: string; password: string }) => {
        return new Promise((resolve, reject) => {
            let url = `${baseUrl}/sign-in`

            fetch(url, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    username,
                    password
                })
            })
                .then(function (res) {
                    return res.json()
                })
                .then(function (resp) {
                    const { success, data } = resp

                    if (success === true) {
                        const { accessToken, refreshToken } = data
                        localStorage.setItem("accessToken", accessToken)
                        localStorage.setItem("refreshToken", refreshToken)
                        localStorage.setItem("firstAccess", "true")
                        resolve(true)
                    } else {
                        reject()
                    }
                })
        })
    },

    logout: () => {
        // Clear query cache if user logs out
        queryClient.clear()
        localStorage.removeItem("accessToken")
        localStorage.removeItem("refreshToken")
        localStorage.removeItem("role")
        localStorage.removeItem("firstAccess")
        return Promise.resolve()
    },
    checkAuth: () => {
        const isForgotPasswordPathName =
            location.href?.includes("#/forgot-password")
        if (localStorage.getItem("accessToken") || isForgotPasswordPathName) {
            return Promise.resolve()
        }
        return Promise.reject()
    },
    checkError: (error: any) => {
        const status = error.status
        if (status === 401 || status === 403) {
            // Clear the query cache if user getting 401 or 403 error
            queryClient.clear()
            localStorage.removeItem("accessToken")
            localStorage.removeItem("refreshToken")
            localStorage.removeItem("role")
            localStorage.removeItem("firstAccess")
            return Promise.reject()
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve()
    },
    getIdentity: () => {
        return new Promise((resolve, reject) => {
            let url = `${baseUrl}/me`
            fetch(url, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "accessToken"
                    )}`,
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
                .then(function (res) {
                    return res.json()
                })
                .then(function (resp) {
                    const { data, success } = resp

                    if (success === true) {
                        resolve({
                            id: data.managerId,
                            fullName: data.fullname
                        })
                        localStorage.setItem("role", data.role)
                        if (localStorage.getItem("firstAccess") === "true") {
                            localStorage.setItem("firstAccess", "false")
                            window.location.reload()
                        }
                    } else {
                        reject()
                    }
                })
        })
    },
    getPermissions: () => Promise.resolve(""),
    getRoles: () => Promise.reject("Not implemented")
}
export default AuthProvider
