import { httpClient } from "Helpers"
import { GenerateUrlByResource } from "Providers/Helpers"
import { useState } from "react"
import { Button, Confirm, useNotify, useRedirect } from "react-admin"

export const SetReleaseTypeButton = ({
    record,
    releaseType
}: {
    record: any
    releaseType: number
}) => {
    const redirect = useRedirect()
    const notify = useNotify()
    const [loading, setLoading] = useState<boolean>(false)
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const isApprove = releaseType == 1
    const buttonText = isApprove ? "Onayla" : "Reddet"

    const showAreYouSure = () => {
        setIsOpen(true)
    }

    const handleSubmit = () => {
        setLoading(true)
        const baseUrl = GenerateUrlByResource("user")

        httpClient(`${baseUrl}/${record.id}/release-type/${releaseType}`, {
            method: "PUT"
        })
            .then(() => {
                notify(isApprove ? "Profil onaylandı!" : "Profil reddedildi.")
                redirect("/user")
            })
            .catch((e) => {
                notify("Bir hata oluştu.", { type: "warning" })
            })
            .finally(() => {
                setLoading(false)
                setIsOpen(false)
            })
    }
    return (
        <>
            <Confirm
                isOpen={isOpen}
                title={buttonText}
                content="Bu işlemi yapmak istediğinizden emin misiniz?"
                confirm="Evet"
                confirmColor="default"
                cancel="Vazgeç"
                onConfirm={handleSubmit}
                onClose={() => {
                    setIsOpen(false)
                }}
            />
            <Button
                label={buttonText}
                onClick={showAreYouSure}
                disabled={loading}
                variant={isApprove ? "contained" : "outlined"}
                color={isApprove ? "secondary" : "primary"}
            />
        </>
    )
}