import { httpClient } from "Helpers"
import { GenerateUrlByResource } from "Providers/Helpers"
import { BadgeUserType } from "Types/BadgeTypes"
import { ReleaseType } from "Types/ReleaseTypes"
import { useState } from "react"
import {
    Datagrid,
    Edit,
    NumberField,
    ReferenceArrayField,
    ReferenceArrayInput,
    ReferenceField,
    SaveButton,
    SelectArrayInput,
    ShowButton,
    SimpleForm,
    TextField,
    Toolbar,
    useNotify,
    useRedirect
} from "react-admin"
import { Typography, Alert, Paper } from "@mui/material"

const BadgeEdit = (props: any) => {
    const { badgeUserType } = props
    const redirect = useRedirect()
    const notify = useNotify()
    const [isSuccess, setIsSuccess] = useState<boolean>(true)
    const handleSubmit = (id: string, badgeIds: string) => {
        const baseUrl = GenerateUrlByResource("user")

        httpClient(`${baseUrl}/${id}/badge-ids`, {
            method: "PUT",
            body: JSON.stringify({ badgeIds })
        })
            .then(() => {
                redirect(`/user/${id}/show/badge`)
                window.location.reload();
            })
            .catch((e) => {
                notify("Bir hata oluştu.", { type: "warning" })
                setIsSuccess(false)
            })
            .finally(() => {
                if (isSuccess) {
                    notify("İşlem başarıyla gerçekleşti.", { type: "success" })
                }
            })
    }
    return (
        <Edit actions={false}>
            <SimpleForm
                onSubmit={(data) =>
                    handleSubmit(data.id, data.badgeIds.toString())
                }
                toolbar={
                    <Toolbar>
                        <SaveButton />
                    </Toolbar>
                }>
                <ReferenceArrayInput
                    label="Rozetler"
                    source="badgeIds"
                    reference="badge"
                    filter={{ userType: badgeUserType.toString() }}>
                    <SelectArrayInput label="Rozetler" optionText={"name"} />
                </ReferenceArrayInput>
            </SimpleForm>
        </Edit>
    )
}

export const BadgeView = ({
    record,
    isCompany,
    isFreelancer
}: {
    record: any
    isCompany: boolean
    isFreelancer: boolean
}) => {
    const badgeUserType: BadgeUserType = isFreelancer
        ? BadgeUserType.Freelancer
        : isCompany
        ? BadgeUserType.Corporate
        : BadgeUserType.Individual
    return (
        <Paper>
            {record?.badgeIds == null ? (
                <Alert severity="warning">
                    Bu profile tanımlı bir rozet bulunmamaktadır.
                </Alert>
            ) : (
                <>
                    <ReferenceArrayField
                        label="Rozetler"
                        reference="badge"
                        source="badgeIds">
                        {record.badgeIds.length != 0 && (
                            <Typography
                                variant="body1"
                                style={{
                                    fontWeight: "bold",
                                    margin: "10px"
                                }}>
                                Sahip Olduğu Rozetler
                            </Typography>
                        )}
                        <Datagrid
                            bulkActionButtons={false}
                            empty={
                                <Alert severity="warning">
                                    Bu profile tanımlı bir rozet
                                    bulunmamaktadır.
                                </Alert>
                            }>
                            <TextField source="name" label="Adı" />
                            <TextField source="note" label="Tanımlama Kuralı" />
                            <ReferenceField
                                reference="commission-rate"
                                label="İşveren Oranı"
                                source="commissionRateId">
                                <NumberField
                                    source="employerRate"
                                    emptyText="Standart"
                                />
                            </ReferenceField>
                            <ReferenceField
                                label="Freelancer Oranı"
                                reference="commission-rate"
                                source="commissionRateId">
                                <NumberField
                                    source="freelancerRate"
                                    emptyText="Standart"
                                />
                            </ReferenceField>
                            <ShowButton />
                        </Datagrid>
                    </ReferenceArrayField>
                </>
            )}
            {record.releaseType == ReleaseType.published && (
                <BadgeEdit badgeUserType={badgeUserType} />
            )}
        </Paper>
    )
}

