import { StatusType } from "Types/StatusTypes"
import {
    BooleanInput,
    Edit,
    NumberInput,
    SelectInput,
    SimpleForm,
    TextInput,
    required,
    useRecordContext
} from "react-admin"
import {Paper} from "@mui/material"
const CommissionRateEdit = () => {
    return (
        <Edit>
            <Paper elevation={20}>
            <SimpleForm>
                <TextInput
                    source="name"
                    name="name"
                    validate={[required()]}
                    fullWidth
                    label="Komisyon İsmi"
                />
                <NumberInput
                    source="employerRate"
                    name="employerRate"
                    label="İşveren Komisyon Oranı"
                    helperText="İşveren tarafına uygulanacak komisyon tutarını girmiş oluyorsunuz. Standart komisyon oranına tabi olacaksa komisyon oranı girmeyiniz!"
                />
                <NumberInput
                    source="freelancerRate"
                    name="freelancerRate"
                    label="Freelance Komisyon Oranı"
                    helperText="Freelance tarafına uygulanacak komisyon tutarını girmiş oluyorsunuz. Standart komisyon oranına tabi olacaksa komisyon oranı girmeyiniz!"
                />
                <SelectInput
                    fullWidth
                    validate={[required()]}
                    name="status"
                    source="status"
                    label="Durum"
                    choices={[
                        { id: StatusType.Passive, name: "Pasif" },
                        { id: StatusType.Active, name: "Aktif" }
                    ]}
                    helperText="Pasif durumunda olan komisyon oranları rozet oluştururken seçenek olarak gösterilmez"
                />
            </SimpleForm>
            </Paper>
        </Edit>
    )
}

export default CommissionRateEdit
