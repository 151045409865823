import { useState } from "react"
import { GenerateUrlByResource } from "Providers/Helpers"
import {
    Button as MButton,
    Modal,
    Box,
    TextField as MTextInput
} from "@mui/material"
import { httpClient } from "Helpers"
import { useNotify, useRedirect } from "react-admin"
import { InvoiceProcessStatus } from "Types/InvoiveProcressTypes"
type InvoiceStatusEditModalPropType = {
    id: string
    isWaiting: boolean
    isAccounter: boolean
}
export const InvoiceStatusEditModal = (
    props: InvoiceStatusEditModalPropType
) => {
    const { id, isAccounter, isWaiting } = props
    const redirect = useRedirect()
    const notify = useNotify()
    const [codeValid, setCodeValid] = useState<boolean>(true)
    const [open, setOpen] = useState<boolean>(false)
    const [invoiceCode, setInvoiceCode] = useState<string>("")
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const baseUrl = GenerateUrlByResource("accounter-invoice")

    const handleCodeValidation = (value: string) => {
        setInvoiceCode(value)
        if(value != ""){
            const regex = new RegExp(/(ARV|EXP)(20\d{2})(\d{9})/)
            setCodeValid(regex.test(value))
        }
    }
    const updateInvoiceProcessStatus = () => {
        httpClient(`${baseUrl}/${id}/process-status`, {
            method: "PUT",
            body: JSON.stringify({
                invoiceCode
            })
        })
            .then((response) => JSON.parse(response.body))
            .then((response) => {
                const { invoiceProcessStatus } = response
                if (invoiceProcessStatus === InvoiceProcessStatus.success) {
                    notify("İşlem başarıyla gerçekleştirilmiştir.", {
                        type: "success"
                    })
                }
            })
            .catch((e) => {
                notify("Bir hata oluştu.", { type: "warning" })
            })
            .finally(() => {
                redirect("/accounter-invoice")
            })
    }

    return (
        <>
            {isWaiting && isAccounter && (
                <MButton
                    variant="contained"
                    color="primary"
                    onClick={handleOpen}
                    className="change-status-button">
                    Fatura Bilgisini Gir
                </MButton>
            )}
            <Modal open={open} onClose={handleClose}>
                <Box className="invoice-modal">
                    <p className="modal-title">Fatura Numarasını Giriniz</p>
                    <Box>
                        <MTextInput
                            style={{ marginTop: "20px" }}
                            name="invoiceCode"
                            value={invoiceCode}
                            error={!codeValid}
                            onChange={(e) => handleCodeValidation(e.target.value)}
                            required
                            fullWidth
                            placeholder="Fatura Numarası"
                        />
                    </Box>
                    <MButton
                        variant="contained"
                        className="modal-save-button"
                        onClick={() => updateInvoiceProcessStatus()}>
                        Kaydet
                    </MButton>
                </Box>
            </Modal>
        </>
    )
}
