export enum NotificationChannelType {
    Email = 0,
    Sms = 1,
    MobilePush = 2,
    WebPush = 3,
    Web = 4
}

export enum NotificationTargetTag {
    Freelancer = 0,
    CompanyUser = 1,
    ActionTaker = 2,
    ActionTarget = 3,
    User = 4,
    Company = 5,
    Ghost = 6,
    OfferLeader = 7,

}

export enum NotificationFromType {
    Freelancer = 0,
    Employer = 1,
    Admin = 2,
    System = 3,
    User = 4,
    Account = 5,
}