import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { IS_DEVELOPMENT } from "Providers/Config"

const element = document.getElementById("root")

if(IS_DEVELOPMENT){
    element.className = 'beta_app'
}
const root = ReactDOM.createRoot(element)
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
