import { Box, Chip, Typography } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"

export const MyProfile = ({
    record,
    isFreelancer,
    employeeCount,
    expertiseLevel
}: {
    record: any | null
    isFreelancer: boolean
    employeeCount: any
    expertiseLevel: any | null
}) => {
    return (
        <>
            <>
                <Typography variant="h6" mb={1} mt={2}>
                    Hakkında
                </Typography>
                <Typography>{record?.bio}</Typography>
            </>
            {!isFreelancer && (
                <>
                    <Typography variant="h6" mb={1} mt={2}>
                        Faaliyet Alanı
                    </Typography>
                    <Typography>
                        {record?.information?.sectors.length > 0
                            ? record.information?.sectors[0]?.details?.name
                            : "-"}
                    </Typography>
                    <Typography variant="h6" mb={1} mt={2}>
                        Çalışan Sayısı
                    </Typography>
                    <Typography>{employeeCount?.name}</Typography>
                </>
            )}
            <>
                <Typography variant="h6" mb={1} mt={2}>
                    Hizmet Verdiği Alan
                </Typography>
                <Typography>
                    {record?.expertise?.length > 0 && (
                        <>
                            {record?.expertise[0].details?.categoryName} <br />
                            {record?.expertise[0].details?.subCategoryName}
                        </>
                    )}
                </Typography>
            </>
            {isFreelancer && (
                <Box>
                    <Typography variant="h6" mb={1} mt={2}>
                        Uzmanlık
                    </Typography>
                    <Typography>
                        {expertiseLevel?.name || "Belirtilmemiş"}
                    </Typography>
                    <Typography variant="h6" mb={1} mt={2}>
                        Meslek
                    </Typography>
                    <Typography>
                        {record?.expertise?.length > 0
                            ? record?.expertise[0]?.details?.professionName
                            : "-"}
                    </Typography>
                </Box>
            )}

            <Typography variant="h6">
                {isFreelancer ? "Yetenekler" : "İhtiyaç Duyduğu Uzmanlıklar"}
            </Typography>
            <Typography>
                {record?.talent?.map((talent: any) => {
                    return talent.talents.map((subTalent: any) => {
                        return (
                            <Chip
                                label={subTalent?.details?.name}
                                sx={{ margin: "2px" }}
                            />
                        )
                    })
                })}
            </Typography>

            {!isFreelancer && (
                <>
                    <Typography variant="h6" mb={1} mt={2}>
                        Sosyal Medya
                    </Typography>
                    <DataGrid
                        getRowId={(row: any) => row.socialMediaId}
                        rows={record?.links || []}
                        columns={[
                            {
                                field: "details.name",
                                headerName: "İsim",
                                renderCell: (params: any) => {
                                    return (
                                        <div
                                            className="rowitem"
                                            style={{
                                                textTransform: "capitalize"
                                            }}>
                                            {params.row.details.name}
                                        </div>
                                    )
                                },
                                sortable: false,
                                width: 100
                            },
                            {
                                field: "url",
                                headerName: "Değer",
                                sortable: false,
                                flex: 1
                            }
                        ]}
                    />

                    <Typography variant="h6" mb={1} mt={2}>
                        Dosyalar
                    </Typography>
                    <DataGrid
                        getRowId={(row: any) => row.name}
                        rows={
                            record?.files?.length > 0
                                ? record?.files[0].files || []
                                : []
                        }
                        columns={[
                            {
                                field: "name",
                                headerName: "İsim",
                                sortable: false,
                                width: 150
                            },
                            {
                                field: "url",
                                headerName: "Adres",
                                sortable: false,
                                flex: 1
                            }
                        ]}
                    />
                </>
            )}
        </>
    )
}
