import {
    fetchUtils,
    Options
} from "react-admin"

export const httpClient = (url: string, options: Options | undefined) => {
    if (options == undefined) {
        options = {}
    }
    if (!options?.headers) {
        const headerDict = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${window.localStorage.getItem(
                "accessToken"
            )}`
        }

        options.headers = new Headers(headerDict)
    }

    return fetchUtils.fetchJson(url, options)
}