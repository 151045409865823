import { ReactElement } from "react"
import { useRecordContext } from "react-admin"
import {Typography} from "@mui/material"

type PropType = {
    source: string
    label?: string
    record?: any
}

const GetStatusText = (ComplaintType: number): ReactElement => {
    let returnElement: ReactElement = <>{"-"}</>
    switch (ComplaintType) {
        case 0:
            returnElement = <>{"Bu kişi Expero dışında ödeme talep ediyor"}</>
            break
        case 1:
            returnElement = <>{"Profil sahte görünüyor veya çalıntı/intihal içeriyor"}</>
            break
        case 2:
            returnElement = <>{"Profili kullanan kişi bir başkası"}</>
            break
        case 3:
            returnElement = <>{"Başka bir şey"}</>
            break
    }
    return <Typography variant={"body2"}sx={{whiteSpace: "normal"}}>{returnElement}</Typography>
}

const ComplaintTypeField = (props: PropType) => {
    const { source, record: propRecord } = props
    const record = propRecord ? propRecord : useRecordContext(props)
    return <>{GetStatusText(record[source])}</>
}

export default ComplaintTypeField
