import { DataGrid } from "@mui/x-data-grid"
export const MyExperience = ({ record }: { record: any }) => {
    return (
        <DataGrid
            getRowId={(row: any) => row.place}
            rows={record?.experience || []}
            columns={[
                {
                    field: "place",
                    headerName: "Yer",
                    sortable: false,
                    flex: 1
                },
                {
                    field: "field",
                    headerName: "Alan",
                    sortable: false,
                    flex: 1
                },
                {
                    field: "jobDescription",
                    headerName: "İş Tanımı",
                    sortable: false,
                    flex: 2
                },
                {
                    field: "startDate",
                    headerName: "Başlangıç",
                    sortable: false,
                    flex: 1
                },
                {
                    field: "endDate",
                    headerName: "Bitiş",
                    sortable: false,
                    flex: 1
                }
            ]}
        />
    )
}
