import { useState, useRef, forwardRef, useImperativeHandle } from "react"

import {
    Button,
    Confirm,
    DateField,
    Empty,
    Labeled,
    ReferenceField,
    TextField,
    useNotify,
    useRecordContext,
    useRedirect
} from "react-admin"

import {
    Box,
    Card,
    CardContent,
    Paper,
    TextField as MTextField,
    Typography
} from "@mui/material"

import ProjectReleaseTypeField from "CustomFields/ProjectReleaseTypeField"

import { GenerateUrlByResource } from "Providers/Helpers"
import { httpClient } from "Helpers"
import Avatar from "CustomFields/Avatar"
import ColorfulBooleanField from "CustomFields/ColorfulBooleanField"
import EmptyText from "CustomElements/EmptyText"
import BackToListField from "CustomFields/BackToListField"

const RejectModalContent = forwardRef((props: any, ref: any) => {
    const { onSuccess } = props

    const [reason, setReason] = useState("")

    const contentFormSubmitBtn = useRef<any>(null)

    useImperativeHandle(ref, () => ({
        getReason: () => {
            return reason
        },
        submit: () => {
            if (contentFormSubmitBtn?.current) {
                contentFormSubmitBtn?.current.click()
            }
        }
    }))

    return (
        <Box>
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                    onSuccess()
                }}
                id="reject-reason-form"
                style={{ width: 300 }}>
                <MTextField
                    multiline
                    maxRows={4}
                    rows={4}
                    name="rejectReason"
                    required
                    placeholder="Bir açıklama giriniz"
                    fullWidth
                    value={reason}
                    onChange={(e) => {
                        setReason(e.target.value)
                    }}
                />
                <button
                    type="submit"
                    ref={contentFormSubmitBtn}
                    style={{ display: "none" }}>
                    test
                </button>
            </form>
        </Box>
    )
})

const SetReleaseTypeButton = ({
    record,
    isApprove
}: {
    record: any
    isApprove: boolean
}) => {
    const redirect = useRedirect()
    const notify = useNotify()
    const [loading, setLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false)

    const buttonText = isApprove ? "Onayla" : "Reddet"

    const rejectModelRef = useRef<any>(null)

    const showAreYouSure = () => {
        setIsOpen(true)
    }

    const sendReq = (message?: string | null) => {
        const baseUrl = GenerateUrlByResource("project")
        httpClient(
            `${baseUrl}/${record.id}/${isApprove ? "approve" : "reject"}`,
            {
                method: "PUT",
                body: JSON.stringify({ message })
            }
        )
            .then(() => {
                notify(isApprove ? "Proje onaylandı!" : "Proje reddedildi.")
            })
            .catch((e) => {
                notify("Bir hata oluştu.", { type: "warning" })
            })
            .finally(() => {
                setLoading(false)
                setIsOpen(false)
            })
    }

    const handleSubmit = () => {
        if (!isApprove) {
            rejectModelRef?.current.submit()
        } else {
            setLoading(true)
            sendReq()
        }
    }

    const handleRejectSubmit = () => {
        setLoading(true)
        sendReq(rejectModelRef?.current.getReason() || "")
    }

    return (
        <>
            <Confirm
                isOpen={isOpen}
                title={buttonText}
                content={
                    isApprove ? (
                        "Bu projeyi onaylamak istediğinizden emin misiniz?"
                    ) : (
                        <RejectModalContent
                            ref={rejectModelRef}
                            onSuccess={handleRejectSubmit}
                        />
                    )
                }
                confirm="Evet"
                confirmColor="default"
                cancel="Vazgeç"
                onConfirm={handleSubmit}
                onClose={() => {
                    setIsOpen(false)
                }}
            />
            <Button
                label={buttonText}
                onClick={showAreYouSure}
                disabled={loading}
                variant={isApprove ? "contained" : "outlined"}
                color={isApprove ? "secondary" : "primary"}
            />
        </>
    )
}

const Aside = () => {
    const record = useRecordContext()

    if (!record) return null

    return (
        <Box>
          <BackToListField source="project"/>
            <Paper elevation={20}>
                <Card>
                    <CardContent>
                        <Typography>
                            <TextField source="title" variant="h6" />
                        </Typography>

                        <Box display="flex" flexDirection="column">
                            <Labeled label="Proje Sahibi">
                                <ReferenceField
                                    label="Kullanıcı"
                                    source="userId"
                                    reference="user"
                                    link="show">
                                    <>
                                        <Box
                                            display="flex"
                                            alignItems={"center"}
                                            style={{ gap: "6px" }}>
                                            <Avatar
                                                source="imagePath"
                                                title="Profil Resmi"
                                            />
                                            <TextField source="name" />
                                        </Box>
                                    </>
                                </ReferenceField>
                            </Labeled>

                            <Labeled label="Kategori">
                                <ReferenceField
                                    source="categoryId"
                                    reference="category"
                                    link={false}>
                                    <TextField source="name" variant="body2" />
                                </ReferenceField>
                            </Labeled>

                            <Labeled label="Kişisel Proje">
                                <ColorfulBooleanField source="isPersonal" />
                            </Labeled>

                            <Labeled label="Oluşturma Tarihi">
                                <DateField
                                    source="createDate"
                                    label="Oluşturma Tarihi"
                                />
                            </Labeled>
                        </Box>

                        <Box display="flex" justifyContent="center" mt={1}>
                            <ProjectReleaseTypeField  />
                        </Box>
                    </CardContent>
                </Card>
            </Paper>
            {record.releaseType === 2 && (
                <Paper elevation={20} sx={{ mt: 2 }}>
                    <Card>
                        <CardContent>
                            <Box display="flex" justifyContent="space-between">
                                <SetReleaseTypeButton
                                    record={record}
                                    isApprove={true}
                                />
                                <SetReleaseTypeButton
                                    record={record}
                                    isApprove={false}
                                />
                            </Box>
                        </CardContent>
                    </Card>
                </Paper>
            )}

            {record.releaseType === 3 && (
                <Paper elevation={20} sx={{ mt: 2 }}>
                    <Card>
                        <CardContent>
                            <Box display="flex" justifyContent="space-between">
                                <Labeled label="Red sebebi">
                                    <Typography variant="body2">
                                        {record.messages ? (
                                            record.messages[0]
                                        ) : (
                                            <i>
                                                Red sebebi belirtilmeden
                                                reddedilmiş
                                            </i>
                                        )}
                                    </Typography>
                                </Labeled>
                            </Box>
                        </CardContent>
                    </Card>
                </Paper>
            )}
        </Box>
    )
}

export default Aside
