import { tagBadgeUser } from "CustomFields/BadgeUserTypeField"
import {
    Create,
    ReferenceInput,
    SaveButton,
    SelectArrayInput,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    required
} from "react-admin"
import { Grid, Paper } from "@mui/material"
import { BadgeVisibilityType } from "Types/BadgeTypes"
import { choiceBadgeVisibility } from "CustomFields/VisibilityStatusField"
import { useState } from "react"
import { BadgeIdentifierInput } from "./components/BadgeIdentifierInput"

const BadgeCreate = () => {
    const [identifierValid, setIdentifierValid] = useState<number>(2)
    return (
        <Create title={"Rozet Oluştur"}>
            <SimpleForm
                toolbar={
                    <Toolbar>
                        <SaveButton disabled={identifierValid != 1} />
                    </Toolbar>
                }>
                <Grid container spacing={2} sx={{ mb: 5, px: 2 }}>
                    <Grid item xs={3}>
                        <Paper elevation={20} style={{ padding: 12 }}>
                            <SelectArrayInput
                                validate={required()}
                                source="userType"
                                choices={tagBadgeUser}
                                label="Uygulanacak Profiller"
                                fullWidth
                            />
                            <ReferenceInput
                                label="Komisyon"
                                source="commissionRateId"
                                reference="commission-rate">
                                <SelectInput
                                    name="commissionRateId"
                                    optionText={"name"}
                                    validate={[required()]}
                                    label="Komisyon"
                                    helperText="Rozetin komisyon oranında  farklı bir oran uygulanması gerekiyorsa ona özel olan komisyonu, eğer uygulanmaması gerekiyorsa varsayılan olarak oluşturduğunuz komisyonu seçiniz."
                                />
                            </ReferenceInput>
                            <SelectInput
                                source="visibility"
                                name="visibility"
                                label="Görünürlük Durumu"
                                validate={required()}
                                choices={choiceBadgeVisibility}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={9}>
                        <Paper elevation={20} style={{ padding: 12 }}>
                            <TextInput
                                source="name"
                                name="name"
                                validate={[required()]}
                                fullWidth
                                label="İsim"
                            />
                            <BadgeIdentifierInput
                                identifierValid={identifierValid}
                                setIdentifierValid={(val: number) =>
                                    setIdentifierValid(val)
                                }
                            />
                            <TextInput
                                source="description"
                                style={{ marginTop: "12px" }}
                                name="description"
                                validate={[required()]}
                                fullWidth
                                label="Açıklama"
                                helperText="Bu kısma doldurulan bilgiler sayfa ziyaretçisine rozet hakkında bilgi vermesi içindir."
                            />
                            <TextInput
                                source="note"
                                name="note"
                                style={{ marginTop: "12px" }}
                                fullWidth
                                label="Rozetin Amacı"
                                helperText="Bu kısma doldurulan bilgiler rozetin hangi profillere, ne amaçla hangi koşullarda uygulanacağını belirlenmesi içindir."
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
}

export default BadgeCreate
