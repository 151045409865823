import PriceField from "CustomFields/PriceField"
import {
    ChipField,
    DateField,
    Labeled,
    ReferenceArrayField,
    SingleFieldList,
    TextField
} from "react-admin"

import {
    Box,
    Typography
} from "@mui/material"

const Summary = () => {
    return (
        <>
            <Labeled label="Proje Açıklaması">
                <TextField
                    source="description"
                    style={{ whiteSpace: "break-spaces" }}
                />
            </Labeled>

            <Labeled label="Uzmanlıklar" sx={{ mt: 1 }}>
                <ReferenceArrayField
                    reference="talent"
                    source="talents"
                    sx={{ mb: 1 }}>
                    <SingleFieldList sx={{ mt: 1 }}>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>
            </Labeled>

            <Box mb={2} />

            <table className="sort-table">
                <tr>
                    <td><Typography variant="body2">Minimum Fiyat</Typography></td>
                    <td>
                        <PriceField source="minPrice" />
                    </td>
                </tr>
                <tr>
                    <td><Typography variant="body2">Maksimum Fiyat</Typography></td>
                    <td>
                        <PriceField source="maxPrice" />
                    </td>
                </tr>
                <tr>
                    <td><Typography variant="body2">Son Başvuru Tarihi</Typography></td>
                    <td>
                        <DateField source="applyEndDate" />
                    </td>
                </tr>
                <tr>
                    <td><Typography variant="body2">Hedeflenen Bitiş Tarihi</Typography></td>
                    <td>
                        <DateField source="projectEndDate" />
                    </td>
                </tr>
            </table>
        </>
    )
}

export default Summary
