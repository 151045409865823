import {
    Box,
    Typography
} from "@mui/material"
import { Empty, useRecordContext } from "react-admin";

const style = {
    "& .RaEmpty-message" : {
        display: 'none'
    }
}

type PropType = {
    text? : string
    sx?: any
    source : string
}

const EmptyText = (props: PropType) => {
    const record = useRecordContext();
    const { text = "İçerik bulunamadı.", sx = {}, source = "" } = props

    if(!(record[source] == undefined || record[source] == null || record[source].length == 0)){
        return null
    }

    return (
        <Box sx={style}>
            <Typography variant="body2" sx={sx}>
                {text}
            </Typography>
        </Box>
    )
}

export default EmptyText;