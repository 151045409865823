import {
    List,
    TextField,
    Datagrid,
    NumberField,
    DateField,
    BooleanField,
    Pagination,
    EditButton,
    ShowButton
} from "react-admin"
import { Box, Card, CardContent, Paper, Grid } from "@mui/material"
import StatusField from "CustomFields/StatusField"

const DataList = () => {
    return (
        <>
            <Datagrid bulkActionButtons={false}>
                <TextField source="name" label="Komisyon Adı" />
                <NumberField
                    source="employerRate"
                    label="İşveren Oranı"
                    emptyText="Standart"
                />
                <NumberField
                    source="freelancerRate"
                    label="Freelancer Oranı"
                    emptyText="Standart"
                />
                <StatusField source="status" label="Durum" />
                <DateField source="createDate" label="Oluşturma Tarihi" />
                <EditButton />
                <ShowButton />
            </Datagrid>
            <Pagination rowsPerPageOptions={[5, 10, 25]} />
        </>
    )
}

export const CommissionRateList = () => {
    return (
        <List
            exporter={false}
            pagination={<></>}
            hasShow
            sort={{ field: "createDate", order: "DESC" }}>
            <DataList />
        </List>
    )
}
