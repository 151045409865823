import { Button, Link, useRecordContext } from "react-admin"
import { Box, Typography, Paper, Card, Link as MLink, CardContent } from "@mui/material"
import {ArrowBack} from "@mui/icons-material"
import Avatar from "CustomFields/Avatar"
import UserTypeField from "CustomFields/UserTypeField"
import UserReleaseTypeField from "CustomFields/UserReleaseTypeField"
import { SITE_URL } from "Providers/Config"
import { SetReleaseTypeButton } from "../Components/SetReleaseTypeButton"
import { RemoveProfile } from "../Components/RemoveProfil"
import { Chat } from "../Components/Chat"
import { AccountShowButton } from "../Components/AccountShowButton"

export const Aside = () => {
    const record = useRecordContext()

    if (!record) return null

    return (
        <>
            <Link to="/user">
                <Button
                    label={"Listeye Dön"}
                    variant={"outlined"}
                    color={"primary"}
                    startIcon={<ArrowBack />}
                    sx={{ mb: 2 }}
                />
            </Link>
            <Paper elevation={20}>
                <Card>
                    <CardContent
                        sx={{ padding: 1, paddingBottom: "0px !important" }}>
                        <Avatar
                            source="imagePath"
                            title="Profil Resmi"
                            size={80}
                            sx={{ mx: "auto", width: 80 }} />
                        <Typography
                            variant="body2"
                            sx={{ textAlign: "center", mt: 1 }}>
                            <UserTypeField source="userType" />
                        </Typography>

                        <Typography
                            variant="h6"
                            sx={{ textAlign: "center", mt: 1 }}>
                            {record.name}
                        </Typography>

                        <Box display="flex" justifyContent="center" mt={1}>
                            <MLink
                                title={record.link}
                                href={`${SITE_URL}${record?.link}`}
                                target="_blank">
                                <Button
                                    variant="text"
                                    label={`@${record.username}`}
                                />
                            </MLink>
                        </Box>

                        <Box mb={1}>
                            <Typography
                                variant="body2"
                                sx={{ textAlign: "center", mt: 1 }}>
                                <UserReleaseTypeField source="releaseType" />
                            </Typography>
                        </Box>

                        {record.releaseType === 2 && (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                mt={2}
                                mb={1}>
                                <SetReleaseTypeButton
                                    record={record}
                                    releaseType={1}
                                />
                                <SetReleaseTypeButton
                                    record={record}
                                    releaseType={3}
                                />
                            </Box>
                        )}
                    </CardContent>
                </Card>
            </Paper>
            <Paper elevation={20}>
                <Card
                    sx={{
                        marginTop: "1em"
                    }}>
                    <CardContent
                        sx={{
                            padding: "10px !important",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center"
                        }}>
                        <Chat record={record} />
                        <RemoveProfile record={record} />
                        <AccountShowButton record={record} />
                    </CardContent>
                </Card>
            </Paper>
        </>
    )
}
