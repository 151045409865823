import { InvoiceType } from "../types"
import { Paper, Box } from "@mui/material"
import { TitleBox } from "./GetTitleBox"
import { DateField, SimpleShowLayout, TextField } from "react-admin"
import InvoiceProcessStatusField from "CustomFields/InvoiceProcessStatusField"
import PriceField from "CustomFields/PriceField"

type InvoicePropType = {
    invoice: InvoiceType
    isFreelancer: boolean
}
export const InvoiceShow = (props: InvoicePropType) => {
    const isAccounter = localStorage.getItem("role") === "accounter"
    const { invoice, isFreelancer } = props
    return (
        <Paper elevation={20}>
            <Box margin={"5px 0px"}>
                <TitleBox title="Fatura" color="#F39C12" />
            </Box>
            <SimpleShowLayout>
                <InvoiceProcessStatusField
                    record={invoice}
                    source="processStatus"
                    label="Fatura Durumu"
                />
                <PriceField record={invoice} source="price" label="Tutar" />
                {isFreelancer && invoice?.mainPaymentId && (
                    <TextField
                        source="invoice.mainPaymentId"
                        label="Moka Ödeme Id"
                    />
                )}
                (
                <TextField
                    label={isFreelancer ? "Moka Sepet Id" : "Moka Ödeme Id"}
                    source="invoice.paymentId"
                />
                )
                {invoice?.invoiceCode && (
                    <TextField
                        source="invoice.invoiceCode"
                        label="Fatura Numarası"
                    />
                )}
                {invoice?.accounterId && (
                    <TextField
                        source="invoice.accounterName"
                        label="Fatura Kesenin İsmi"
                    />
                )}
                <DateField
                    source="invoice.createDate"
                    label="Fatura Oluşturulma Tarihi"
                />
                {!isAccounter && invoice?.paymentDoneDate && (
                    <DateField
                        source="invoice.paymentDoneDate"
                        label="Freelancera Para Aktarılma Tarihi"
                    />
                )}
            </SimpleShowLayout>
        </Paper>
    )
}
