import { Login, LoginForm } from "react-admin"
import { Link as MLink, Button } from "@mui/material"

const LoginView = () => {
    return (
        <Login>
            <LoginForm />
            <MLink display={"flex"} href={"#/forgot-password"}>
                <Button sx={{ ml: "auto" }} variant={"text"}>
                    Şifremi Unuttum
                </Button>
            </MLink>
        </Login>
    )
}

export default LoginView
