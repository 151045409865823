import {
    BooleanField,
    DateField,
    SimpleShowLayout,
    TextField
} from "react-admin"
import { ProjectType } from "../types"
import { Paper } from "@mui/material"
import { TitleBox } from "./GetTitleBox"

type ProjectPropType = {
    project: ProjectType
}
export const ProjectShow = (props: ProjectPropType) => {
    const { project } = props
    return (
        <Paper elevation={20} style={{ paddingTop: "10px" }}>
            <TitleBox title="Proje" color="#D35400" />
            <SimpleShowLayout>
                <TextField source="project.title" label="Başlık" />
                <TextField source="project.description" label="Açıklama" />
                <BooleanField
                    label="Proje Devam Ediyor Mu?"
                    source="project.isContinuing"
                />
                <BooleanField
                    label="Proje Tamamlandı Mı?"
                    source="project.isCompleted"
                />
                <DateField
                    source="project.approvalDate"
                    label="Proje İş Başı Tarihi"
                />
                {project?.projectEndDate && (
                    <DateField
                        source="project.projectEndDate"
                        label="Projenin Tamamlanma Tarihi"
                    />
                )}
            </SimpleShowLayout>
        </Paper>
    )
}
