import React from "react"
import MUIAvatar from "@mui/material/Avatar"
import { useRecordContext } from "react-admin"
import { UserAccountEnum, UserGroupType } from "Types/UserTypes"
import Chip from '@mui/material/Chip';
import { ReleaseType, StateType } from "Types/ReleaseTypes";

type PropType = {
    label?: string,
    source: string
}

type TypeInfo = {
    label: string,
    color: "default" | "success" | "warning" | "error" | "primary" | "secondary" | "info" | undefined,
    value: number
}

const TypeList : TypeInfo[] = [
    { label: "Gelen Teklif", color: "primary", value : 0 },
    { label: "Onaylı", color: "success", value : 1 },
    { label: "Shortlist", color: "warning", value : 2 },
    { label: "Revizyon", color: "info", value : 3 },
]




    
const OfferOveralTypeField = (props: PropType) => {
    const { source } = props
    const record = useRecordContext(props)
    const data = record[source];
    const targetType = TypeList.find((s: TypeInfo) => s.value === data)
    
    return (
        <>
            <Chip label={targetType?.label} color={targetType?.color}  size="small" />
        </>
    )
}

export default OfferOveralTypeField
