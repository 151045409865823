import {
    Create,
    SelectInput,
    SimpleForm,
    TextInput,
    required
} from "react-admin"

const ManagerCreateView = () => {
    return (
        <Create title="Yeni Yönetici Oluştur">
            <SimpleForm>
                <TextInput
                    source="firstname"
                    label="İsim"
                    validate={required()}
                />
                <TextInput
                    source="lastname"
                    label="Soyisim"
                    validate={required()}
                />
                <TextInput
                    type="email"
                    source="email"
                    label="E-mail"
                    validate={required()}
                />
                <TextInput
                    type="password"
                    source="password"
                    label="Şifre"
                    validate={required()}
                />

                <SelectInput
                    source="status"
                    label="Durum"
                    validate={required()}
                    emptyValue={false}
                    choices={[
                        { id: 0, name: "Pasif" },
                        { id: 1, name: "Aktif" }
                    ]}
                />

                <SelectInput
                    source="role"
                    label="Rol"
                    validate={required()}
                    emptyValue={false}
                    choices={[
                        { id: "admin", name: "Admin" },
                        { id: "accounter", name: "Muhasebeci" }
                    ]}
                />
            </SimpleForm>
        </Create>
    )
}

export default ManagerCreateView
