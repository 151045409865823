import { Grid } from "@mui/material"
import BackToListField from "CustomFields/BackToListField"
import { Show, useRecordContext } from "react-admin"
import { AccounterInvoiceViewType } from "./types"
import "./styles.css"
import { InvoiceProcessStatus } from "Types/InvoiveProcressTypes"
import {
    FromUserShow,
    InvoiceShow,
    InvoiceStatusEditModal,
    ProjectShow
} from "./components"

const TopBar = () => {
    const isAccounter = localStorage.getItem("role") == "accounter"
    const record: AccounterInvoiceViewType = useRecordContext()
    if (!record) return null

    const isWaiting =
        record.invoice?.processStatus == InvoiceProcessStatus.waiting
    return (
        <Grid className="top-bar">
            <BackToListField source="accounter-invoice" />
            <InvoiceStatusEditModal
                id={record.id}
                isAccounter={isAccounter}
                isWaiting={isWaiting}
            />
        </Grid>
    )
}

const DataList = () => {
    const record: AccounterInvoiceViewType = useRecordContext()
    if (!record) return null

    return (
        <>
            <Grid item xs={6}>
                <FromUserShow user={record.fromUser} />
            </Grid>
            <Grid item xs={6}>
                <InvoiceShow
                    invoice={record.invoice}
                    isFreelancer={record.fromUser?.isFreelancer}
                />
            </Grid>
            <Grid xs={12} sx={{ mt: 1.5, ml: 1 }}>
                <ProjectShow project={record.project} />
            </Grid>
        </>
    )
}

const AccounterInvoiceView = () => {
    return (
        <Show title={"Fatura Detayı"} actions={<></>}>
            <Grid spacing={1} sx={{ mt: 5, mb: 6, px: 2 }}>
                <TopBar />
                <Grid container spacing={1} sx={{ mb: 6, px: 2, py: 1 }}>
                    <DataList />
                </Grid>
            </Grid>
        </Show>
    )
}

export default AccounterInvoiceView
