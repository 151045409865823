import {
    List,
    Datagrid,
    Pagination,
    TextField,
    ShowButton,
    EditButton,
    DateField,
    ArrayField,
    ReferenceField
} from "react-admin"
import { Box, Card, CardContent, Paper, Grid } from "@mui/material"
import StatusField from "CustomFields/StatusField"
import BadgeUserTypeField from "CustomFields/BadgeUserTypeField"
import VisibilityStatusField from "CustomFields/VisibilityStatusField"

const DataList = () => {
    return (
        <>
            <Datagrid bulkActionButtons={false}>
                <TextField source="name" label="İsim" />
                <TextField
                    source="description"
                    label="Açıklama"
                    style={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 3
                    }}
                />
                <ReferenceField
                    label="Komisyon"
                    source="commissionRateId"
                    reference="commission-rate"
                    link="show">
                    <TextField source="name" label="Komisyon Adı" />
                </ReferenceField>
                <ArrayField source="userType" label="Uygulanan Profiller">
                    <BadgeUserTypeField source="userType" />
                </ArrayField>
                <VisibilityStatusField
                    source="visibility"
                    label="Görünürlük Durumu"
                />
                <StatusField source="status" label="Durum" />
                <DateField source="createDate" label="Oluşturma Tarihi" />
                <EditButton />
                <ShowButton />
            </Datagrid>
            <Pagination rowsPerPageOptions={[5, 10, 25]} />
        </>
    )
}

export const BadgeList = () => {
    return (
        <List
            exporter={false}
            pagination={<></>}
            hasShow
            sort={{
                field: "createDate",
                order: "DESC"
            }}>
            <DataList />
        </List>
    )
}
