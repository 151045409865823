import { BadgeUserType } from "Types/BadgeTypes"
import { useRecordContext } from "react-admin"
import {Box, Chip} from "@mui/material"
type PropType = {
    source: string
    label?: string
}
export const tagBadgeUser = [
    {
        id: BadgeUserType.Freelancer,
        name: "Freelancer"
    },
    {
        id: BadgeUserType.Corporate,
        name: "İşveren"
    },
    {
        id: BadgeUserType.Individual,
        name: "Bireysel İşveren",
    }
]

const BadgeUserTypeField = (props: PropType) => {
    const { source } = props
    const record = useRecordContext(props)

    let target = record[source]

    return (
        <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="flex-start"
            sx={{ gap: 1 }}>
            {target.map((s: BadgeUserType, index: number) => {
                const found = tagBadgeUser.find((x: any) => x.id === s)
                return <Chip label={found?.name} size="small" />
            })}
        </Box>
    )
}

export default BadgeUserTypeField