import { Alert } from "@mui/material"
import { OfferStatusType } from "Types/OfferStatusTypes"

type PropType = {
    label?: string
    offerStatus: number
}
type TypeInfo = {
    label: string
    severity: "success" | "warning" | "info" | undefined
    value: number
}
const TypeList: TypeInfo[] = [
    { label: "Plan taslak aşamasında", severity: "warning", value: 0 },
    {
        label: "Freelancer ekibi bekleniyor",
        severity: "warning",
        value: 1
    },
    {
        label: "Freelancerlar, ekip liderinin planları oluşturmasını bekliyor",
        severity: "warning",
        value: 2
    },
    {
        label: "İşveren Freelancerın teklifini bekliyor",
        severity: "warning",
        value: 3
    },
    {
        label: "İşverenin proje adımlarını incelemesi bekleniyor",
        severity: "warning",
        value: 4
    },
    {
        label: "Ekip lideri, proje ekibinin yeni teklifler oluşturmasını bekliyor",
        severity: "warning",
        value: 5
    },
    {
        label: "İşveren, proje ekibinden teklif gelmesini bekliyor",
        severity: "warning",
        value: 6
    },
    {
        label: "İşveren freelancerın teklifi güncellemesini bekliyor",
        severity: "warning",
        value: 7
    },
    { label: "İşveren freelancerın proje planını oluşturmasını bekliyor", severity: "warning", value: 8 },
    {
        label: "İşverenin proje planını onaylaması bekleniyor",
        severity: "warning",
        value: 9
    },
    {
        label: "İşveren, freelancerdan revizyon talep etti",
        severity: "warning",
        value: 10
    },
    {
        label: "Proje adımları kabul edildi",
        severity: "warning",
        value: 11
    },
    {
        label: "Proje planları oluşturuldu, freelancerın işi tamamlaması bekleniyor",
        severity: "warning",
        value: 12
    },
    {
        label: "Proje tamamlandı",
        severity: "warning",
        value: 13
    },
    {
        label: "Tüm proje adımları kabul edildi",
        severity: "warning",
        value: 14
    },
    {
        label: "Arşivlendi",
        severity: "warning",
        value: 99
    },
]
const OfferStatusTypeField = (props: PropType) => {
    const { offerStatus } = props
    const offerStatusType: OfferStatusType = offerStatus
    const targetType = TypeList.find(
        (s: TypeInfo) => s.value === offerStatusType
    )

    return (
        <>
            <Alert severity={targetType?.severity}>{targetType?.label} </Alert>
        </>
    )
}

export default OfferStatusTypeField
