import {
    Box,
    Typography,
    Divider,
} from "@mui/material"
type GeneralDataPropType = {
    totalCount: number
    icon: JSX.Element
    title: string
}
export const GeneralData = (props: GeneralDataPropType) => {
    const { totalCount, icon, title } = props
    return (
        <Box style={GeneralDataStyle.generalData}>
            <Box style={GeneralDataStyle.generalDataTitle}>
                {icon}
                <Typography variant="body2">{title}</Typography>
            </Box>
            <Typography
                color="primary"
                style={GeneralDataStyle.generalDataCountText}>
                {totalCount}
            </Typography>
        </Box>
    )
}
class GeneralDataStyle{
    static generalData: React.CSSProperties = {
        flex: 1,
        margin: "0px 10px",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0px 0px 15px -10px rgba(0, 0, 0, 0.75)"
    }
    static generalDataTitle: React.CSSProperties = {
        margin: "5px 0px",
        display: "flex",
        alignItems: "center"
    }
    static generalDataCountText: React.CSSProperties = {
        fontWeight: 600,
        fontSize: 32
    }
}