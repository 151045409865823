import { QueryClient } from "react-query"

export type ServiceInfo = {
    resources: string[]
    baseURL: string
    serviceEndpoint: string
}

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            structuralSharing: false,
            refetchOnWindowFocus: false
        }
    }
})

const BASEREMOTEURL = process.env.REACT_APP_API_ENDPOINT || ""

export const IS_DEVELOPMENT = process.env.REACT_APP_IS_DEVELOPMENT === "true"
export const SITE_URL = process.env.REACT_APP_SITE_URL || ""

export const ENDPOINTS = {
    FeatureService: BASEREMOTEURL, // or localhost
    IdentityService: BASEREMOTEURL, // or localhost
    UserAttributeService: BASEREMOTEURL, // or localhost
    ProjectService: BASEREMOTEURL, // or localhost
    BlogService: BASEREMOTEURL, // or localhost
    NotificationService: BASEREMOTEURL// or localhost
}

export const SERVICE_INFOS = [
    {
        resources: [
            "sector",
            "talent",
            "language",
            "city",
            "profession",
            "category"
        ],
        baseURL: ENDPOINTS.FeatureService,
        serviceEndpoint: "feature-service/Admin"
    },
    {
        resources: ["user", "account", "invite-codes", "manager", "complaint"],
        baseURL: ENDPOINTS.IdentityService,
        serviceEndpoint: "identity-service/Admin"
    },
    {
        resources: ["billing", "portfolio", "portfolio-detail", "badge", "commission-rate", "accounter-invoice"],
        baseURL: ENDPOINTS.UserAttributeService,
        serviceEndpoint: "userAttribute-service/Admin"
    },
    {
        resources: ["project", "offer"],
        baseURL: ENDPOINTS.ProjectService,
        serviceEndpoint: "project-service/Admin"
    },
    {
        resources: ["template", "layout","chat"],
        baseURL: ENDPOINTS.NotificationService,
        serviceEndpoint: "notification-service/Admin"
    },
    {
        resources: [
            "content",
            "content-category",
            "content-tag",
            "content-feedback",
            "quick-feedback"
        ],
        baseURL: ENDPOINTS.BlogService,
        serviceEndpoint: "blog-service/Admin"
    }
]
