import ColorfulBooleanField from "CustomFields/ColorfulBooleanField"
import ProjectReleaseTypeField from "CustomFields/ProjectReleaseTypeField"
import {
    Datagrid,
    Pagination,
    ReferenceManyField,
    ShowButton,
    TextField
} from "react-admin"
import { Typography, Card } from "@mui/material"
export const MyProject = () => {
    return (
        <ReferenceManyField label="" reference="project" target="userId">
            <Card>
                <Datagrid
                    bulkActionButtons={false}
                    empty={
                        <Typography component="span" variant="body2">
                            Bu kullanıcının oluşturduğu bir proje yok.
                        </Typography>
                    }>
                    <TextField source="title" label="Başlık" />
                    <ColorfulBooleanField
                        source="isPersonal"
                        label="Kişisel Proje"
                    />
                    <ProjectReleaseTypeField label="releaseType" />
                    <ShowButton />
                </Datagrid>
                <Pagination rowsPerPageOptions={[5, 10, 25]} />
            </Card>
        </ReferenceManyField>
    )
}
