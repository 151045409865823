import { Edit, SimpleForm, TextInput, SelectInput, required } from 'react-admin';
import { ContentType } from 'Types/ContentTypes';
import { StatusType } from 'Types/StatusTypes';

const BlogCategoryEdit = () => {
    return (
        <Edit title="Düzenle">
            <SimpleForm>
                <TextInput source="name" name="name" label="İsim" validate={[required()]} fullWidth />
                <TextInput source="description" name="description" label="Açıklama" validate={[required()]} fullWidth />

                <SelectInput
                    fullWidth
                    validate={required()}
                    name="blogType"
                    source="blogType"
                    label="İçerik Sayfası"
                    choices={[
                        { id: ContentType.Blog, name: "Blog" },
                        { id: ContentType.Help, name: "Yardım" }
                    ]}
                />
                
            </SimpleForm>
        </Edit>
    )
}

export default BlogCategoryEdit;