import { InputAdornment, Tooltip, IconButton } from "@mui/material"
import { TextInput, required } from "react-admin"
import { Help } from "@mui/icons-material"
import { number } from "prop-types"

type BadgeIdentifierInputType = {
    identifierValid: number
    setIdentifierValid: (val: number) => void
}

export const BadgeIdentifierInput = (props: BadgeIdentifierInputType) => {
    const { identifierValid, setIdentifierValid } = props
    const handleIdentifierValidation = (value: any) => {
        const reg = new RegExp("([A-Z öüğçş])", "g")
        if (value === "") {
            setIdentifierValid(2)
        } else {
            reg.test(value) ? setIdentifierValid(0) : setIdentifierValid(1)
        }
    }
    return (
        <TextInput
            source="badgeIdentifier"
            name="badgeIdentifier"
            validate={[required()]}
            fullWidth
            label="Tanımlayıcı"
            helperText="Buraya girilen tanımlama için dikkat edilmesi gereken bazı kurallar vardır. # şeklinde tanımlama yaptığınızı düşünebilirsiniz. Örneğin: Premium Üye isminde ki rozetin tanımlaması şöyle olacaktır: 'premium_member'"
            onChange={(e) => handleIdentifierValidation(e.target?.value)}
            InputLabelProps={{
                style: {
                    color:
                        identifierValid == 0
                            ? "darkred"
                            : identifierValid == 1
                            ? "darkblue"
                            : "grey"
                }
            }}
            InputProps={{
                color: identifierValid == 0 ? "error" : "primary",
                endAdornment: (
                    <InputAdornment position="end">
                        <Tooltip title="Türkçe karakter, büyük harf, '  ' kullanmayınız.">
                            <IconButton>
                                <Help
                                    color={
                                        identifierValid == 0
                                            ? "error"
                                            : identifierValid == 1
                                            ? "primary"
                                            : "disabled"
                                    }
                                />
                            </IconButton>
                        </Tooltip>
                    </InputAdornment>
                )
            }}
        />
    )
}
