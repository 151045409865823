import { Edit, SelectInput, SimpleForm, TextInput, required } from "react-admin"
import { Paper, Grid } from "@mui/material"
import BackToListField from "CustomFields/BackToListField"

const Inputs = () => {
    return (
        <Paper elevation={20}>
            <SimpleForm id="id">
                <TextInput
                    source="firstname"
                    label="İsim"
                    validate={required()}
                />
                <TextInput
                    source="lastname"
                    label="Soyisim"
                    validate={required()}
                />
                <TextInput
                    type="email"
                    source="email"
                    label="E-mail"
                    validate={required()}
                />
                <TextInput type="password" source="password" label="Şifre" />

                <SelectInput
                    source="status"
                    label="Durum"
                    emptyValue={false}
                    validate={required()}
                    choices={[
                        { id: 0, name: "Pasif" },
                        { id: 1, name: "Aktif" }
                    ]}
                />

                <SelectInput
                    source="role"
                    label="Rol"
                    emptyValue={false}
                    validate={required()}
                    choices={[
                        { id: "admin", name: "Admin" },
                        { id: "accounter", name: "Muhasebeci" }
                    ]}
                />
            </SimpleForm>
        </Paper>
    )
}

export const ManagerEditView = () => {
    return (
        <Edit title="Yönetici Düzenle">
            <Grid spacing={1} sx={{ mt: 5, mb: 6, px: 2 }}>
                <Grid>
                    <BackToListField source="manager" />
                </Grid>
                <Grid sx={{ px: 3 }}>
                    <Inputs />
                </Grid>
            </Grid>
        </Edit>
    )
}
