import { InvoiceProcessStatus } from "Types/InvoiveProcressTypes"
import { useRecordContext } from "react-admin"
import { Tooltip, Chip } from "@mui/material"

type PropType = {
    source: string
    label?: string
    record?: any
}

const InvoiceProcessStatusField = (props: PropType) => {
    const { source, record : propRecord } = props
    const  record = propRecord ? propRecord : useRecordContext(props)
    let returnColor:
        | "info"
        | "primary"
        | "secondary"
        | "error"
        | "success"
        | "warning"
        | "default" = "info"
    let returnTip = null
    let returnText = ""
    switch (record[source]) {
        case InvoiceProcessStatus.waiting:
            returnText = "Bekleyen"
            returnTip = "Fatura kesilmesi bekleniyor"
            returnColor = "info"
            break
        case InvoiceProcessStatus.cancelled:
            returnText = "İptal"
            returnTip = "Fatura iptal edildi"
            returnColor = "error"
            break
        case InvoiceProcessStatus.fullRefund:
            returnText = "Tam iade"
            returnColor = "warning"
            break
        case InvoiceProcessStatus.partialRefund:
            returnText = "Kısmi iade"
            returnColor = "warning"
            break
        case InvoiceProcessStatus.success:
            returnText = "Tamamlandı"
            returnColor = "success"
            break
        default:
            returnText = "Bilinmiyor"
            returnColor = "default"
            break
    }

    return (
        <Tooltip title={returnTip ?? returnText}>
            <Chip label={returnText} color={returnColor} size="small"/>
        </Tooltip>
    )
}

export default InvoiceProcessStatusField
