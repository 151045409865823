import { useRecordContext } from "react-admin"
import { NotificationChannelType } from "Types/NotificationTypes"

type PropType = {
    source: string
    label?: string
}

const NotificationTypeField = (props: PropType) => {
    const { source } = props
    const record = useRecordContext(props)

    let returnText = "";
    if(record[source] === NotificationChannelType.Email) {
        returnText = "Email";
    }else if(record[source] === NotificationChannelType.Sms) {
        returnText = "SMS";
    }else if(record[source] === NotificationChannelType.MobilePush) {
        returnText = "Mobil Push";
    }else if(record[source] === NotificationChannelType.Web) {
        returnText = "Web";
    }

    return <>{returnText}</>
}

export default NotificationTypeField